import React, { useEffect } from 'react';
import {
	formatUsedAnnualLeavesContent,
	numberToCommaString,
	safeStringCompare,
} from '../../../app/common/util/util';
import { Table } from 'semantic-ui-react';
import SortableTable from '../../../app/common/form/SortableTable';

export default function AnnualLeavesWorkerList({
	paydocus,
	searchResults,
	setSearchResults,
	searchTerm,
	title,
	paydocuWorkerUid,
	numOfCompanyWorkers,
}) {
	useEffect(() => {
		// 검색 로직
		const searchFilter = (paydocu) => {
			return (
				paydocu?.workerInfo?.worker?.includes(searchTerm) ||
				paydocu?.info?.yearMonth?.includes(searchTerm) ||
				paydocu?.companyInfo?.companyName?.includes(searchTerm)
			);
		};

		const searchedPaydocus = paydocus?.filter(searchFilter);
		// 상태 업데이트가 필요한 경우에만 setState 호출
		if (JSON.stringify(searchResults) !== JSON.stringify(searchedPaydocus)) {
			setSearchResults(searchedPaydocus);
		}
	}, [searchTerm, paydocus, searchResults, setSearchResults]);

	// 연차합계 계산 함수
	function sumOfUsedALsCom() {
		let result = searchResults
			?.map((searchResult) => searchResult?.usedAnnualLeaves || 0)
			.reduce((prev, curr) => +prev + +curr, 0);
		return numberToCommaString(result);
	}

	const headers = [
		{ key: 'yearMonth', label: '년월' },
		{ key: 'companyName', label: '회사명' },
		{ key: 'worker', label: '직원명' },
		{ key: 'usedAnnualLeaves', label: '연차사용수(일수)' },
		{ key: 'usedAnnualLeaveDates', label: '연차사용내역(일자)' },
	];

	const sortFunctions = {
		companyName: (a, b) => safeStringCompare(a.companyName, b.companyName),
		yearMonth: (a, b) => safeStringCompare(a.yearMonth, b.yearMonth),
		worker: (a, b) => safeStringCompare(a.worker, b.worker),
		usedAnnualLeaves: (a, b) =>
			safeStringCompare(a.usedAnnualLeaves, b.usedAnnualLeaves),
		usedAnnualLeaveDates: (a, b) =>
			safeStringCompare(a.usedAnnualLeaveDates, b.usedAnnualLeaveDates),
	};

	const formattedData = searchResults
		.filter((result) => result.workerInfo.workerId === paydocuWorkerUid)
		.filter(
			(result) =>
				result?.usedAnnualLeaves ||
				result?.addAverWage?.plusUnusedAnnualLeavesDays
		)
		.map((result) => {
			return {
				yearMonth: result?.info?.yearMonth,
				companyName: result?.companyInfo?.companyName,
				worker: result?.workerInfo?.worker,
				usedAnnualLeaves: result?.usedAnnualLeaves,
				usedAnnualLeaveDates: formatUsedAnnualLeavesContent(
					result?.usedAnnualLeaveDates
				),
			};
		});

	const footer = (
		<Table.Row>
			<Table.Cell colSpan='3'>합계</Table.Cell>
			<Table.Cell>{sumOfUsedALsCom()}일</Table.Cell>
			<Table.Cell></Table.Cell>
		</Table.Row>
	);

	return numOfCompanyWorkers > 5 ? (
		<SortableTable
			headers={headers}
			data={formattedData}
			sortFunctions={sortFunctions}
			footer={footer}
			title={title}
			enableSearch={true}
		/>
	) : (
		<h1>
			직원이 5명 미만인 경우, 근로기준법 제60조의 연차유급휴가가 적용되지
			않습니다.
		</h1>
	);
}
