import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Loader } from 'semantic-ui-react';
import {
	clearClientReqs4In,
	fetchClientReqs4In,
	fetchWorkerUsers,
} from '../../clientReqActions4In';

import WorkerAnnualLeavesLawBaseInfoList from './WorkerAnnualLeavesLawBaseInfoList';
import PaydocuShortCut from '../../../../companyManage/paydocuDashboard/PaydocuShortCut';

export default function WorkerAnnualLeavesLawBaseInfoDashboard() {
	const dispatch = useDispatch();
	const { clientReqs4In, filter, moreClientReqs4In } = useSelector(
		(state) => state.clientReq4InStore
	);

	const { currentUserProfile } = useSelector((state) => state.profile);
	const { clientInfo, paydocus } = useSelector((state) => state.companyReducer);
	const { loading } = useSelector((state) => state.async);
	const { authenticated } = useSelector((state) => state.auth);

	const isWorker = currentUserProfile?.isWorker;
	const companyId = clientInfo?.id
		? clientInfo?.id
		: clientInfo?.clientUid?.clientUid
		? clientInfo?.clientUid
		: currentUserProfile?.id;

	function handleFetchNextClientReqs4In() {
		dispatch(fetchClientReqs4In(filter));
	}

	useEffect(() => {
		const fetchData = async () => {
			try {
				await dispatch(fetchClientReqs4In(filter));
			} catch (error) {
				console.error('Error fetching client requests:', error);
			}
		};

		fetchData();

		return () => {
			dispatch(clearClientReqs4In());
		};
	}, [dispatch, filter]);

	useEffect(() => {
		const fetchWorkerUsersData = async () => {
			try {
				await dispatch(fetchWorkerUsers(companyId));
			} catch (error) {
				console.error('Error fetching worker users:', error);
			}
		};

		fetchWorkerUsersData();
	}, [dispatch, companyId]);

	return (
		<>
			{/* 12월17일 아직 쓰지 않음 */}
			{isWorker ? null : (
				<Grid>
					<Grid.Column width={16}>
						<>
							{authenticated && (
								<>
									<PaydocuShortCut
										filter={filter}
										clientReqs4In={clientReqs4In}
									/>
									<WorkerAnnualLeavesLawBaseInfoList
										paydocus={paydocus}
										workersList={clientReqs4In}
										getNextClientReqs={handleFetchNextClientReqs4In}
										loading={loading}
										moreClientReqs={moreClientReqs4In}
										currentUserProfile={currentUserProfile}
									/>
								</>
							)}
						</>
					</Grid.Column>
					<Grid.Column width={16}>
						<Loader active={loading} />
					</Grid.Column>
				</Grid>
			)}
		</>
	);
}
