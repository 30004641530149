import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Dropdown, Menu } from 'semantic-ui-react';

export default function PaydocuAnnualLeaveMenu({
	currentUserProfile,
	clientInfo,
	FcLeave,
}) {
	const history = useHistory();

	const [dropdownOpen, setDropdownOpen] = useState(false);
	const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 1000);
		};

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	const handleMouseOver = () => {
		setDropdownOpen(true);
	};

	const handleMouseLeave = () => {
		setDropdownOpen(false);
	};

	const handleDropdownToggle = () => {
		setDropdownOpen(!dropdownOpen);
	};

	const numOfWorkers = clientInfo?.numOfWorkers
		? clientInfo?.numOfWorkers
		: +currentUserProfile?.numOfWorkers;

	const annualLeaves = clientInfo?.annualLeaves
		? clientInfo?.annualLeaves
		: currentUserProfile?.annualLeaves;

	const annualLeavesYearBase =
		annualLeaves === '특정일(회계년도 등)기준' ? true : false;

	function reloadToAnnualLeavesInputData() {
		history.push('/annualLeavesInputData');
	}

	function reloadToAnnualLeavesDataTable() {
		history.push('/AnnualLeavesDataTable');
	}

	function reloadToWorkerAnnualLeavesYearBase() {
		history.push('/workerAnnualLeavesYearBaseInfo');
	}

	function reloadToWorkerAnnualLeavesLawBase() {
		history.push('/workerAnnualLeavesLawBaseInfo');
	}

	function reloadToAnnualLeavesTable() {
		history.push('/annualLeavesTable');
	}

	function reloadToAnnualLeavesWorkerTable() {
		history.push('/annualLeavesWorkerTable');
	}

	const companyName = clientInfo?.companyName
		? clientInfo?.companyName
		: currentUserProfile?.companyName;

	const specialView =
		companyName === '(주)무빙컨텐츠솔루션즈' ||
		companyName === '피엠지인티그레이티드커뮤니케이션즈(주)'
			? true
			: false;

	return (
		<>
			<Menu.Item
				className='menu-item'
				onMouseOver={handleMouseOver}
				onMouseLeave={handleMouseLeave}>
				{!isMobile && <FcLeave className='icon-spacing' />}
				<Dropdown
					style={{ width: '160px' }}
					pointing='top left'
					text='연차/근태관리'
					open={dropdownOpen}
					onMouseOver={handleMouseOver}
					onMouseLeave={handleMouseLeave}
					onClick={handleDropdownToggle}>
					<Dropdown.Menu>
						{
							<>
								{numOfWorkers >= 5 ? (
									<>
										{currentUserProfile?.authLevel > 100 && (
											<Dropdown.Item
												onClick={() => reloadToAnnualLeavesInputData()}
												text='연차 일괄 등록'
											/>
										)}
										{(specialView || currentUserProfile?.authLevel > 100) && (
											<>
												<Dropdown.Item
													onClick={() => reloadToAnnualLeavesDataTable()}
													text='등록연차 조회'
												/>
												<hr />
											</>
										)}
										<Dropdown.Item
											onClick={() => reloadToWorkerAnnualLeavesLawBase()}
											text='연도별 연차 대장(법정기준)'
										/>
										{annualLeavesYearBase ? (
											<Dropdown.Item
												onClick={() => reloadToWorkerAnnualLeavesYearBase()}
												text='연도별 연차 대장(회계기준)'
											/>
										) : (
											<Dropdown.Item
												onClick={() =>
													alert(
														'귀사는 법정 연차입니다. 위 법정연차 대장을 이용하세요.'
													)
												}
												text='연도별 연차 대장(회계기준)'
											/>
										)}

										<Dropdown.Item
											onClick={() => reloadToAnnualLeavesTable()}
											text='월별 연차/근태대장'
										/>
										<Dropdown.Item
											onClick={() => reloadToAnnualLeavesWorkerTable()}
											text='개인별 연차/근태대장'
										/>
									</>
								) : (
									<Dropdown.Item text='연차미해당' />
								)}
							</>
						}
					</Dropdown.Menu>
				</Dropdown>
			</Menu.Item>
		</>
	);
}
