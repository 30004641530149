import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Dropdown, Menu } from 'semantic-ui-react';

export default function PriceIntroMenu({ MdOutlinePriceCheck }) {
	const history = useHistory();

	const [dropdownOpen, setDropdownOpen] = useState(false);

	const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 1000);
		};

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	const handleMouseOver = () => {
		setDropdownOpen(true);
	};

	const handleMouseLeave = () => {
		setDropdownOpen(false);
	};

	const handleDropdownToggle = () => {
		setDropdownOpen(!dropdownOpen);
	};

	function goToPricePolicy() {
		history.push('/pricePolicyPaper');
	}

	return (
		<>
			<Menu.Item
				className='menu-item'
				onMouseOver={handleMouseOver}
				onMouseLeave={handleMouseLeave}>
				{!isMobile && <MdOutlinePriceCheck className='icon-spacing' />}
				<Dropdown
					pointing='top left'
					text='기능 및 이용료 소개'
					open={dropdownOpen}
					onClick={handleDropdownToggle}>
					<Dropdown.Menu>
						<Dropdown.Item
							onClick={() => goToPricePolicy()}
							text='기능 및 이용료 소개'
						/>
					</Dropdown.Menu>
				</Dropdown>
			</Menu.Item>
		</>
	);
}
