import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import SortableTable from '../../../../../app/common/form/SortableTable';
import {
	numberToCommaString,
	roundUp2,
	safeStringCompare,
} from '../../../../../app/common/util/util';

export default function WorkerWageInfoList({
	workersList = [], // 기본값 설정
	getNextClientReqs,
	loading,
	moreClientReqs,
	title,
}) {
	// // 특정 열이 존재하는지 확인
	// const hasSumOfMonthONHWages = workersList.some(
	// 	(workerList) => workerList?.calWorkWage?.sumOfMonthONHWages
	// );

	const headers = [
		{ key: 'workerDivisions', label: '부서' },
		{ key: 'workerTitles', label: '직위/직책' },
		{ key: 'status', label: '직원구분' },
		{ key: 'name', label: '직원성명' },
		{ key: 'workerEnteredDate', label: '입사일' },
		{ key: 'retiredDate', label: '퇴직일' },
		{ key: 'annualSalary', label: '연봉액' },
		{ key: 'monthSalary', label: '월급액' },
		{ key: 'taxFreeWageSum', label: '비과세 금액(월)' },
		{ key: 'taxWageSum', label: '과세 금액(월)' },
		{ key: 'dedectTaxEtc', label: '공제세액(월)' },
		{ key: 'deductWage', label: '실수령액(월)' },
		// { key: 'monthBasicWage', label: '월 기본급' },
		// ...(hasSumOfMonthONHWages
		// 	? [{ key: 'sumOfMonthONHWages', label: '월 포괄가산임금' }]
		// 	: []),
		{
			key: 'monthOrdinaryWage',
			label: <span style={{ color: 'gray' }}>통상임금(월)</span>,
		},
		{
			key: 'paidHolidayLBTimeWage',
			label: (
				<span style={{ color: 'gray' }}>
					통상임금(일급)
					<br />
					(주휴수당)
				</span>
			),
		},
		{
			key: 'ordinaryTimeWage',
			label: <span style={{ color: 'gray' }}>통상임금(시급)</span>,
		},
	];

	const sortFunctions = {
		status: (a, b) => safeStringCompare(a.status, b.status),
		name: (a, b) => safeStringCompare(a.name, b.name),
		workerDivisions: (a, b) =>
			safeStringCompare(a.workerDivisions, b.workerDivisions),
		workerTitles: (a, b) => safeStringCompare(a.workerTitles, b.workerTitles),
		annualSalary: (a, b) => safeStringCompare(a.annualSalary, b.annualSalary),
		monthSalary: (a, b) => safeStringCompare(a.monthSalary, b.monthSalary),
		taxWageSum: (a, b) => safeStringCompare(a.taxWageSum, b.taxWageSum),
		taxFreeWageSum: (a, b) =>
			safeStringCompare(a.taxFreeWageSum, b.taxFreeWageSum),
		ordinaryTimeWage: (a, b) =>
			safeStringCompare(a.ordinaryTimeWage, b.ordinaryTimeWage),
		paidHolidayLBTimeWage: (a, b) =>
			safeStringCompare(a.paidHolidayLBTimeWage, b.paidHolidayLBTimeWage),

		ordinaryDayWage: (a, b) =>
			safeStringCompare(a.ordinaryDayWage, b.ordinaryDayWage),

		workerEnteredDate: (a, b) =>
			safeStringCompare(a.workerEnteredDate, b.workerEnteredDate),
		retiredDate: (a, b) => safeStringCompare(a.retiredDate, b.retiredDate),
		dedectTaxEtc: (a, b) => safeStringCompare(a.dedectTaxEtc, b.dedectTaxEtc),
		deductWage: (a, b) => safeStringCompare(a.deductWage, b.deductWage),
	};

	const data = workersList.map((workerList) => {
		let status = '정규직';
		if (workerList?.dayWorker?.daylyPay > 0) {
			status = '일용직';
		} else if (workerList?.dayWorker?.timeWage > 0) {
			status = '시급직';
		} else if (workerList?.isBizWorker) {
			status = '사업소득직';
		} else if (workerList?.contractEndDate) {
			status = '계약직';
		}
		const formatDateToLocal = (date) => {
			if (!date) return null; // 날짜가 없을 경우 null 반환
			const localDate = new Date(
				date.getTime() - date.getTimezoneOffset() * 60000
			);
			return localDate.toISOString().split('T')[0];
		};

		const workerEnteredDate = new Date(workerList?.workerEnteredDate);
		const formattedEnteredDate = formatDateToLocal(workerEnteredDate);
		const retiredDate = workerList?.retiredDate;
		const formattedRetiredDate = retiredDate
			? formatDateToLocal(retiredDate)
			: null;

		const annualSalary =
			Math.floor((workerList?.totalMonthWageAmount * 12) / 10) * 10 || 0;
		const annualSalaryString = numberToCommaString(annualSalary);
		const monthSalary = numberToCommaString(workerList?.totalMonthWageAmount);

		// 비과세 월급액
		const taxWageSum = numberToCommaString(workerList?.calWorkWage?.taxWageSum);

		const taxFreeWageSum = numberToCommaString(
			Number(workerList?.totalMonthWageAmount || 0) -
				Number(workerList?.calWorkWage?.taxWageSum || 0)
		);

		// 시간급 통상임금
		const ordinaryTimeWage = numberToCommaString(
			roundUp2(
				workerList?.dayWorker?.timeWage > 0
					? workerList?.dayWorker?.timeWage
					: workerList?.dayWorker?.daylyPay > 0
					? workerList?.dayWorker?.realTimeWage
					: workerList?.lawBase?.hourOrdinaryWage || 0
			)
		);
		const paidHolidayLBTimeWage =
			workerList?.dayWorker?.timeWage > 0 ||
			workerList?.dayWorker?.daylyPay > 0 ||
			workerList?.isBizWorker
				? '해당없음'
				: numberToCommaString(
						roundUp2(
							workerList?.lawBase?.paidHolidayLBTime *
								workerList?.lawBase?.hourOrdinaryWage
						)
				  );

		const monthBasicWage = numberToCommaString(
			workerList?.calWorkWage?.monthBasicWage || 0
		);
		const monthOrdinaryWage = numberToCommaString(
			workerList?.calWorkWage?.monthOrdinaryWage || 0
		);

		const sumOfMonthONHWages = numberToCommaString(
			workerList?.calWorkWage?.sumOfMonthONHWages || 0
		);

		const dedectTaxEtc = numberToCommaString(
			workerList?.calTaxAnd4In?.dedectTaxEtc || 0
		);

		const deductWage = numberToCommaString(
			workerList?.calTaxAnd4In?.deductWage || 0
		);

		return {
			status: status,
			workerDivisions: workerList?.workerDivisions || '부서 없음',
			workerTitles: workerList?.workerTitles || '직위 없음',
			name: workerList?.worker?.name,
			workerEnteredDate: formattedEnteredDate,
			retiredDate: formattedRetiredDate,
			annualSalary: annualSalaryString || 0,
			monthSalary: monthSalary || 0,
			taxWageSum: taxWageSum || 0,
			ordinaryTimeWage: ordinaryTimeWage || 0,
			paidHolidayLBTimeWage: paidHolidayLBTimeWage || 0,
			taxFreeWageSum: taxFreeWageSum || 0,
			monthBasicWage: monthBasicWage || 0,
			monthOrdinaryWage: monthOrdinaryWage || 0,
			sumOfMonthONHWages: sumOfMonthONHWages || 0,
			dedectTaxEtc: dedectTaxEtc || 0,
			deductWage: deductWage || 0,
		};
	});

	const customRenderers = {
		maternityLeaves: (row) => (
			<div style={{ whiteSpace: 'pre-line' }}>{row.maternityLeaves}</div>
		),
		childLeaveOfAbsences: (row) => (
			<div style={{ whiteSpace: 'pre-line' }}>{row.childLeaveOfAbsences}</div>
		),
		childShortTimes: (row) => (
			<div style={{ whiteSpace: 'pre-line' }}>{row.childShortTimes}</div>
		),
		leaveOfAbsences: (row) => (
			<div style={{ whiteSpace: 'pre-line' }}>{row.leaveOfAbsences}</div>
		),
	};

	return (
		<>
			<h2 style={{ textAlign: 'center' }}>직원별 현재 근로계약상 임금 정보</h2>

			{workersList.length !== 0 ? (
				<InfiniteScroll
					pageStart={0}
					loadMore={getNextClientReqs}
					hasMore={!loading && moreClientReqs}
					initialLoad={false}>
					<SortableTable
						headers={headers}
						data={data}
						sortFunctions={sortFunctions}
						title={title}
						customRenderers={customRenderers}
						enableSearch={true}
					/>
				</InfiniteScroll>
			) : (
				<div>데이터가 없습니다.</div>
			)}
		</>
	);
}
