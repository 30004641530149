import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import SortableTable from '../../../../../app/common/form/SortableTable';
import {
	numberToCommaString,
	roundUp2,
	safeStringCompare,
} from '../../../../../app/common/util/util';

export default function WorkerTimeInfoList({
	workersList = [], // 기본값 설정
	getNextClientReqs,
	loading,
	moreClientReqs,
	title,
}) {
	// 특정 열이 존재하는지 확인
	const hasExpectWeeklyOverWorks = workersList.some(
		(workerList) => workerList?.realWorkTime?.expectWeeklyOverWorks
	);
	const hasExpectWeeklyNightWorks = workersList.some(
		(workerList) => workerList?.realWorkTime?.expectWeeklyNightWorks
	);

	const hasMonthlyConverseMonthOWT = workersList.some(
		(workerList) => workerList?.calWorkTime?.converseMonthOWT
	);

	const hasMonthlyConverseMonthHWT = workersList.some(
		(workerList) => workerList?.calWorkTime?.converseMonthNWT
	);

	const hasMonthlyConverseMonthHoliBasicTime = workersList.some(
		(workerList) => workerList?.calWorkTime?.converseMonthHoliBasicTime
	);

	const hasMonthlyConversemonthOWTHoli = workersList.some(
		(workerList) => workerList?.calWorkTime?.conversemonthOWTHoli
	);

	const hasMonthlyConverseMonthNWTHoli = workersList.some(
		(workerList) => workerList?.calWorkTime?.converseMonthNWTHoli
	);

	const headers = [
		{ key: 'workerDivisions', label: '부서' },
		{ key: 'workerTitles', label: '직위/직책' },
		{ key: 'status', label: '직원구분' },
		{ key: 'name', label: '직원성명' },
		{ key: 'workerEnteredDate', label: '입사일' },
		{ key: 'retiredDate', label: '퇴직일' },

		{ key: 'ordinaryTimeWage', label: '시간급 통상임금' },
		{ key: 'weekLBTime', label: '1주 소정근로시간' },
		{ key: 'paidHolidayLBTime', label: '유급주휴시간' },
		...(hasExpectWeeklyOverWorks
			? [{ key: 'expectWeeklyOverWorks', label: '1주 포괄약정연장시간' }]
			: []),
		...(hasExpectWeeklyNightWorks
			? [{ key: 'expectWeeklyNightWorks', label: '1주 포괄약정야간시간' }]
			: []),
		{ key: 'monthLBTime', label: '월 통상임금 산정기준 시간수' },
		{ key: 'mTPCTime', label: '1개월 총 유급시간' },

		...(hasMonthlyConverseMonthOWT
			? [{ key: 'converseMonthOWT', label: '1개월 연장(환산)시간' }]
			: []),
		...(hasMonthlyConverseMonthHWT
			? [{ key: 'converseMonthNWT', label: '1개월 야간(환산)시간' }]
			: []),
		...(hasMonthlyConverseMonthHoliBasicTime
			? [
					{
						key: 'converseMonthHoliBasicTime',
						label: '1개월 휴일기본(환산)시간',
					},
			  ]
			: []),
		...(hasMonthlyConversemonthOWTHoli
			? [{ key: 'conversemonthOWTHoli', label: '1개월 휴일연장(환산)시간' }]
			: []),
		...(hasMonthlyConverseMonthNWTHoli
			? [{ key: 'converseMonthNWTHoli', label: '1개월 휴일야간(환산)시간' }]
			: []),
	];

	const sortFunctions = {
		status: (a, b) => safeStringCompare(a.status, b.status),
		name: (a, b) => safeStringCompare(a.name, b.name),
		workerDivisions: (a, b) =>
			safeStringCompare(a.workerDivisions, b.workerDivisions),
		workerTitles: (a, b) => safeStringCompare(a.workerTitles, b.workerTitles),

		ordinaryTimeWage: (a, b) =>
			safeStringCompare(a.ordinaryTimeWage, b.ordinaryTimeWage),
		paidHolidayLBTime: (a, b) =>
			safeStringCompare(a.paidHolidayLBTime, b.paidHolidayLBTime),

		ordinaryDayWage: (a, b) =>
			safeStringCompare(a.ordinaryDayWage, b.ordinaryDayWage),

		expectWeeklyOverWorks: (a, b) =>
			safeStringCompare(a.expectWeeklyOverWorks, b.expectWeeklyOverWorks),
		expectWeeklyNightWorks: (a, b) =>
			safeStringCompare(a.expectWeeklyNightWorks, b.expectWeeklyNightWorks),

		workerEnteredDate: (a, b) =>
			safeStringCompare(a.workerEnteredDate, b.workerEnteredDate),
		retiredDate: (a, b) => safeStringCompare(a.retiredDate, b.retiredDate),
	};

	const data = workersList.map((workerList) => {
		let status = '정규직';
		if (workerList?.dayWorker?.daylyPay > 0) {
			status = '일용직';
		} else if (workerList?.dayWorker?.timeWage > 0) {
			status = '시급직';
		} else if (workerList?.isBizWorker) {
			status = '사업소득직';
		} else if (workerList?.contractEndDate) {
			status = '계약직';
		}

		const workerEnteredDate = new Date(workerList?.workerEnteredDate);
		const formattedEnteredDate = workerEnteredDate.toISOString().split('T')[0];
		const retiredDate = workerList?.retiredDate;
		const formattedRetiredDate = retiredDate
			? retiredDate.toISOString().split('T')[0]
			: null;

		// 시간급 통상임금
		const ordinaryTimeWage = numberToCommaString(
			roundUp2(
				workerList?.dayWorker?.timeWage > 0
					? workerList?.dayWorker?.timeWage
					: workerList?.dayWorker?.daylyPay > 0
					? workerList?.dayWorker?.realTimeWage
					: workerList?.lawBase?.hourOrdinaryWage || 0
			)
		);

		const ordinaryDayWage = numberToCommaString(
			roundUp2(
				Number(workerList?.lawBase?.hourOrdinaryWage || 0) *
					Number(workerList?.lawBase?.paidHolidayLBTime || 0)
			)
		);

		const paidHolidayLBTime = numberToCommaString(
			workerList?.lawBase?.paidHolidayLBTime
		);

		const mTPCTime = roundUp2(workerList?.calWorkTime?.mTPCTime);
		const converseMonthOWT = roundUp2(
			workerList?.calWorkTime?.converseMonthOWT
		);
		const converseMonthNWT = roundUp2(
			workerList?.calWorkTime?.converseMonthNWT
		);
		const converseMonthHoliBasicTime = roundUp2(
			workerList?.calWorkTime?.converseMonthHoliBasicTime
		);
		const conversemonthOWTHoli = roundUp2(
			workerList?.calWorkTime?.conversemonthOWTHoli
		);
		const converseMonthNWTHoli = roundUp2(
			workerList?.calWorkTime?.converseMonthNWTHoli
		);

		const monthLBTime = roundUp2(workerList?.lawBase?.monthLBTime);
		const weekLBTime = roundUp2(workerList?.lawBase?.weekLBTime);

		return {
			status: status,
			workerDivisions: workerList?.workerDivisions || '부서 없음',
			workerTitles: workerList?.workerTitles || '직위 없음',
			name: workerList?.worker?.name,
			workerEnteredDate: formattedEnteredDate,
			retiredDate: formattedRetiredDate,

			ordinaryTimeWage: ordinaryTimeWage || 0,
			paidHolidayLBTime: paidHolidayLBTime || 0,
			monthLBTime: monthLBTime || 0,
			weekLBTime: weekLBTime || 0,
			ordinaryDayWage: ordinaryDayWage || 0,
			mTPCTime: mTPCTime || 0,
			converseMonthOWT: converseMonthOWT || 0,
			converseMonthNWT: converseMonthNWT || 0,
			converseMonthHoliBasicTime: converseMonthHoliBasicTime || 0,
			conversemonthOWTHoli: conversemonthOWTHoli || 0,
			converseMonthNWTHoli: converseMonthNWTHoli || 0,

			expectWeeklyOverWorks:
				workerList?.realWorkTime?.expectWeeklyOverWorks || 0,
			expectWeeklyNightWorks:
				workerList?.realWorkTime?.expectWeeklyNightWorks || 0,
		};
	});

	const customRenderers = {
		maternityLeaves: (row) => (
			<div style={{ whiteSpace: 'pre-line' }}>{row.maternityLeaves}</div>
		),
		childLeaveOfAbsences: (row) => (
			<div style={{ whiteSpace: 'pre-line' }}>{row.childLeaveOfAbsences}</div>
		),
		childShortTimes: (row) => (
			<div style={{ whiteSpace: 'pre-line' }}>{row.childShortTimes}</div>
		),
		leaveOfAbsences: (row) => (
			<div style={{ whiteSpace: 'pre-line' }}>{row.leaveOfAbsences}</div>
		),
	};

	return (
		<>
			<h2 style={{ textAlign: 'center' }}>
				직원별 현재 근로계약상 근로시간 정보
			</h2>

			{workersList.length !== 0 ? (
				<InfiniteScroll
					pageStart={0}
					loadMore={getNextClientReqs}
					hasMore={!loading && moreClientReqs}
					initialLoad={false}>
					<br />
					<SortableTable
						headers={headers}
						data={data}
						sortFunctions={sortFunctions}
						title={title}
						customRenderers={customRenderers}
						enableSearch={true}
					/>
				</InfiniteScroll>
			) : (
				<div>데이터가 없습니다.</div>
			)}
		</>
	);
}
