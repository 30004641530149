import { financeEnterForYear } from './changeDateALF.js';
import moment from 'moment';

//오늘 현재 일자 계산 회계년도 기본년차
export function calALFinTodBagicYear(
	enterDate,
	finBaseDate,
	workersNumber,
	specificDate,
	retiredDate
) {
	const enterD = moment(
		financeEnterForYear(enterDate, finBaseDate),
		'YYYY-MM-DD'
	);

	const validSpecificDate =
		specificDate && specificDate !== ''
			? specificDate
			: moment().format('YYYY-MM-DD'); // 현재 날짜를 YYYY-MM-DD 형식으로 변환

	const executeTodayDate =
		retiredDate && retiredDate < validSpecificDate
			? moment(retiredDate, 'YYYY-MM-DD')
			: moment(validSpecificDate, 'YYYY-MM-DD');

	const emplPeriodY = executeTodayDate.diff(enterD, 'years');
	return parseInt(workersNumber) < 5 ? 0 : emplPeriodY >= 1 ? 15 : 0;
}

//오늘 현재 일자 계산 회계년도 가산연차
export function calALFinTodPlusYear(
	enterDate,
	finBaseDate,
	workersNumber,
	specificDate,
	retiredDate
) {
	const enterD = moment(
		financeEnterForYear(enterDate, finBaseDate),
		'YYYY-MM-DD'
	);

	const validSpecificDate =
		specificDate && specificDate !== ''
			? specificDate
			: moment().format('YYYY-MM-DD'); // 현재 날짜를 YYYY-MM-DD 형식으로 변환

	const executeTodayDate =
		retiredDate && retiredDate < validSpecificDate
			? moment(retiredDate, 'YYYY-MM-DD')
			: moment(validSpecificDate, 'YYYY-MM-DD');

	const emplPeriodY = executeTodayDate.diff(enterD, 'years');
	const sumPlus = emplPeriodY >= 3 ? Math.floor((emplPeriodY - 1) / 2) : 0;
	return parseInt(workersNumber) < 5 ? 0 : sumPlus >= 10 ? 10 : sumPlus;
}

// 오늘 현재 일자 계산 회계기준 비례연차 일수 구하기
export function calALFinTodRatioYear(
	enterDate,
	finBaseDate,
	workersNumber,
	specificDate,
	retiredDate
) {
	const realEntD = moment(enterDate, 'YYYY-MM-DD');
	const enterDFi = moment(
		financeEnterForYear(enterDate, finBaseDate),
		'YYYY-MM-DD'
	);
	const enterFiPlusOneYear = moment([
		enterDFi.get('year') + 1,
		enterDFi.get('month'),
		enterDFi.get('date'),
	]);

	const validSpecificDate =
		specificDate && specificDate !== ''
			? specificDate
			: moment().format('YYYY-MM-DD'); // 현재 날짜를 YYYY-MM-DD 형식으로 변환

	const executeTodayDate =
		retiredDate && retiredDate < validSpecificDate
			? moment(retiredDate, 'YYYY-MM-DD')
			: moment(validSpecificDate, 'YYYY-MM-DD');

	const ratioDayCount = enterDFi.diff(realEntD, 'days');
	const ratioDay = executeTodayDate.isBetween(
		enterDFi,
		enterFiPlusOneYear,
		undefined,
		'[]'
	)
		? Math.ceil((ratioDayCount / 365) * 15)
		: 0;
	return parseInt(workersNumber) < 5 ? 0 : ratioDay;
}
