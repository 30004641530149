import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import SortableTable from '../../../../../app/common/form/SortableTable';
import {
	numberToCommaString,
	roundUp,
	roundUp2,
	safeStringCompare,
} from '../../../../../app/common/util/util';
import moment from 'moment';

export default function WorkerLongTermLeavesInfoList({
	workersList = [], // 기본값 설정
	getNextClientReqs,
	loading,
	moreClientReqs,
	title,
}) {
	const headers = [
		{ key: 'workerDivisions', label: '부서' },
		{ key: 'workerTitles', label: '직위/직책' },
		{ key: 'status', label: '직원구분' },
		{ key: 'name', label: '직원성명' },
		{ key: 'workerEnteredDate', label: '입사일' },
		{ key: 'retiredDate', label: '퇴직일' },
		{ key: 'monthSalary', label: '책정 월급액' },
		{ key: 'ordinaryTimeWage', label: '시간급 통상임금' },
		{ key: 'paidHolidayLBTime', label: '1일 소정근로시간' },
		{ key: 'ordinaryDayWage', label: '일급 통상임금' },
		{ key: 'maternityLeaves', label: '출산휴가' },
		{ key: 'childLeaveOfAbsences', label: '육아휴직' },
		{ key: 'childShortTimes', label: '육아근로단축' },
		{ key: 'leaveOfAbsences', label: '기타개인휴직' },
	];

	const sortFunctions = {
		status: (a, b) => safeStringCompare(a.status, b.status),
		name: (a, b) => safeStringCompare(a.name, b.name),
		workerDivisions: (a, b) =>
			safeStringCompare(a.workerDivisions, b.workerDivisions),
		workerTitles: (a, b) => safeStringCompare(a.workerTitles, b.workerTitles),
		monthSalary: (a, b) => safeStringCompare(a.monthSalary, b.monthSalary),

		ordinaryTimeWage: (a, b) =>
			safeStringCompare(a.ordinaryTimeWage, b.ordinaryTimeWage),
		paidHolidayLBTime: (a, b) =>
			safeStringCompare(a.paidHolidayLBTime, b.paidHolidayLBTime),

		ordinaryDayWage: (a, b) =>
			safeStringCompare(a.ordinaryDayWage, b.ordinaryDayWage),

		maternityLeaves: (a, b) =>
			safeStringCompare(a.maternityLeaves, b.maternityLeaves),
		childLeaveOfAbsences: (a, b) =>
			safeStringCompare(a.childLeaveOfAbsences, b.childLeaveOfAbsences),
		childShortTimes: (a, b) =>
			safeStringCompare(a.childShortTimes, b.childShortTimes),

		leaveOfAbsences: (a, b) =>
			safeStringCompare(a.leaveOfAbsences, b.leaveOfAbsences),

		workerEnteredDate: (a, b) =>
			safeStringCompare(a.workerEnteredDate, b.workerEnteredDate),
		retiredDate: (a, b) => safeStringCompare(a.retiredDate, b.retiredDate),
	};

	const data = workersList.map((workerList) => {
		let status = '정규직';
		if (workerList?.dayWorker?.daylyPay > 0) {
			status = '일용직';
		} else if (workerList?.dayWorker?.timeWage > 0) {
			status = '시급직';
		} else if (workerList?.isBizWorker) {
			status = '사업소득직';
		} else if (workerList?.contractEndDate) {
			status = '계약직';
		}

		const workerEnteredDate = new Date(workerList?.workerEnteredDate);
		const formattedEnteredDate = moment(workerEnteredDate).format('YYYY-MM-DD');
		const retiredDate = workerList?.retiredDate;
		const formattedRetiredDate = retiredDate
			? moment(retiredDate).format('YYYY-MM-DD')
			: null;

		// 출산휴가 데이터 처리
		const maternityLeaves = workerList?.maternityLeaves
			?.map((leave) => {
				const startDateV = new Date(
					leave.maternityLeaveStartDate.seconds * 1000
				);
				const startDate = moment(startDateV).format('YYYY-MM-DD');

				const endDateV = leave.maternityLeaveEndDate
					? new Date(leave.maternityLeaveEndDate.seconds * 1000)
					: new Date();

				const endDate = moment(endDateV).format('YYYY-MM-DD');

				return `(${leave.maternityLeaveType}\n ${startDate} ~ ${endDate} )`;
			})
			.join('\n');

		// 육아휴직 데이터 처리
		const childLeaveOfAbsences = workerList?.childLeaveOfAbsences
			?.map((leave) => {
				const startDateV = new Date(
					leave.childLeaveOfAbsenceStartDate.seconds * 1000
				);

				const endDateV = leave.childLeaveOfAbsenceEndDate
					? new Date(leave.childLeaveOfAbsenceEndDate.seconds * 1000)
					: new Date();

				const startDate = moment(startDateV).format('YYYY-MM-DD');
				const endDate = moment(endDateV).format('YYYY-MM-DD');

				return `(${leave.childLeaveOfAbsenceType})\n ${startDate} ~ ${endDate} `;
			})
			.join('\n'); // 줄 바꿈으로 조합

		// 육아기 근로시간 단축 데이터 처리
		const childShortTimes = workerList?.childShortTimes
			?.map((leave) => {
				const startDateV = new Date(
					leave.childShortTimeStartDate.seconds * 1000
				);

				const endDateV = leave.childShortTimeEndDate
					? new Date(leave.childShortTimeEndDate.seconds * 1000)
					: new Date();

				const startDate = moment(startDateV).format('YYYY-MM-DD');
				const endDate = moment(endDateV).format('YYYY-MM-DD');
				return `(${leave.childShortTimeType})\n ${startDate} ~ ${endDate} `;
			})
			.join('\n'); // 줄 바꿈으로 조합

		// 육아기 근로시간 단축 데이터 처리
		const leaveOfAbsences = workerList?.leaveOfAbsences
			?.map((leave) => {
				const startDateV = new Date(
					leave.leaveOfAbsenceStartDate.seconds * 1000
				);

				const endDateV = leave.leaveOfAbsenceEndDate
					? new Date(leave.leaveOfAbsenceEndDate.seconds * 1000)
					: new Date();

				const startDate = moment(startDateV).format('YYYY-MM-DD');
				const endDate = moment(endDateV).format('YYYY-MM-DD');
				return `(${leave.leaveOfAbsenceType})\n ${startDate} ~ ${endDate} `;
			})
			.join('\n'); // 줄 바꿈으로 조합

		const monthSalary = numberToCommaString(workerList?.totalMonthWageAmount);

		// 시간급 통상임금
		const ordinaryTimeWage = numberToCommaString(
			roundUp2(
				workerList?.dayWorker?.timeWage > 0
					? workerList?.dayWorker?.timeWage
					: workerList?.dayWorker?.daylyPay > 0
					? workerList?.dayWorker?.realTimeWage
					: workerList?.lawBase?.hourOrdinaryWage || 0
			)
		);

		const ordinaryDayWage = numberToCommaString(
			roundUp(
				Number(workerList?.lawBase?.hourOrdinaryWage || 0) *
					Number(workerList?.lawBase?.paidHolidayLBTime || 0)
			)
		);

		const paidHolidayLBTime = numberToCommaString(
			workerList?.lawBase?.paidHolidayLBTime
		);

		return {
			status: status,
			workerDivisions: workerList?.workerDivisions || '부서 없음',
			workerTitles: workerList?.workerTitles || '직위 없음',
			name: workerList?.worker?.name,
			workerEnteredDate: formattedEnteredDate,
			retiredDate: formattedRetiredDate,
			monthSalary: monthSalary || 0,
			ordinaryTimeWage: ordinaryTimeWage || 0,
			paidHolidayLBTime: paidHolidayLBTime || 0,
			ordinaryDayWage: ordinaryDayWage || 0,
			maternityLeaves: maternityLeaves || '없음',
			childLeaveOfAbsences: childLeaveOfAbsences || '없음',
			childShortTimes: childShortTimes || '없음',
			leaveOfAbsences: leaveOfAbsences || '없음',
		};
	});

	const customRenderers = {
		maternityLeaves: (row) => (
			<div style={{ whiteSpace: 'pre-line' }}>{row.maternityLeaves}</div>
		),
		childLeaveOfAbsences: (row) => (
			<div style={{ whiteSpace: 'pre-line' }}>{row.childLeaveOfAbsences}</div>
		),
		childShortTimes: (row) => (
			<div style={{ whiteSpace: 'pre-line' }}>{row.childShortTimes}</div>
		),
		leaveOfAbsences: (row) => (
			<div style={{ whiteSpace: 'pre-line' }}>{row.leaveOfAbsences}</div>
		),
	};

	return (
		<>
			<h2 style={{ textAlign: 'center' }}>
				직원별 현재 휴직(육아 등), 근로시간단축(육아 등) 정보
			</h2>

			{workersList.length !== 0 ? (
				<InfiniteScroll
					pageStart={0}
					loadMore={getNextClientReqs}
					hasMore={!loading && moreClientReqs}
					initialLoad={false}>
					<br />
					<SortableTable
						headers={headers}
						data={data}
						sortFunctions={sortFunctions}
						title={title}
						customRenderers={customRenderers}
						enableSearch={true}
					/>
				</InfiniteScroll>
			) : (
				<div>데이터가 없습니다.</div>
			)}
		</>
	);
}
