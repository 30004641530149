import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Grid, Button, Table } from 'semantic-ui-react';
import {
	calPaidHoliWageCount,
	calculateWeeklyHours,
	capValuesAt40,
	combineObjects,
	filterByValueRange,
	getCommonWeeks,
	getWeekNumbers,
	netAgeEnteredF,
	numStringToNumber,
	numberToCommaString,
	roundUp,
	roundUp2,
} from '../../../../app/common/util/util';
import { listenToClientReq4InFromFirestore } from '../../../../app/firestore/firestoreService4In';
import useFirestoreDoc from '../../../../app/hooks/useFirestoreDoc';
import LoadingComponent from '../../../../app/layout/LoadingComponent';
import { listenToSelectedClientReq4In } from '../clientReqActions4In';
import ClientReqDetailedHeader4In from './ClientReqDetailedHeader4In';
import ClientReqDLCJudgeWageTable from './clientReqDetailedLCDetail/ClientReqDLCJudgeWageTable';
import ClientReqDLCHeadWageTable from './clientReqDetailedLCDetail/ClientReqDLCHeadWageTable';
import ClientReqDLCWageTableDayWorkerPayDetail from './clientReqDetailedLCDetail/clientReqDLCWageTableDetail/payDetail/ClientReqDLCWageTableDayWorkerPayDetail';
import { decrypto } from '../../../../app/firestore/firestoreService';
import {
	clearPaydocu,
	fetchAllPaydocusFormFirestoreService4In,
	setWorkDatesPaydocu,
} from '../../../companyManage/companyActions';

import ClientReqDLCDayWorkerWageTableTimeDetail from './clientReqDetailedLCDetail/clientReqDLCWageTableDetail/timeDetail/ClientReqDLCDayWorkerWageTableTimeDetail';
import { toast } from 'react-toastify';
import ClientRBizDayTimeWorkerMonthWageTableBasicInfo from './ClientRBizDayTimeWorkerMonthWageTableBasicInfo';
import ClientRDaylyWorkerMonthWageTableAddWork from './ClientRDaylyWorkerMonthWageTableAddWork';

import WeekNumberInput from '../realForms/crwfDetail/WeekNumberInput';
import WeekNumberInputLastMonth from '../realForms/crwfDetail/WeekNumberInputLastMonth';
import WeekNumberInputDayWorker from '../realForms/crwfDetail/WeekNumberInputDayWorker';
import PaydocuShortCut from '../../../companyManage/paydocuDashboard/PaydocuShortCut';
import {
	useHistory,
	useLocation,
} from 'react-router-dom/cjs/react-router-dom.min';
import axios from 'axios';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../../../app/config/firebase';
// const API_BASE_URL = 'http://localhost:5000';
const API_BASE_URL = process.env.REACT_APP_API_SERVER_URL; // 실제 서버 URL로 변경하세요.

export default function ClientRDaylyWorkerMonthWageTable({ match }) {
	const history = useHistory();
	const dispatch = useDispatch();
	const location = useLocation();

	const { selectedClientReq4In, clientReqs4In } = useSelector(
		(state) => state.clientReq4InStore
	);
	const { paydocus, filter, workDates, clientInfo } = useSelector(
		(state) => state.companyReducer
	);
	const { loading, error } = useSelector((state) => state.async);
	const { currentUserProfile } = useSelector((state) => state.profile);
	const [bizIncomeValue, setBizIncomeValue] = useState('');
	const [bizDeductionAfterTaxValue, setBizDeductionAfterTaxValue] =
		useState('');
	const [bizDeductionBeforeTaxValue, setBizDeductionBeforeTaxValue] =
		useState('');
	const [
		bizDeductionBeforeTaxCommentValue,
		setBizDeductionBeforeTaxCommentValue,
	] = useState('');
	const [
		bizDeductionAfterTaxCommentValue,
		setBizDeductionAfterTaxCommentValue,
	] = useState('');

	function handelInputChange(e) {
		let { name, value } = e.target;

		// 콤마 제거 후 숫자로 변환 시도 (숫자 필드에만 적용)
		const numericValue = value.replace(/,/g, '');

		// 입력값이 비어있을 경우 빈 문자열로 처리
		if (numericValue === '') {
			setValues((prevValues) => ({
				...prevValues,
				[name]: '', // 빈 문자열로 저장
			}));
			return;
		}

		// 숫자 또는 소수점만 포함하는 경우 처리
		if (/^\d*\.?\d*$/.test(numericValue)) {
			const parts = numericValue.split('.');
			// 소수점 앞부분만 천 단위 구분 기호를 적용
			parts[0] = new Intl.NumberFormat().format(Number(parts[0] || 0));
			value = parts?.join('.');

			// 상태를 업데이트할 때는 콤마를 제거한 원본 문자열을 저장 (소수점 포함)
			setValues((prevValues) => ({
				...prevValues,
				[name]: numericValue, // 콤마 제거된 원본 값 저장
			}));
			return;
		}

		// thisMonth 필드를 처리할 때는 두 자리로 맞춤
		if (name === 'thisMonth') {
			value = value.padStart(2, '0'); // 두 자리로 맞추기
		}

		setValues((prevValues) => ({
			...prevValues,
			[name]: value, // 현재 필드 업데이트
		}));
	}
	const hostUid = currentUserProfile?.id;
	// const retiredWorker = selectedClientReq4In?.retiredDate ? true : false;
	// const retiredWorkerPaydocu = paydocus.slice(-1)[0]

	// const selectedPaydocu = retiredWorker ? retiredWorkerPaydocu : paydocus[0]
	let selectedPaydocu = paydocus[0];
	const normalWorkerWeekWorkDays = clientInfo?.normalWorkerWeekWorkDays
		? clientInfo?.normalWorkerWeekWorkDays
		: currentUserProfile?.normalWorkerWeekWorkDays;
	const daylyTimePay = selectedClientReq4In?.daylyPay?.timeWage
		? numStringToNumber(selectedClientReq4In?.daylyPay?.timeWage)
		: 0;

	const netAgeEntered = netAgeEnteredF(selectedClientReq4In);
	const isDayOrTimeWorker =
		selectedClientReq4In?.dayWorker?.daylyPay || daylyTimePay ? true : false;
	const isTimeWorker = selectedClientReq4In?.dayWorker?.timeWage ? true : false;
	const isBizWorker = selectedClientReq4In?.isBizWorker ? true : false;

	useFirestoreDoc({
		query: () => listenToClientReq4InFromFirestore(match.params.id),
		data: (selectedClientReq4In) =>
			dispatch(listenToSelectedClientReq4In(selectedClientReq4In)),
		deps: [match.params.id, dispatch],
	});

	const [values, setValues] = useState({
		id: match.params.id,
		workingDays: '',
		usedAnnualLeaves: '',
		usedAnnualLeaveDates: '',
		thisMonth: location.pathname.startsWith('/clientRDaylyWorkerMonthWageTable')
			? format(new Date(), 'MM')
			: '',
		thisYear: location.pathname.startsWith('/clientRDaylyWorkerMonthWageTable')
			? format(new Date(), 'yyyy')
			: '',
		dayWorkerPlusMonthOWT: '',
		dayWorkerPlusOverTimeDates: '',
		dayWorkerPlusMonthNWT: '',
		dayWorkerPlusNightTimeDates: '',
		dayWorkerPlusMonthHoliBasicTime: '',
		dayWorkerPlusHoliBasicTimeDates: '',
		dayWorkerPlusMonthOWTHoli: '',
		dayWorkerPlusHoliOverTimeDates: '',
		dayWorkerPlusMonthNWTHoli: '',
		dayWorkerPlusHoliNightTimeDates: '',
		dayWorkerAbsentTimes: '',
		dayWorkerAbsentTimeDates: '',
		dayWorkerSpecialBonus: '',
		timeWorkerPlusMonthOWT: '',
		timeWorkerPlusOverTimeDates: '',
		timeWorkerPlusMonthNWT: '',
		timeWorkerPlusNightTimeDates: '',
		timeWorkerPlusMonthHoliBasicTime: '',
		timeWorkerPlusHoliBasicTimeDates: '',
		timeWorkerPlusMonthOWTHoli: '',
		timeWorkerPlusHoliOverTimeDates: '',
		timeWorkerPlusMonthNWTHoli: '',
		timeWorkerPlusHoliNightTimeDates: '',
		timeWorkerPlusMonthOffBasicTime: '',
		timeWorkerPlusOffBasicTimeDates: '',
		timeWorkerPlusMonthOWTOff: '',
		timeWorkerPlusOffOverTimeDates: '',
		timeWorkerPaidHoli: '',
		timeWorkerPaidHoliDates: '',
		timeWorkerSpecialBonus: '',
		countOfNomalDayWork: '',
		countOfHoliDayWork: '',
		countOfOverWorkingDayWork: '',
		workDates: [],
		dayWorkerCAWage5: '',
		dayWorkerCAWEName5: '',
		dayWorkerCAWage5Comment: '',
		dayWorkerCAWage6: '',
		dayWorkerCAWEName6: '',
		dayWorkerCAWage6Comment: '',
		dayWorkerCAWage7: '',
		dayWorkerCAWEName7: '',
		dayWorkerCAWage7Comment: '',
		dayWorkerUserFixedTax: '',
		dayWorkerUserFixedTaxComment: '',
		dayWorkerUserFixedRegionTax: '',
		dayWorkerUserFixedRegionTaxComment: '',
		dayWorkerUserFixedHealthCare: '',
		dayWorkerUserFixedHealthCareComment: '',
		dayWorkerUserFixedOldCare: '',
		dayWorkerUserFixedOldCareComment: '',
		dayWorkerUserFixedPension: '',
		dayWorkerUserFixedPensionComment: '',
		dayWorkerUserFixedNoJobInsurance: '',
		dayWorkerUserFixedNoJobInsuranceComment: '',
		dayWorkerDeductEtcName1: '',
		dayWorkerDeductEtcWage1: '',
		dayWorkerDeductEtcWage1Comment: '',
		dayWorkerDeductEtcName2: '',
		dayWorkerDeductEtcWage2: '',
		dayWorkerDeductEtcWage2Comment: '',
		dayWorkerDeductEtcName3: '',
		dayWorkerDeductEtcWage3: '',
		dayWorkerDeductEtcWage3Comment: '',
		dayWorkerPlusDeductHealthCare: '',
		dayWorkerPlusDeductOldCare: '',
		dayWorkerPlusDeductWorkTax: '',
		dayWorkerPlusDeductWorkRegionTax: '',
		dayWorkerPlusDeductDuru: '',
		dayWorkerPlusMinus4Ins: '',
		dayWorkerPlusDeductWorkerCompanyLoan: '',
		dayWorkerPlusDeductHealthCareComment: '',
		dayWorkerPlusDeductOldCareComment: '',
		dayWorkerPlusDeductWorkTaxComment: '',
		dayWorkerPlusDeductWorkRegionTaxComment: '',
		dayWorkerPlusMinus4InsComment: '',
		dayWorkerPlusDeductWorkerCompanyLoanComment: '',
		bizIncome: '',
		bizDeductionBeforeTax: '',
		bizDeductionBeforeTaxComment: '',
		bizDeductionAfterTax: '',
		bizDeductionAfterTaxComment: '',
	});

	const yearMonth = values?.thisYear + values?.thisMonth;

	useEffect(() => {
		if (
			selectedClientReq4In?.id &&
			location.pathname.startsWith('/manageClientRDaylyWorkerMonthWageTable')
		) {
			dispatch(
				fetchAllPaydocusFormFirestoreService4In(
					'isDayWorker',
					selectedClientReq4In.id,
					yearMonth
				)
			).then(() => {});
			return () => {
				dispatch(clearPaydocu());
			};
		}
	}, [dispatch, selectedClientReq4In?.id, location.pathname, yearMonth]);

	useEffect(() => {
		if (selectedPaydocu) {
			setBizIncomeValue(selectedPaydocu?.bizIncome);
			setBizDeductionBeforeTaxValue(selectedPaydocu?.bizDeductionBeforeTax);
			setBizDeductionBeforeTaxCommentValue(
				selectedPaydocu?.bizDeductionBeforeTaxComment
			);
			setBizDeductionAfterTaxValue(selectedPaydocu?.bizDeductionAfterTax);
			setBizDeductionAfterTaxCommentValue(
				selectedPaydocu?.bizDeductionAfterTaxComment
			);

			setValues((preValues) => ({
				...preValues,
				id: match.params.id,
				workingDays: '',
				usedAnnualLeaves: +selectedPaydocu?.usedAnnualLeaves || '',
				usedAnnualLeaveDates: selectedPaydocu?.usedAnnualLeaveDates || '',
				thisMonth: selectedPaydocu?.info?.month || format(new Date(), 'MM'),
				thisYear: selectedPaydocu?.info?.year || format(new Date(), 'yyyy'),
				dayWorkerPlusMonthOWT:
					+selectedPaydocu?.dayWorkerInfo?.dayWorkerPlusOTime || '',
				dayWorkerPlusOverTimeDates:
					selectedPaydocu?.dayWorkerInfo?.dayWorkerPlusOverTimeDates || '',
				dayWorkerPlusMonthNWT:
					+selectedPaydocu?.dayWorkerInfo?.dayWorkerPlusNTime || '',
				dayWorkerPlusNightTimeDates:
					selectedPaydocu?.dayWorkerInfo?.dayWorkerPlusNightTimeDates || '',
				dayWorkerPlusMonthHoliBasicTime:
					+selectedPaydocu?.dayWorkerInfo?.dayWorkerPlusHBTime || '',
				dayWorkerPlusHoliBasicTimeDates:
					selectedPaydocu?.dayWorkerInfo?.dayWorkerPlusHoliBasicTimeDates || '',
				dayWorkerPlusMonthOWTHoli:
					+selectedPaydocu?.dayWorkerInfo?.dayWorkerPlusHOTime || '',
				dayWorkerPlusHoliOverTimeDates:
					selectedPaydocu?.dayWorkerInfo?.dayWorkerPlusHoliOverTimeDates || '',
				dayWorkerPlusMonthNWTHoli:
					+selectedPaydocu?.dayWorkerInfo?.dayWorkerPlusHNTime || '',
				dayWorkerPlusHoliNightTimeDates:
					selectedPaydocu?.dayWorkerInfo?.dayWorkerPlusHoliNightTimeDates || '',
				dayWorkerAbsentTimes:
					+selectedPaydocu?.dayWorkerInfo?.dayWorkerAbsentTimes || '',
				dayWorkerAbsentTimeDates:
					selectedPaydocu?.dayWorkerInfo?.dayWorkerAbsentTimeDates || '',
				dayWorkerSpecialBonus:
					+selectedPaydocu?.dayWorkerInfo?.dayWorkerSpecialBonus || '',
				timeWorkerPlusMonthOWT:
					+selectedPaydocu?.dayWorkerInfo?.timeWorkerPlusMonthOWT || '',
				timeWorkerPlusOverTimeDates:
					selectedPaydocu?.dayWorkerInfo?.timeWorkerPlusOverTimeDates || '',
				timeWorkerPlusMonthNWT:
					+selectedPaydocu?.dayWorkerInfo?.timeWorkerPlusMonthNWT || '',
				timeWorkerPlusNightTimeDates:
					selectedPaydocu?.dayWorkerInfo?.timeWorkerPlusNightTimeDates || '',
				timeWorkerPlusMonthHoliBasicTime:
					+selectedPaydocu?.dayWorkerInfo?.timeWorkerPlusMonthHoliBasicTime ||
					'',
				timeWorkerPlusHoliBasicTimeDates:
					selectedPaydocu?.dayWorkerInfo?.timeWorkerPlusHoliBasicTimeDates ||
					'',
				timeWorkerPlusMonthOWTHoli:
					+selectedPaydocu?.dayWorkerInfo?.timeWorkerPlusMonthOWTHoli || '',
				timeWorkerPlusHoliOverTimeDates:
					selectedPaydocu?.dayWorkerInfo?.timeWorkerPlusHoliOverTimeDates || '',
				timeWorkerPlusMonthNWTHoli:
					+selectedPaydocu?.dayWorkerInfo?.timeWorkerPlusMonthNWTHoli || '',
				timeWorkerPlusHoliNightTimeDates:
					selectedPaydocu?.dayWorkerInfo?.timeWorkerPlusHoliNightTimeDates ||
					'',
				timeWorkerPlusMonthOffBasicTime:
					+selectedPaydocu?.dayWorkerInfo?.timeWorkerPlusMonthOffBasicTime ||
					'',
				timeWorkerPlusOffBasicTimeDates:
					selectedPaydocu?.dayWorkerInfo?.timeWorkerPlusOffBasicTimeDates || '',
				timeWorkerPlusMonthOWTOff:
					+selectedPaydocu?.dayWorkerInfo?.timeWorkerPlusMonthOWTOff || '',
				timeWorkerPlusOffOverTimeDates:
					selectedPaydocu?.dayWorkerInfo?.timeWorkerPlusOffOverTimeDates || '',
				timeWorkerPaidHoli:
					+selectedPaydocu?.dayWorkerInfo?.timeWorkerPaidHoli || '',
				timeWorkerPaidHoliDates:
					selectedPaydocu?.dayWorkerInfo?.timeWorkerPaidHoliDates || '',
				timeWorkerSpecialBonus:
					+selectedPaydocu?.dayWorkerInfo?.timeWorkerSpecialBonus || '',
				countOfNomalDayWork:
					+selectedPaydocu?.dayWorkerInfo?.countOfNomalDayWork || '',
				countOfHoliDayWork:
					+selectedPaydocu?.dayWorkerInfo?.countOfHoliDayWork || '',
				countOfOverWorkingDayWork:
					+selectedPaydocu?.dayWorkerInfo?.countOfOverWorkingDayWork || '',
				workDates: selectedPaydocu?.workerInfo?.workDates || [],
				dayWorkerCAWage5:
					+selectedPaydocu?.dayWorkerInfo?.dayWorkerCAWage5 || '',
				dayWorkerCAWEName5:
					selectedPaydocu?.dayWorkerInfo?.dayWorkerCAWEName5 || '',
				dayWorkerCAWage5Comment:
					selectedPaydocu?.dayWorkerInfo?.dayWorkerCAWage5Comment || '',
				dayWorkerCAWage6:
					+selectedPaydocu?.dayWorkerInfo?.dayWorkerCAWage6 || '',
				dayWorkerCAWEName6:
					selectedPaydocu?.dayWorkerInfo?.dayWorkerCAWEName6 || '',
				dayWorkerCAWage6Comment:
					selectedPaydocu?.dayWorkerInfo?.dayWorkerCAWage6Comment || '',
				dayWorkerCAWage7:
					+selectedPaydocu?.dayWorkerInfo?.dayWorkerCAWage7 || '',
				dayWorkerCAWEName7:
					selectedPaydocu?.dayWorkerInfo?.dayWorkerCAWEName7 || '',
				dayWorkerCAWage7Comment:
					selectedPaydocu?.dayWorkerInfo?.dayWorkerCAWage7Comment || '',
				dayWorkerUserFixedTax:
					+selectedPaydocu?.dayWorkerInfo?.dayWorkerUserFixedTax || '',
				dayWorkerUserFixedTaxComment:
					selectedPaydocu?.dayWorkerInfo?.dayWorkerUserFixedTaxComment || '',
				dayWorkerUserFixedRegionTax:
					+selectedPaydocu?.dayWorkerInfo?.dayWorkerUserFixedRegionTax || '',
				dayWorkerUserFixedRegionTaxComment:
					selectedPaydocu?.dayWorkerInfo?.dayWorkerUserFixedRegionTaxComment ||
					'',
				dayWorkerUserFixedHealthCare:
					+selectedPaydocu?.dayWorkerInfo?.dayWorkerUserFixedHealthCare || '',
				dayWorkerUserFixedHealthCareComment:
					selectedPaydocu?.dayWorkerInfo?.dayWorkerUserFixedHealthCareComment ||
					'',
				dayWorkerUserFixedOldCare:
					+selectedPaydocu?.dayWorkerInfo?.dayWorkerUserFixedOldCare || '',
				dayWorkerUserFixedOldCareComment:
					selectedPaydocu?.dayWorkerInfo?.dayWorkerUserFixedOldCareComment ||
					'',
				dayWorkerUserFixedPension:
					+selectedPaydocu?.dayWorkerInfo?.dayWorkerUserFixedPension || '',
				dayWorkerUserFixedPensionComment:
					selectedPaydocu?.dayWorkerInfo?.dayWorkerUserFixedPensionComment ||
					'',
				dayWorkerUserFixedNoJobInsurance:
					+selectedPaydocu?.dayWorkerInfo?.dayWorkerUserFixedNoJobInsurance ||
					'',
				dayWorkerUserFixedNoJobInsuranceComment:
					selectedPaydocu?.dayWorkerInfo
						?.dayWorkerUserFixedNoJobInsuranceComment || '',
				dayWorkerDeductEtcName1:
					selectedPaydocu?.dayWorkerInfo?.dayWorkerDeductEtcName1 || '',
				dayWorkerDeductEtcWage1:
					+selectedPaydocu?.dayWorkerInfo?.dayWorkerDeductEtcWage1 || '',
				dayWorkerDeductEtcWage1Comment:
					selectedPaydocu?.dayWorkerInfo?.dayWorkerDeductEtcWage1Comment || '',
				dayWorkerDeductEtcName2:
					selectedPaydocu?.dayWorkerInfo?.dayWorkerDeductEtcName2 || '',
				dayWorkerDeductEtcWage2:
					+selectedPaydocu?.dayWorkerInfo?.numDayWorkerDeductEtcWage2 || '',
				dayWorkerDeductEtcWage2Comment:
					selectedPaydocu?.dayWorkerInfo?.dayWorkerDeductEtcWage2Comment || '',
				dayWorkerDeductEtcName3:
					selectedPaydocu?.dayWorkerInfo?.dayWorkerDeductEtcName3 || '',
				dayWorkerDeductEtcWage3:
					+selectedPaydocu?.dayWorkerInfo?.numDayWorkerDeductEtcWage3 || '',
				dayWorkerDeductEtcWage3Comment:
					selectedPaydocu?.dayWorkerDeductEtcWage3Comment || '',
				dayWorkerPlusDeductHealthCare:
					+selectedPaydocu?.dayWorkerInfo?.dayWorkerPlusDeductHealthCare || '',
				dayWorkerPlusDeductOldCare:
					+selectedPaydocu?.dayWorkerInfo?.dayWorkerPlusDeductOldCare || '',
				dayWorkerPlusDeductWorkTax:
					+selectedPaydocu?.dayWorkerInfo?.dayWorkerPlusDeductWorkTax || '',
				dayWorkerPlusDeductWorkRegionTax:
					+selectedPaydocu?.dayWorkerInfo?.dayWorkerPlusDeductWorkRegionTax ||
					'',
				dayWorkerPlusDeductDuru:
					+selectedPaydocu?.dayWorkerInfo?.dayWorkerPlusDeductDuru || '',
				dayWorkerPlusMinus4Ins:
					+selectedPaydocu?.dayWorkerInfo?.dayWorkerPlusMinus4Ins || '',
				dayWorkerPlusDeductWorkerCompanyLoan:
					+selectedPaydocu?.dayWorkerInfo
						?.dayWorkerPlusDeductWorkerCompanyLoan || '',
				dayWorkerPlusDeductHealthCareComment:
					selectedPaydocu?.dayWorkerInfo
						?.dayWorkerPlusDeductHealthCareComment || '',
				dayWorkerPlusDeductOldCareComment:
					selectedPaydocu?.dayWorkerInfo?.dayWorkerPlusDeductOldCareComment ||
					'',
				dayWorkerPlusDeductWorkTaxComment:
					selectedPaydocu?.dayWorkerInfo?.dayWorkerPlusDeductWorkTaxComment ||
					'',
				dayWorkerPlusDeductWorkRegionTaxComment:
					selectedPaydocu?.dayWorkerInfo
						?.dayWorkerPlusDeductWorkRegionTaxComment || '',

				dayWorkerPlusMinus4InsComment:
					selectedPaydocu?.dayWorkerInfo?.dayWorkerPlusMinus4InsComment || '',
				dayWorkerPlusDeductWorkerCompanyLoanComment:
					selectedPaydocu?.dayWorkerInfo
						?.dayWorkerPlusDeductWorkerCompanyLoanComment || '',
				bizIncome: +selectedPaydocu?.bizIncome || '',
				bizDeductionBeforeTax: +selectedPaydocu?.bizDeductionBeforeTax || '',
				bizDeductionBeforeTaxComment:
					selectedPaydocu?.bizDeductionBeforeTaxComment || '',
				bizDeductionAfterTax: +selectedPaydocu?.bizDeductionAfterTax || '',
				bizDeductionAfterTaxComment:
					selectedPaydocu?.bizDeductionAfterTaxComment || '',
			}));
		}
	}, [selectedPaydocu, match.params.id]);

	useEffect(() => {
		setValues((prevValues) => ({
			...prevValues,
			thisMonth: String(prevValues.thisMonth).padStart(2, '0'), // 항상 두 자리로 유지
		}));
	}, [values.thisMonth]);

	const [inputTax, setInputTax] = useState(true);
	const [initialTax, setInitialTax] = useState(false);
	const [monthTotalWorkTime, setMonthTotalWorkTime] = useState(false);
	const [monthTotalWorkTimeDays, setMonthTotalWorkTimeDays] = useState('');
	const [previousWorkDates, setPreviousWorkDates] = useState([]);
	const [weekBLTimeTW, setWeekBLTimeTW] = useState([]);
	const [weekBLTimeTWThisMonth, setWeekBLTimeTWThisMonth] = useState([]);
	const [filteredSelectedDays, setFilteredSelectedDays] = useState([]);

	const clientUid = clientInfo?.id ? clientInfo?.id : '';

	function sumValues(obj) {
		return Object.values(obj).reduce((a, b) => a + b, 0);
	}
	//월 소정근로시간 주휴시간용(전달 마지막주가 겹치는 경우 합치고 마지막주는 빼고)
	const paidWeekPossibleArr = calPaidHoliWageCount(
		values?.thisMonth,
		values?.thisYear
	);
	const preWeeklyWorkHours = calculateWeeklyHours(previousWorkDates);
	const weeklyWorkHours = calculateWeeklyHours(workDates);
	const workDatesWorkHourObj = combineObjects(
		preWeeklyWorkHours,
		weeklyWorkHours
	);
	const lawBaseWorkDatesWorkHourObj = capValuesAt40(workDatesWorkHourObj);
	const getWeekNumbersArr = getWeekNumbers(paidWeekPossibleArr);
	const lawBaseTimeOfWeekObj = getCommonWeeks(
		lawBaseWorkDatesWorkHourObj,
		getWeekNumbersArr
	);
	const finalPaidWeekObj = filterByValueRange(lawBaseTimeOfWeekObj, 15, 40);
	const finalPaidHoliWageTimeSum = sumValues(finalPaidWeekObj);
	const lawBasePaidWeekObj = filterByValueRange(weeklyWorkHours, 15, 40);
	//월 소정근로시간- 4대보험용
	const lawBaseWorkDatesWorkHourThisMonthOnlyObj =
		capValuesAt40(weeklyWorkHours);
	const lawBaseWorkDatesWorkHourThisMonthOnlySum = isTimeWorker
		? sumValues(lawBaseWorkDatesWorkHourThisMonthOnlyObj)
		: workDates.length * selectedClientReq4In?.dayWorker?.workingTimeDayBasic;

	const decryptoData = {};
	for (const prop in selectedClientReq4In) {
		decryptoData[prop] =
			prop === 'workerSocialNumberFront'
				? // || prop === "workerName"
				  decrypto(selectedClientReq4In[prop], String(process.env.CRYPTO_KEY))
				: selectedClientReq4In[prop];
	}

	useEffect(() => {
		dispatch(setWorkDatesPaydocu(values?.workDates));
		return () => {
			dispatch(clearPaydocu());
		};
	}, [values?.thisMonth, values?.thisYear, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

	const numOfWorkers = clientInfo?.numOfWorkers
		? clientInfo?.numOfWorkers
		: currentUserProfile?.numOfWorkers;

	//initialValues에 있으면 undefine되어 빼론거
	const worker = selectedClientReq4In?.worker?.name;
	const workerId = selectedClientReq4In?.id;

	const dayWorkerWage = selectedClientReq4In?.dayWorker?.daylyPay;
	const dayWokerTimeWage = selectedClientReq4In?.dayWorker?.realTimeWage;
	const dayWorkerDayTotalPaidConversedTime =
		selectedClientReq4In?.dayWorker?.dayTotalPaidConversedTime;
	const dayWorkerWorkingTimeDayTotal =
		selectedClientReq4In?.dayWorker?.workingTimeDayTotal;
	const dayWorkerWorkingTimeDayBasic =
		selectedClientReq4In?.dayWorker?.workingTimeDayBasic;

	const dayWorkerDayBasicWorkingTime =
		numOfWorkers >= 5
			? dayWorkerWorkingTimeDayBasic
			: dayWorkerWorkingTimeDayTotal;

	const dayWorkerPaidHolidayLBTime =
		selectedClientReq4In?.dayWorker?.dayWorkerPaidHolidayLBTime;
	const dayWorkerWorkingTimeDayOver =
		selectedClientReq4In?.dayWorker?.workingTimeDayOver;
	const dayWorkerWorkingTimeDayNight =
		selectedClientReq4In?.dayWorker?.workingTimeDayNight;
	const week14OverBLTime = selectedClientReq4In?.week14OverBLTime;

	const dayWorkerHoliWage =
		numOfWorkers >= 5
			? dayWokerTimeWage *
			  (dayWorkerWorkingTimeDayBasic * 1.5 +
					dayWorkerWorkingTimeDayOver * 2 +
					dayWorkerWorkingTimeDayNight)
			: dayWorkerWage;
	const dayWorkerHoliPaidTime =
		dayWorkerWorkingTimeDayBasic * 1.5 +
		dayWorkerWorkingTimeDayOver * 2 +
		dayWorkerWorkingTimeDayNight;

	const dayWorkerOverWorkDayWage =
		numOfWorkers >= 5
			? dayWokerTimeWage *
			  (dayWorkerWorkingTimeDayBasic * 1.5 +
					dayWorkerWorkingTimeDayOver * 1.5 +
					dayWorkerWorkingTimeDayNight)
			: dayWorkerWage;
	const dayWorkerOverWorkDayPaidTime =
		dayWorkerWorkingTimeDayBasic * 1.5 +
		dayWorkerWorkingTimeDayOver * 1.5 +
		dayWorkerWorkingTimeDayNight;

	const timeWorkerTimeWage =
		week14OverBLTime === '주휴수당 포함' ? daylyTimePay / 1.2 : daylyTimePay;
	const timeWorkerTimePaidHoliWage =
		week14OverBLTime === '주휴수당 포함'
			? daylyTimePay - timeWorkerTimeWage
			: 0;

	const dayWorkerTaxText = selectedClientReq4In?.daylyPay?.dayWorkerTax;

	//기초관리
	let today = new Date();

	const centerStyle = {
		width: `${100 / 9}%`,
		wordWrap: 'break-word',
		whiteSpace: 'normal',
		textAlign: 'center',
	};
	const inputWageStyle = {
		border: 'none',
		borderRight: '0px',
		borderTop: '0px',
		borderLeft: '0px',
		borderBottom: '0px',
		textAlign: 'right',
		width: '7rem',
	};
	const inputCommentStyle = {
		resize: 'none',
		height: '20px',
		border: 'none',
		borderRight: '0px',
		borderTop: '0px',
		borderLeft: '0px',
		borderBottom: '0px',
		textAlign: 'left',
		width: '50rem',
	};
	const inputTimeStyle = {
		border: 'none',
		borderRight: '0px',
		borderTop: '0px',
		borderLeft: '0px',
		borderBottom: '0px',
		textAlign: 'center',
		width: '5rem',
	};
	const inputBizIncomeStyle = {
		border: 'none',
		borderRight: '0px',
		borderTop: '0px',
		borderLeft: '0px',
		borderBottom: '0px',
		textAlign: 'center',
		width: '10rem',
	};

	const constructOrNot = selectedClientReq4In?.daylyPay?.constructOrNot;
	const dayWorkerTax = selectedClientReq4In?.daylyPay?.dayWorkerTax;

	//노무사는 노무사가 입력한 회사로 회사이름 설정 가능하고, 유저는 본인 회원가입한 상호로만 회사이름 설정됨.
	const isWorker = currentUserProfile?.isWorker;

	const companyName = isWorker
		? currentUserProfile?.workerInputCompanyName
			? currentUserProfile?.workerInputCompanyName
			: selectedClientReq4In?.companyName
		: selectedClientReq4In?.companyName;

	const gumakStyle = { textAlign: 'right' };

	const countOfNomalDayWork = values?.countOfNomalDayWork
		? +values?.countOfNomalDayWork
		: 0;
	const countOfHoliDayWork = values?.countOfHoliDayWork
		? +values?.countOfHoliDayWork
		: 0;
	const countOfOverWorkingDayWork = values?.countOfOverWorkingDayWork
		? +values?.countOfOverWorkingDayWork
		: 0;
	const sumOfCountWorkDays =
		countOfNomalDayWork + countOfHoliDayWork + countOfOverWorkingDayWork;
	// 1일 일용직 소정근로시간(주휴수당근거수당)
	const workingTimeDayBasic =
		selectedClientReq4In?.dayWorker?.workingTimeDayBasic;
	const workingTimeMonthBasic = sumOfCountWorkDays * workingTimeDayBasic;
	const healthInsureOver60DayWorker =
		(workingTimeMonthBasic || lawBaseWorkDatesWorkHourThisMonthOnlySum) >= 60;
	const maternityFinalDedectWage = ['', '', '', '', false, '', '', ''];

	async function handleFormSubmit(e) {
		let existingPayDocu = selectedPaydocu;
		let existingPayDocuId = existingPayDocu?.id;

		// selectedPaydocuFirst가 없을 경우 데이터베이스에서 동일한 yearMonth와 workerId를 가진 문서를 검색
		if (!existingPayDocu) {
			const targetYearMonth = values?.thisYear + values?.thisMonth;
			const q = query(
				collection(db, 'payDocu'),
				where('info.yearMonth', '==', targetYearMonth),
				where('workerInfo.workerId', '==', selectedClientReq4In?.id)
			);

			const querySnapshot = await getDocs(q);

			if (!querySnapshot.empty) {
				// 동일한 문서가 있으면 existingPayDocu에 할당
				existingPayDocu = querySnapshot.docs[0];
				existingPayDocuId = existingPayDocu.id;
			}
		}
		try {
			// 선택된 급여 문서가 있고, 그 문서의 연월과 workerId가 현재 입력값과 동일한지 확인
			if (
				existingPayDocu ||
				(selectedPaydocu?.info?.yearMonth ===
					values?.thisYear + values?.thisMonth &&
					selectedClientReq4In?.id === selectedPaydocu?.workerInfo?.workerId)
			) {
				axios
					.post(`${API_BASE_URL}/update-worker-wage`, {
						hostUid,
						values,
						workDates,
						existingPayDocuId,
						initialTax,
						maternityFinalDedectWage,
						monthTotalWorkTime,
						finalPaidWeekObj,
						finalPaidHoliWageTimeSum,
						lawBasePaidWeekObj,
						lawBaseWorkDatesWorkHourThisMonthOnlySum,
						filteredSelectedDays,
						weekBLTimeTWThisMonth,
						clientUid,
					})
					.then(async (response) => {
						// 서버에서 문서 생성 후 Firestore에서 다시 데이터를 불러와 상태를 업데이트
						const targetYearMonth = values?.thisYear + values?.thisMonth;
						const q = query(
							collection(db, 'payDocu'),
							where('info.yearMonth', '==', targetYearMonth),
							where('workerInfo.workerId', '==', selectedClientReq4In?.id)
						);

						const querySnapshot = await getDocs(q);

						if (!querySnapshot.empty) {
							existingPayDocu = querySnapshot.docs[0];
						}
						// 문서 생성 후 바로 페이지 이동
						history.push('/paydocus');
						return existingPayDocu;
					})
					.catch((error) => {
						// 에러 처리
						console.error('Error:', error);
						toast.error(error.message);
					});
			} else {
				// 새로운 문서 추가
				await axios
					.post(`${API_BASE_URL}/add-worker-wage`, {
						hostUid,
						values,
						workDates,
						initialTax,
						maternityFinalDedectWage,
						monthTotalWorkTime,
						finalPaidWeekObj,
						finalPaidHoliWageTimeSum,
						lawBasePaidWeekObj,
						lawBaseWorkDatesWorkHourThisMonthOnlySum,
						filteredSelectedDays,
						weekBLTimeTWThisMonth,
						clientUid,
					})
					.then(async () => {
						// 서버에서 문서 생성 후 Firestore에서 다시 데이터를 불러와 상태를 업데이트
						const targetYearMonth = values?.thisYear + values?.thisMonth;
						const q = query(
							collection(db, 'payDocu'),
							where('info.yearMonth', '==', targetYearMonth),
							where('workerInfo.workerId', '==', selectedClientReq4In?.id)
						);
						const querySnapshot = await getDocs(q);

						if (!querySnapshot.empty) {
							// 문서가 존재하는 경우 selectedPaydocu 업데이트
							selectedPaydocu = querySnapshot.docs[0];
						}

						// 문서 생성 후 페이지 이동
						history.push('/paydocus');
					})
					.catch((error) => {
						// 에러 처리
						console.error('Error:', error);
						toast.error(error.message);
					});
			}
		} catch (error) {
			console.log(error.message);
		}
	}

	function selfDeductButtonClickHandler() {
		setInputTax(false);
		setInitialTax(false);
		alert('공제하지 않는 항목은 반드시 "0"을 입력하세요.');
	}

	function initialTaxButtonClickHandler() {
		setInputTax(true);
		setInitialTax(true);
		alert('수기입력된 세금을 법정으로 계산합니다.');
	}

	// const numOfWorkDaysMisMacth = Math.ceil(sumOfCountWorkDays) < workDates.length;
	const numOfWorkDaysMatch = daylyTimePay
		? true
		: Math.ceil(sumOfCountWorkDays) === workDates.length;

	const confirmedBasicTime =
		numOfWorkers >= 5
			? dayWorkerWorkingTimeDayBasic
			: dayWorkerWorkingTimeDayTotal;

	if (loading || (!selectedClientReq4In && !error))
		return <LoadingComponent content='Loading 임금명세서 ...' />;
	if (error) return <Redirect to='/error' />;
	return (
		<div>
			<PaydocuShortCut filter={filter} clientReqs4In={clientReqs4In} />
			{isBizWorker ? (
				<Grid>
					<Grid.Column width={16}>
						{/* {sumOfCountWorkDays !== 0 ? numOfWorkDaysMatch ? toast("선택한 근무일수와 계산된 근무일수가 일치하여 저장가능합니다.") : null : null}
              {numOfWorkDaysMisMacth ? alert("선택한 근무일수가 계산된 근무일수보다 많습니다. 다시 입력하세요.") : null} */}
						<ClientReqDetailedHeader4In
							selectedClientReq4In={selectedClientReq4In}
							companyName={companyName}
							isDayOrTimeWorker={isDayOrTimeWorker}
							clientReqs4In={clientReqs4In}
							daylyTimePay={daylyTimePay}
							isBizWorker={isBizWorker}
						/>
						{isBizWorker ? null : (
							<>
								<div>
									{' '}
									* 본 임금명세서는 2021년 11월 19일 이후 시행되는 근로기준법
									제48조(임금명세서) 제2항 및 동법 시행령 제27조의
									2(임금명세서의 기재사항)에 따른{' '}
									<span style={{ fontSize: '1.1rem', color: 'blue' }}>
										기재사항을 안내한 명세서
									</span>
									입니다.
								</div>
								<div>
									{' '}
									*{' '}
									<span style={{ fontSize: '1.1rem', color: 'blue' }}>
										중도입퇴사 일할계산
									</span>
									과 세전 임금 및 세금, 4대보험 금액, 세후 임금은 모두
									자동계산됩니다.
								</div>
								<div>
									{' '}
									* 추가 연장/야간/휴일 시간, 지각, 조퇴, 외출 시간 등이 있는
									경우{' '}
									<span style={{ fontSize: '1.1rem', color: 'blue' }}>
										일수와 시간만 입력하시면 일할계산 등은 자동계산
									</span>
									됩니다.
								</div>
								<br />
							</>
						)}
						<ClientReqDLCJudgeWageTable
							daylyTimePay={daylyTimePay}
							selectedClientReq4In={selectedClientReq4In}
							values={values}
							handleFormSubmit={handleFormSubmit}
							isDayOrTimeWorker={isDayOrTimeWorker}
							workDatesPaydocu={workDates}
							sumOfCountWorkDays={sumOfCountWorkDays}
							workDates={workDates}
							numOfWorkDaysMatch={numOfWorkDaysMatch}
							isBizWorker={isBizWorker}
						/>
						{isBizWorker ? null : (
							<Button.Group floated='right'>
								<Button
									type='button'
									onClick={() => initialTaxButtonClickHandler()}
									onMouseOver={() =>
										toast(
											'수기입력된 세금, 4대보험을 법정기준으로 다시 계산합니다.'
										)
									}
									color='teal'>
									공제자동계산
								</Button>
								<Button
									type='button'
									onClick={() => selfDeductButtonClickHandler()}
									color='red'>
									공제수기입력
								</Button>
							</Button.Group>
						)}
						<br />
						<div id='wageTable'>
							<form onSubmit={handleFormSubmit} id='myForm'>
								<Table border='1' width='100%' align='center' id='table-to-xls'>
									<ClientReqDLCHeadWageTable
										companyName={companyName}
										inputWageStyle={inputWageStyle}
										handelInputChange={handelInputChange}
										thisMonth={values?.thisMonth}
										thisYear={values?.thisYear}
										worker={worker}
										selectedPaydocu={selectedPaydocu}
										isBizWorker={isBizWorker}
									/>
									<Table.Body>
										{isBizWorker ? (
											<Table.Row>
												<Table.Cell colSpan={'9'}>
													<span
														style={{
															fontSize: '1.2rem',
															color: 'darkcyan',
															fontWeight: 'bold',
														}}>{`1. 사업소득직 ${worker}의 보수작업`}</span>
												</Table.Cell>
											</Table.Row>
										) : (
											<Table.Row>
												<Table.Cell colSpan={'9'}>
													<span
														style={{
															fontSize: '1.2rem',
															color: 'darkcyan',
															fontWeight: 'bold',
														}}>{`1. ${worker} 일당의 구성항목`}</span>
												</Table.Cell>
											</Table.Row>
										)}
										<ClientRBizDayTimeWorkerMonthWageTableBasicInfo
											centerStyle={centerStyle}
											worker={worker}
											decryptoData={decryptoData}
											numberToCommaString={numberToCommaString}
											roundUp={roundUp}
											dayWorkerWage={dayWorkerWage}
											dayWokerTimeWage={dayWokerTimeWage}
											dayWorkerWorkingTimeDayTotal={
												dayWorkerWorkingTimeDayTotal
											}
											dayWorkerWorkingTimeDayBasic={
												dayWorkerWorkingTimeDayBasic
											}
											roundUp2={roundUp2}
											dayWorkerDayTotalPaidConversedTime={
												dayWorkerDayTotalPaidConversedTime
											}
											numOfWorkers={numOfWorkers}
											dayWorkerPaidHolidayLBTime={dayWorkerPaidHolidayLBTime}
											dayWorkerWorkingTimeDayOver={dayWorkerWorkingTimeDayOver}
											dayWorkerWorkingTimeDayNight={
												dayWorkerWorkingTimeDayNight
											}
											daylyTimePay={daylyTimePay}
											timeWorkerTimeWage={timeWorkerTimeWage}
											timeWorkerTimePaidHoliWage={timeWorkerTimePaidHoliWage}
											isBizWorker={isBizWorker}
											inputBizIncomeStyle={inputBizIncomeStyle}
											handelInputChange={handelInputChange}
											bizIncomeValue={bizIncomeValue}
											setBizIncomeValue={setBizIncomeValue}
											bizDeductionAfterTaxValue={bizDeductionAfterTaxValue}
											setBizDeductionAfterTaxValue={
												setBizDeductionAfterTaxValue
											}
											bizDeductionBeforeTaxValue={bizDeductionBeforeTaxValue}
											bizDeductionAfterTaxCommentValue={
												bizDeductionAfterTaxCommentValue
											}
											setBizDeductionAfterTaxCommentValue={
												setBizDeductionAfterTaxCommentValue
											}
											bizDeductionBeforeTaxCommentValue={
												bizDeductionBeforeTaxCommentValue
											}
											values={values}
										/>
										{isBizWorker ? null : (
											<>
												<>
													{daylyTimePay > 0 ? null : (
														<>
															<Table.Row>
																<Table.Cell colSpan='9'>
																	<span
																		style={{
																			fontSize: '1.2rem',
																			color: 'darkcyan',
																			fontWeight: 'bold',
																		}}>{`2. ${worker}의 근무일 `}</span>
																	<span
																		style={{
																			fontSize: '0.9rem',
																			color: 'red',
																		}}>
																		{
																			'(주의 : 달력 선택 전에 근무일수를 먼저 선택하세요)'
																		}
																	</span>
																</Table.Cell>
															</Table.Row>
															<Table.Row>
																<Table.Cell colSpan={2} style={centerStyle}>
																	평일 근무일수
																</Table.Cell>
																<Table.Cell colSpan={2} style={centerStyle}>
																	휴일 근무일수
																</Table.Cell>
																<Table.Cell colSpan={2} style={centerStyle}>
																	휴무일 근무일수
																</Table.Cell>
																<Table.Cell colSpan={3} style={centerStyle}>
																	총 근무일수
																</Table.Cell>
															</Table.Row>
															<Table.Row>
																<Table.Cell colSpan={2} style={centerStyle}>
																	<input
																		style={inputTimeStyle}
																		type='number'
																		min='0'
																		max={29}
																		name='countOfNomalDayWork'
																		value={countOfNomalDayWork}
																		onChange={(e) => handelInputChange(e)}
																		autoComplete='off'
																	/>
																</Table.Cell>
																<Table.Cell colSpan={2} style={centerStyle}>
																	<input
																		style={inputTimeStyle}
																		type='number'
																		min='0'
																		max={15}
																		name='countOfHoliDayWork'
																		value={countOfHoliDayWork}
																		onChange={(e) => handelInputChange(e)}
																		autoComplete='off'
																	/>
																</Table.Cell>
																<Table.Cell colSpan={2} style={centerStyle}>
																	<input
																		style={inputTimeStyle}
																		type='number'
																		min='0'
																		max={15}
																		name='countOfOverWorkingDayWork'
																		value={countOfOverWorkingDayWork}
																		onChange={(e) => handelInputChange(e)}
																		autoComplete='off'
																	/>
																</Table.Cell>
																<Table.Cell colSpan={4} style={centerStyle}>
																	{sumOfCountWorkDays}
																</Table.Cell>
															</Table.Row>
														</>
													)}
													<Table.Row>
														<Table.Cell colSpan={9}>
															{daylyTimePay ? (
																<span
																	style={{
																		fontSize: '1.2rem',
																		color: 'darkcyan',
																		fontWeight: 'bold',
																	}}>{`2. ${worker}의 근무일별 근무시간 `}</span>
															) : (
																'근무일 선택'
															)}
														</Table.Cell>
													</Table.Row>
													{+values?.countOfHoliDayWork !== 0 &&
													values?.countOfOverWorkingDayWork !== undefined ? (
														<>
															<Table.Row>
																<Table.Cell colSpan={'9'}>
																	* 휴일 일당 구성항목
																</Table.Cell>
															</Table.Row>
															<Table.Row>
																<Table.Cell style={centerStyle}></Table.Cell>
																<Table.Cell style={centerStyle}>
																	휴일 일당
																</Table.Cell>
																<Table.Cell style={centerStyle}>
																	통상시급
																</Table.Cell>
																<Table.Cell style={centerStyle}>
																	1일 근로시간 <br /> (전체)
																</Table.Cell>
																<Table.Cell style={centerStyle}>
																	1일 유급시간 <br />
																	(전체){' '}
																</Table.Cell>
																<Table.Cell style={centerStyle}>
																	1일 기본급시간
																</Table.Cell>
																{numOfWorkers < 5 ? (
																	<Table.Cell style={centerStyle}>
																		1일 휴일보존 수당
																	</Table.Cell>
																) : null}
																<Table.Cell style={centerStyle}>
																	1일 연장시간
																</Table.Cell>
																<Table.Cell style={centerStyle}>
																	1일 야간시간
																</Table.Cell>
																{numOfWorkers >= 5 ? (
																	<Table.Cell style={centerStyle}></Table.Cell>
																) : null}
															</Table.Row>
															<Table.Row>
																<Table.Cell style={centerStyle}></Table.Cell>
																<Table.Cell style={centerStyle}>
																	{numberToCommaString(
																		roundUp(dayWorkerHoliWage)
																	)}
																</Table.Cell>
																<Table.Cell style={centerStyle}>
																	{numberToCommaString(
																		roundUp(dayWokerTimeWage)
																	)}
																</Table.Cell>
																<Table.Cell style={centerStyle}>
																	{roundUp2(dayWorkerWorkingTimeDayTotal)}
																</Table.Cell>
																<Table.Cell style={centerStyle}>
																	{roundUp2(
																		numOfWorkers >= 5
																			? dayWorkerHoliPaidTime
																			: dayWorkerDayTotalPaidConversedTime
																	)}
																</Table.Cell>
																<Table.Cell style={centerStyle}>
																	{roundUp2(
																		numOfWorkers >= 5
																			? dayWorkerWorkingTimeDayBasic
																			: dayWorkerWorkingTimeDayTotal
																	)}
																	<br /> 휴일기본환산(
																	{roundUp2(dayWorkerWorkingTimeDayBasic * 1.5)}
																	){' '}
																</Table.Cell>
																{numOfWorkers < 5 ? (
																	<Table.Cell style={centerStyle}>
																		{roundUp2(dayWorkerPaidHolidayLBTime)}
																	</Table.Cell>
																) : null}
																<Table.Cell style={centerStyle}>
																	{roundUp2(dayWorkerWorkingTimeDayOver)}
																	<br /> 휴일연장환산(
																	{roundUp2(dayWorkerWorkingTimeDayOver * 2)})
																</Table.Cell>
																<Table.Cell style={centerStyle}>
																	{roundUp2(dayWorkerWorkingTimeDayNight)}
																	<br /> 야간환산 (
																	{roundUp2(dayWorkerWorkingTimeDayNight * 0.5)}
																	)
																</Table.Cell>
																{numOfWorkers >= 5 ? (
																	<Table.Cell style={centerStyle}></Table.Cell>
																) : null}
															</Table.Row>
														</>
													) : null}
													{+values?.countOfOverWorkingDayWork !== 0 &&
													values?.countOfOverWorkingDayWork !== undefined ? (
														<>
															<Table.Row>
																<Table.Cell colSpan={'9'}>
																	* 휴무일 일당 구성항목
																</Table.Cell>
															</Table.Row>
															<Table.Row>
																<Table.Cell style={centerStyle}></Table.Cell>
																<Table.Cell style={centerStyle}>
																	휴무일 일당
																</Table.Cell>
																<Table.Cell style={centerStyle}>
																	통상시급
																</Table.Cell>
																<Table.Cell style={centerStyle}>
																	1일 근로시간 <br /> (전체)
																</Table.Cell>
																<Table.Cell style={centerStyle}>
																	1일 유급시간 <br />
																	(전체){' '}
																</Table.Cell>
																<Table.Cell style={centerStyle}>
																	1일 기본급시간
																</Table.Cell>
																{numOfWorkers < 5 ? (
																	<Table.Cell style={centerStyle}>
																		1일 휴무일보존 수당
																	</Table.Cell>
																) : null}
																<Table.Cell style={centerStyle}>
																	1일 연장시간
																</Table.Cell>
																<Table.Cell style={centerStyle}>
																	1일 야간시간
																</Table.Cell>
																{numOfWorkers >= 5 ? (
																	<Table.Cell style={centerStyle}></Table.Cell>
																) : null}
															</Table.Row>
															<Table.Row>
																<Table.Cell style={centerStyle}></Table.Cell>
																<Table.Cell style={centerStyle}>
																	{numberToCommaString(
																		roundUp(dayWorkerOverWorkDayWage)
																	)}
																</Table.Cell>
																<Table.Cell style={centerStyle}>
																	{numberToCommaString(
																		roundUp(dayWokerTimeWage)
																	)}
																</Table.Cell>
																<Table.Cell style={centerStyle}>
																	{roundUp2(dayWorkerWorkingTimeDayTotal)}
																</Table.Cell>
																<Table.Cell style={centerStyle}>
																	{roundUp2(
																		numOfWorkers >= 5
																			? dayWorkerOverWorkDayPaidTime
																			: dayWorkerDayTotalPaidConversedTime
																	)}
																</Table.Cell>
																<Table.Cell style={centerStyle}>
																	{roundUp2(
																		numOfWorkers >= 5
																			? dayWorkerWorkingTimeDayBasic
																			: dayWorkerWorkingTimeDayTotal
																	)}
																	<br /> 연장환산(
																	{roundUp2(dayWorkerWorkingTimeDayBasic * 1.5)}
																	){' '}
																</Table.Cell>
																{numOfWorkers < 5 ? (
																	<Table.Cell style={centerStyle}>
																		{roundUp2(dayWorkerPaidHolidayLBTime)}
																	</Table.Cell>
																) : null}
																<Table.Cell style={centerStyle}>
																	{roundUp2(dayWorkerWorkingTimeDayOver)}
																	<br /> 연장환산(
																	{roundUp2(dayWorkerWorkingTimeDayOver * 1.5)})
																</Table.Cell>
																<Table.Cell style={centerStyle}>
																	{roundUp2(dayWorkerWorkingTimeDayNight)}
																	<br /> 야간환산 (
																	{roundUp2(dayWorkerWorkingTimeDayNight * 0.5)}
																	)
																</Table.Cell>
																{numOfWorkers >= 5 ? (
																	<Table.Cell style={centerStyle}></Table.Cell>
																) : null}
															</Table.Row>
														</>
													) : null}
													{numOfWorkers < 5 ? (
														<Table.Row>
															<Table.Cell colSpan='9'>
																* 5인미만 사업장은 근로기준법에 따라
																소정근로시간이 없으므로 연장근로가 아닌
																초과근로로 표시되고, 초과된 근로에 대한 1배만
																지급되며, 연장근로, 야간근로, 휴일근로
																가산수당이 적용되지 않습니다.
															</Table.Cell>
														</Table.Row>
													) : null}
													<ClientRDaylyWorkerMonthWageTableAddWork
														centerStyle={centerStyle}
														worker={worker}
														numOfWorkers={numOfWorkers}
														daylyTimePay={daylyTimePay}
														ClientReqDLCDayWorkerWageTableTimeDetail={
															ClientReqDLCDayWorkerWageTableTimeDetail
														}
														handelInputChange={handelInputChange}
														inputTimeStyle={inputTimeStyle}
														values={values}
														timeWorkerPaidHoli={values?.timeWorkerPaidHoli}
														timeWorkerPlusMonthOWT={
															values?.timeWorkerPlusMonthOWT
														}
														timeWorkerPlusMonthNWT={
															values?.timeWorkerPlusMonthNWT
														}
														timeWorkerPlusMonthHoliBasicTime={
															values?.timeWorkerPlusMonthHoliBasicTime
														}
														timeWorkerPlusMonthOWTHoli={
															values?.timeWorkerPlusMonthOWTHoli
														}
														timeWorkerPlusMonthOffBasicTime={
															values?.timeWorkerPlusMonthOffBasicTime
														}
														timeWorkerPlusMonthOWTOff={
															values?.timeWorkerPlusMonthOWTOff
														}
														timeWorkerPlusHoliBasicTimeDates={
															values?.timeWorkerPlusHoliBasicTimeDates
														}
														timeWorkerPlusHoliBasicTime={
															values?.timeWorkerPlusHoliBasicTime
														}
														timeWorkerPlusMonthOWTHoliDates={
															values?.timeWorkerPlusMonthOWTHoliDates
														}
														timeWorkerPlusMonthOWTOffDates={
															values?.timeWorkerPlusMonthOWTOffDates
														}
														timeWorkerPlusOverTimeDates={
															values?.timeWorkerPlusOverTimeDates
														}
														timeWorkerPlusNightTimeDates={
															values?.timeWorkerPlusNightTimeDates
														}
														timeWorkerPlusHoliOverTimeDates={
															values?.timeWorkerPlusHoliOverTimeDates
														}
														timeWorkerPlusOffBasicTimeDates={
															values?.timeWorkerPlusOffBasicTimeDates
														}
														timeWorkerPlusOffOverTimeDates={
															values?.timeWorkerPlusOffOverTimeDates
														}
														dayWorkerPlusMinus4Ins={
															values?.dayWorkerPlusMinus4Ins
														}
														dayWorkerPlusMinus4InsComment={
															values?.dayWorkerPlusMinus4InsComment
														}
													/>
												</>
												<Table.Row>
													<Table.Cell colSpan='9'>{` `}</Table.Cell>
												</Table.Row>
												<ClientReqDLCWageTableDayWorkerPayDetail
													dayWorkerTaxText={dayWorkerTaxText}
													dayWorkerDayBasicWorkingTime={
														dayWorkerDayBasicWorkingTime
													}
													isDayOrTimeWorker={isDayOrTimeWorker}
													filteredSelectedDays={filteredSelectedDays}
													weekBLTimeTW={weekBLTimeTW}
													setWeekBLTimeTW={setWeekBLTimeTW}
													weekBLTimeTWThisMonth={weekBLTimeTWThisMonth}
													setWeekBLTimeTWThisMonth={setWeekBLTimeTWThisMonth}
													normalWorkerWeekWorkDays={normalWorkerWeekWorkDays}
													finalPaidWeekObj={finalPaidWeekObj}
													lawBasePaidWeekObj={lawBasePaidWeekObj}
													finalPaidHoliWageTimeSum={finalPaidHoliWageTimeSum}
													lawBaseWorkDatesWorkHourThisMonthOnlySum={
														lawBaseWorkDatesWorkHourThisMonthOnlySum
													}
													week14OverBLTime={week14OverBLTime}
													monthTotalWorkTime={monthTotalWorkTime}
													monthTotalWorkTimeDays={monthTotalWorkTimeDays}
													values={values}
													initialTax={initialTax}
													dayWorkerTax={dayWorkerTax}
													dayWokerTimeWage={roundUp2(dayWokerTimeWage)}
													healthInsureOver60DayWorker={
														healthInsureOver60DayWorker
													}
													constructOrNot={constructOrNot}
													dayWorkerWage={dayWorkerWage}
													dayWorkerHoliWage={dayWorkerHoliWage}
													dayWorkerOverWorkDayWage={dayWorkerOverWorkDayWage}
													countOfNomalDayWork={countOfNomalDayWork}
													countOfOverWorkingDayWork={countOfOverWorkingDayWork}
													countOfHoliDayWork={countOfHoliDayWork}
													thisYear={values?.thisYear}
													thisMonth={values?.thisMonth}
													handelInputChange={handelInputChange}
													selectedClientReq4In={selectedClientReq4In}
													numOfWorkers={numOfWorkers}
													inputWageStyle={inputWageStyle}
													centerStyle={centerStyle}
													gumakStyle={gumakStyle}
													inputCommentStyle={inputCommentStyle}
													dayWorkerPlusMonthOWT={values?.dayWorkerPlusMonthOWT}
													dayWorkerPlusMonthNWT={values?.dayWorkerPlusMonthNWT}
													dayWorkerPlusMonthHoliBasicTime={
														values?.dayWorkerPlusMonthHoliBasicTime
													}
													dayWorkerPlusMonthOWTHoli={
														values?.dayWorkerPlusMonthOWTHoli
													}
													dayWorkerPlusMonthNWTHoli={
														values?.dayWorkerPlusMonthNWTHoli
													}
													timeWorkerPlusMonthOWT={
														values?.timeWorkerPlusMonthOWT
													}
													timeWorkerPlusMonthNWT={
														values?.timeWorkerPlusMonthNWT
													}
													timeWorkerPlusMonthHoliBasicTime={
														values?.timeWorkerPlusMonthHoliBasicTime
													}
													timeWorkerPlusMonthOWTHoli={
														values?.timeWorkerPlusMonthOWTHoli
													}
													timeWorkerPlusMonthOffBasicTime={
														values?.timeWorkerPlusMonthOffBasicTime
													}
													timeWorkerPlusMonthOWTOff={
														values?.timeWorkerPlusMonthOWTOff
													}
													timeWorkerPaidHoli={values?.timeWorkerPaidHoli}
													workingDays={values?.workingDays}
													dayWorkerCAWage5={values?.dayWorkerCAWage5}
													dayWorkerCAWEName5={values?.dayWorkerCAWEName5}
													dayWorkerCAWage5Comment={
														values?.dayWorkerCAWage5Comment
													}
													dayWorkerCAWage6={values?.dayWorkerCAWage6}
													dayWorkerCAWEName6={values?.dayWorkerCAWEName6}
													dayWorkerCAWage6Comment={
														values?.dayWorkerCAWage6Comment
													}
													dayWorkerCAWage7={values?.dayWorkerCAWage7}
													dayWorkerCAWEName7={values?.dayWorkerCAWEName7}
													dayWorkerCAWage7Comment={
														values?.dayWorkerCAWage7Comment
													}
													dayWorkerDeductEtcName1={
														values?.dayWorkerDeductEtcName1
													}
													dayWorkerDeductEtcWage1={
														values?.dayWorkerDeductEtcWage1
													}
													dayWorkerDeductEtcWage1Comment={
														values?.dayWorkerDeductEtcWage1Comment
													}
													dayWorkerDeductEtcName2={
														values?.dayWorkerDeductEtcName2
													}
													dayWorkerDeductEtcWage2={
														values?.dayWorkerDeductEtcWage2
													}
													dayWorkerDeductEtcWage2Comment={
														values?.dayWorkerDeductEtcWage2Comment
													}
													dayWorkerDeductEtcName3={
														values?.dayWorkerDeductEtcName3
													}
													dayWorkerDeductEtcWage3={
														values?.dayWorkerDeductEtcWage3
													}
													dayWorkerDeductEtcWage3Comment={
														values?.dayWorkerDeductEtcWage3Comment
													}
													dayWorkerUserFixedTax={values?.dayWorkerUserFixedTax}
													dayWorkerUserFixedRegionTax={
														values?.dayWorkerUserFixedRegionTax
													}
													dayWorkerUserFixedHealthCare={
														values?.dayWorkerUserFixedHealthCare
													}
													dayWorkerUserFixedOldCare={
														values?.dayWorkerUserFixedOldCare
													}
													dayWorkerUserFixedPension={
														values?.dayWorkerUserFixedPension
													}
													dayWorkerUserFixedNoJobInsurance={
														values?.dayWorkerUserFixedNoJobInsurance
													}
													dayWorkerAbsentTimes={values?.dayWorkerAbsentTimes}
													inputTax={inputTax}
													dayWorkerPlusDeductHealthCare={
														values?.dayWorkerPlusDeductHealthCare
													}
													dayWorkerPlusDeductOldCare={
														values?.dayWorkerPlusDeductOldCare
													}
													dayWorkerPlusDeductWorkTax={
														values?.dayWorkerPlusDeductWorkTax
													}
													dayWorkerPlusDeductWorkRegionTax={
														values?.dayWorkerPlusDeductWorkRegionTax
													}
													dayWorkerPlusDeductDuru={
														values?.dayWorkerPlusDeductDuru
													}
													dayWorkerPlusMinus4Ins={
														values?.dayWorkerPlusMinus4Ins
													}
													dayWorkerPlusDeductWorkerCompanyLoan={
														values?.dayWorkerPlusDeductWorkerCompanyLoan
													}
													dayWorkerPlusDeductHealthCareComment={
														values?.dayWorkerPlusDeductHealthCareComment
													}
													dayWorkerPlusDeductOldCareComment={
														values?.dayWorkerPlusDeductOldCareComment
													}
													dayWorkerPlusDeductWorkTaxComment={
														values?.dayWorkerPlusDeductWorkTaxComment
													}
													dayWorkerPlusDeductWorkRegionTaxComment={
														values?.dayWorkerPlusDeductWorkRegionTaxComment
													}
													dayWorkerPlusMinus4InsComment={
														values?.dayWorkerPlusMinus4InsComment
													}
													dayWorkerPlusDeductWorkerCompanyLoanComment={
														values?.dayWorkerPlusDeductWorkerCompanyLoanComment
													}
													daylyTimePay={daylyTimePay}
													timeWorkerTimeWage={timeWorkerTimeWage}
													timeWorkerTimePaidHoliWage={
														timeWorkerTimePaidHoliWage
													}
													dayWorkerSpecialBonus={values?.dayWorkerSpecialBonus}
													timeWorkerSpecialBonus={
														values?.timeWorkerSpecialBonus
													}
													netAgeEntered={netAgeEntered}
												/>
												<Table.Row width='100%' align='center'>
													<Table.Cell colSpan='9' align='center'>
														{format(today, 'yyyy-MM-dd')}
													</Table.Cell>
												</Table.Row>
												<Table.Row>
													<Table.Cell
														colSpan='9'
														align='center'>{`임금명세서를 교부받았음을 확인합니다. : ${worker} (서명 또는 인)`}</Table.Cell>
												</Table.Row>
												<Table.Row>
													<Table.Cell
														colSpan='9'
														align='left'>{`근로기준법 제48조 제2항 및 2021.11.19.시행 임금명세서 교부 의무 설명자료(2021.11 고용노동부)에 따라 본 명세서는 ${worker}에게 교부된 것으로 본다.`}</Table.Cell>
												</Table.Row>
											</>
										)}
									</Table.Body>
								</Table>
							</form>
						</div>
						<br />
						<hr />
						<hr />
					</Grid.Column>
				</Grid>
			) : (
				<Grid>
					<Grid.Column width={16}>
						{/* {sumOfCountWorkDays !== 0 ? numOfWorkDaysMatch ? toast("선택한 근무일수와 계산된 근무일수가 일치하여 저장가능합니다.") : null : null}
              {numOfWorkDaysMisMacth ? alert("선택한 근무일수가 계산된 근무일수보다 많습니다. 다시 입력하세요.") : null} */}
						<ClientReqDetailedHeader4In
							selectedClientReq4In={selectedClientReq4In}
							companyName={companyName}
							isDayOrTimeWorker={isDayOrTimeWorker}
							clientReqs4In={clientReqs4In}
							daylyTimePay={daylyTimePay}
						/>
						<div>
							{' '}
							* 본 임금명세서는 2021년 11월 19일 이후 시행되는 근로기준법
							제48조(임금명세서) 제2항 및 동법 시행령 제27조의 2(임금명세서의
							기재사항)에 따른{' '}
							<span style={{ fontSize: '1.1rem', color: 'blue' }}>
								기재사항을 안내한 명세서
							</span>
							입니다.
						</div>
						<div>
							{' '}
							*{' '}
							<span style={{ fontSize: '1.1rem', color: 'blue' }}>
								중도입퇴사 일할계산
							</span>
							과 세전 임금 및 세금, 4대보험 금액, 세후 임금은 모두
							자동계산됩니다.
						</div>
						<div>
							{' '}
							* 추가 연장/야간/휴일 시간, 지각, 조퇴, 외출 시간 등이 있는 경우{' '}
							<span style={{ fontSize: '1.1rem', color: 'blue' }}>
								일수와 시간만 입력하시면 일할계산 등은 자동계산
							</span>
							됩니다.
						</div>
						<br />
						<ClientReqDLCJudgeWageTable
							daylyTimePay={daylyTimePay}
							selectedClientReq4In={selectedClientReq4In}
							values={values}
							handleFormSubmit={handleFormSubmit}
							isDayOrTimeWorker={isDayOrTimeWorker}
							workDatesPaydocu={workDates}
							sumOfCountWorkDays={sumOfCountWorkDays}
							workDates={workDates}
							numOfWorkDaysMatch={numOfWorkDaysMatch}
						/>
						<Button.Group floated='right'>
							<Button
								type='button'
								onClick={() => initialTaxButtonClickHandler()}
								onMouseOver={() =>
									toast(
										'수기입력된 세금, 4대보험을 법정기준으로 다시 계산합니다.'
									)
								}
								color='teal'>
								공제자동계산
							</Button>
							<Button
								type='button'
								onClick={() => selfDeductButtonClickHandler()}
								color='red'>
								공제수기입력
							</Button>
						</Button.Group>
						<br />
						<div id='wageTable'>
							<form onSubmit={handleFormSubmit} id='myForm'>
								<Table border='1' width='100%' align='center' id='table-to-xls'>
									<ClientReqDLCHeadWageTable
										companyName={companyName}
										inputWageStyle={inputWageStyle}
										handelInputChange={handelInputChange}
										thisMonth={values?.thisMonth}
										thisYear={values?.thisYear}
										worker={worker}
										selectedPaydocu={selectedPaydocu}
										isDayOrTimeWorker={isDayOrTimeWorker}
									/>
									<Table.Body>
										<Table.Row>
											<Table.Cell colSpan={'9'}>
												<span
													style={{
														fontSize: '1.2rem',
														color: 'darkcyan',
														fontWeight: 'bold',
													}}>{`1. ${worker} 일당의 구성항목`}</span>
											</Table.Cell>
										</Table.Row>
										<ClientRBizDayTimeWorkerMonthWageTableBasicInfo
											centerStyle={centerStyle}
											worker={worker}
											decryptoData={decryptoData}
											numberToCommaString={numberToCommaString}
											roundUp={roundUp}
											dayWorkerWage={dayWorkerWage}
											dayWokerTimeWage={dayWokerTimeWage}
											dayWorkerWorkingTimeDayTotal={
												dayWorkerWorkingTimeDayTotal
											}
											dayWorkerWorkingTimeDayBasic={
												dayWorkerWorkingTimeDayBasic
											}
											roundUp2={roundUp2}
											dayWorkerDayTotalPaidConversedTime={
												dayWorkerDayTotalPaidConversedTime
											}
											numOfWorkers={numOfWorkers}
											dayWorkerPaidHolidayLBTime={dayWorkerPaidHolidayLBTime}
											dayWorkerWorkingTimeDayOver={dayWorkerWorkingTimeDayOver}
											dayWorkerWorkingTimeDayNight={
												dayWorkerWorkingTimeDayNight
											}
											daylyTimePay={daylyTimePay}
											timeWorkerTimeWage={timeWorkerTimeWage}
											timeWorkerTimePaidHoliWage={timeWorkerTimePaidHoliWage}
										/>
										{daylyTimePay > 0 ? null : (
											<>
												<Table.Row>
													<Table.Cell colSpan='9'>
														<span
															style={{
																fontSize: '1.2rem',
																color: 'darkcyan',
																fontWeight: 'bold',
															}}>{`2. ${worker}의 근무일 `}</span>
														<span style={{ fontSize: '0.9rem', color: 'red' }}>
															{
																'(주의 : 달력 선택 전에 근무일수를 먼저 선택하세요)'
															}
														</span>
													</Table.Cell>
												</Table.Row>
												<Table.Row>
													<Table.Cell colSpan={2} style={centerStyle}>
														평일 근무일수
													</Table.Cell>
													<Table.Cell colSpan={2} style={centerStyle}>
														휴일 근무일수
													</Table.Cell>
													<Table.Cell colSpan={2} style={centerStyle}>
														휴무일 근무일수
													</Table.Cell>
													<Table.Cell colSpan={3} style={centerStyle}>
														총 근무일수
													</Table.Cell>
												</Table.Row>
												<Table.Row>
													<Table.Cell colSpan={2} style={centerStyle}>
														<input
															style={inputTimeStyle}
															type='number'
															min='0'
															max={29}
															name='countOfNomalDayWork'
															value={countOfNomalDayWork}
															onChange={(e) => handelInputChange(e)}
															autoComplete='off'
														/>
													</Table.Cell>
													<Table.Cell colSpan={2} style={centerStyle}>
														<input
															style={inputTimeStyle}
															type='number'
															min='0'
															max={15}
															name='countOfHoliDayWork'
															value={countOfHoliDayWork}
															onChange={(e) => handelInputChange(e)}
															autoComplete='off'
														/>
													</Table.Cell>
													<Table.Cell colSpan={2} style={centerStyle}>
														<input
															style={inputTimeStyle}
															type='number'
															min='0'
															max={15}
															name='countOfOverWorkingDayWork'
															value={countOfOverWorkingDayWork}
															onChange={(e) => handelInputChange(e)}
															autoComplete='off'
														/>
													</Table.Cell>
													<Table.Cell colSpan={4} style={centerStyle}>
														{sumOfCountWorkDays}
													</Table.Cell>
												</Table.Row>
											</>
										)}
										<Table.Row>
											<Table.Cell colSpan={9}>
												{daylyTimePay ? (
													<span
														style={{
															fontSize: '1.2rem',
															color: 'darkcyan',
															fontWeight: 'bold',
														}}>{`2. ${worker}의 근무일별 근무시간 `}</span>
												) : (
													'근무일 선택'
												)}
											</Table.Cell>
										</Table.Row>
										{daylyTimePay > 0 ? (
											<Table.Row>
												<Table.Cell colSpan={4}>
													<WeekNumberInputLastMonth
														targetMonth={values?.thisMonth}
														targetYear={values?.thisYear}
														paydocus={paydocus}
														workerId={workerId}
														setPreviousWorkDates={setPreviousWorkDates}
													/>
												</Table.Cell>
												<Table.Cell colSpan={5}>
													<WeekNumberInput
														selectedClientReq4In={selectedClientReq4In}
														filteredSelectedDays={filteredSelectedDays}
														setFilteredSelectedDays={setFilteredSelectedDays}
														targetMonth={values?.thisMonth}
														targetYear={values?.thisYear}
														setWorkDatesPaydocu={setWorkDatesPaydocu}
														workDates={workDates}
														selectedPaydocu={selectedPaydocu}
														dispatch={dispatch}
														setMonthTotalWorkTime={setMonthTotalWorkTime}
														setMonthTotalWorkTimeDays={
															setMonthTotalWorkTimeDays
														}
														daylyTimePay={daylyTimePay}
													/>
												</Table.Cell>
											</Table.Row>
										) : (
											<>
												<Table.Row>
													<Table.Cell colSpan={4}>
														<WeekNumberInputLastMonth
															targetMonth={values?.thisMonth}
															targetYear={values?.thisYear}
															paydocus={paydocus}
															workerId={workerId}
															setPreviousWorkDates={setPreviousWorkDates}
														/>
													</Table.Cell>
													<Table.Cell colSpan={5}>
														<WeekNumberInputDayWorker
															confirmedBasicTime={confirmedBasicTime}
															selectedClientReq4In={selectedClientReq4In}
															filteredSelectedDays={filteredSelectedDays}
															setFilteredSelectedDays={setFilteredSelectedDays}
															targetMonth={values?.thisMonth}
															targetYear={values?.thisYear}
															setWorkDatesPaydocu={setWorkDatesPaydocu}
															workDates={workDates}
															selectedPaydocu={selectedPaydocu}
															dispatch={dispatch}
															setMonthTotalWorkTime={setMonthTotalWorkTime}
															setMonthTotalWorkTimeDays={
																setMonthTotalWorkTimeDays
															}
															daylyTimePay={daylyTimePay}
														/>
													</Table.Cell>
												</Table.Row>
											</>
										)}
										<Table.Row>
											{daylyTimePay > 0 ? null : (
												<Table.Cell colSpan='9' style={{ color: 'blue' }}>
													{`근무일 : ${
														workDates
															.filter((date) => date) // 유효한 날짜 데이터만 필터링
															.sort((a, b) => {
																// Date 객체를 사용하여 날짜를 비교
																const dateA = new Date(
																	a.year,
																	a.month - 1,
																	a.date
																);
																const dateB = new Date(
																	b.year,
																	b.month - 1,
																	b.date
																);
																return dateA - dateB; // 오름차순 정렬
															})
															.map((dateObj) => dateObj.date) // 날짜 정보만 추출
															.join(', ') // 쉼표로 날짜 구분
													} [총 ${workDates.length}일]`}
												</Table.Cell>
											)}
										</Table.Row>

										{+values?.countOfHoliDayWork !== 0 &&
										values?.countOfOverWorkingDayWork !== undefined ? (
											<>
												<Table.Row>
													<Table.Cell colSpan={'9'}>
														* 휴일 일당 구성항목
													</Table.Cell>
												</Table.Row>
												<Table.Row>
													<Table.Cell style={centerStyle}></Table.Cell>
													<Table.Cell style={centerStyle}>휴일 일당</Table.Cell>
													<Table.Cell style={centerStyle}>통상시급</Table.Cell>
													<Table.Cell style={centerStyle}>
														1일 근로시간 <br /> (전체)
													</Table.Cell>
													<Table.Cell style={centerStyle}>
														1일 유급시간 <br />
														(전체){' '}
													</Table.Cell>
													<Table.Cell style={centerStyle}>
														1일 기본급시간
													</Table.Cell>
													{numOfWorkers < 5 ? (
														<Table.Cell style={centerStyle}>
															1일 휴일보존 수당
														</Table.Cell>
													) : null}
													<Table.Cell style={centerStyle}>
														1일 연장시간
													</Table.Cell>
													<Table.Cell style={centerStyle}>
														1일 야간시간
													</Table.Cell>
													{numOfWorkers >= 5 ? (
														<Table.Cell style={centerStyle}></Table.Cell>
													) : null}
												</Table.Row>
												<Table.Row>
													<Table.Cell style={centerStyle}></Table.Cell>
													<Table.Cell style={centerStyle}>
														{numberToCommaString(roundUp(dayWorkerHoliWage))}
													</Table.Cell>
													<Table.Cell style={centerStyle}>
														{numberToCommaString(roundUp(dayWokerTimeWage))}
													</Table.Cell>
													<Table.Cell style={centerStyle}>
														{roundUp2(dayWorkerWorkingTimeDayTotal)}
													</Table.Cell>
													<Table.Cell style={centerStyle}>
														{roundUp2(
															numOfWorkers >= 5
																? dayWorkerHoliPaidTime
																: dayWorkerDayTotalPaidConversedTime
														)}
													</Table.Cell>
													<Table.Cell style={centerStyle}>
														{roundUp2(
															numOfWorkers >= 5
																? dayWorkerWorkingTimeDayBasic
																: dayWorkerWorkingTimeDayTotal
														)}
														<br /> 휴일기본환산(
														{roundUp2(dayWorkerWorkingTimeDayBasic * 1.5)}){' '}
													</Table.Cell>
													{numOfWorkers < 5 ? (
														<Table.Cell style={centerStyle}>
															{roundUp2(dayWorkerPaidHolidayLBTime)}
														</Table.Cell>
													) : null}
													<Table.Cell style={centerStyle}>
														{roundUp2(dayWorkerWorkingTimeDayOver)}
														<br /> 휴일연장환산(
														{roundUp2(dayWorkerWorkingTimeDayOver * 2)})
													</Table.Cell>
													<Table.Cell style={centerStyle}>
														{roundUp2(dayWorkerWorkingTimeDayNight)}
														<br /> 야간환산 (
														{roundUp2(dayWorkerWorkingTimeDayNight * 0.5)})
													</Table.Cell>
													{numOfWorkers >= 5 ? (
														<Table.Cell style={centerStyle}></Table.Cell>
													) : null}
												</Table.Row>
											</>
										) : null}
										{+values?.countOfOverWorkingDayWork !== 0 &&
										values?.countOfOverWorkingDayWork !== undefined ? (
											<>
												<Table.Row>
													<Table.Cell colSpan={'9'}>
														* 휴무일 일당 구성항목
													</Table.Cell>
												</Table.Row>
												<Table.Row>
													<Table.Cell style={centerStyle}></Table.Cell>
													<Table.Cell style={centerStyle}>
														휴무일 일당
													</Table.Cell>
													<Table.Cell style={centerStyle}>통상시급</Table.Cell>
													<Table.Cell style={centerStyle}>
														1일 근로시간 <br /> (전체)
													</Table.Cell>
													<Table.Cell style={centerStyle}>
														1일 유급시간 <br />
														(전체){' '}
													</Table.Cell>
													<Table.Cell style={centerStyle}>
														1일 기본급시간
													</Table.Cell>
													{numOfWorkers < 5 ? (
														<Table.Cell style={centerStyle}>
															1일 휴무일보존 수당
														</Table.Cell>
													) : null}
													<Table.Cell style={centerStyle}>
														1일 연장시간
													</Table.Cell>
													<Table.Cell style={centerStyle}>
														1일 야간시간
													</Table.Cell>
													{numOfWorkers >= 5 ? (
														<Table.Cell style={centerStyle}></Table.Cell>
													) : null}
												</Table.Row>
												<Table.Row>
													<Table.Cell style={centerStyle}></Table.Cell>
													<Table.Cell style={centerStyle}>
														{numberToCommaString(
															roundUp(dayWorkerOverWorkDayWage)
														)}
													</Table.Cell>
													<Table.Cell style={centerStyle}>
														{numberToCommaString(roundUp(dayWokerTimeWage))}
													</Table.Cell>
													<Table.Cell style={centerStyle}>
														{roundUp2(dayWorkerWorkingTimeDayTotal)}
													</Table.Cell>
													<Table.Cell style={centerStyle}>
														{roundUp2(
															numOfWorkers >= 5
																? dayWorkerOverWorkDayPaidTime
																: dayWorkerDayTotalPaidConversedTime
														)}
													</Table.Cell>
													<Table.Cell style={centerStyle}>
														{roundUp2(
															numOfWorkers >= 5
																? dayWorkerWorkingTimeDayBasic
																: dayWorkerWorkingTimeDayTotal
														)}
														<br /> 연장환산(
														{roundUp2(dayWorkerWorkingTimeDayBasic * 1.5)}){' '}
													</Table.Cell>
													{numOfWorkers < 5 ? (
														<Table.Cell style={centerStyle}>
															{roundUp2(dayWorkerPaidHolidayLBTime)}
														</Table.Cell>
													) : null}
													<Table.Cell style={centerStyle}>
														{roundUp2(dayWorkerWorkingTimeDayOver)}
														<br /> 연장환산(
														{roundUp2(dayWorkerWorkingTimeDayOver * 1.5)})
													</Table.Cell>
													<Table.Cell style={centerStyle}>
														{roundUp2(dayWorkerWorkingTimeDayNight)}
														<br /> 야간환산 (
														{roundUp2(dayWorkerWorkingTimeDayNight * 0.5)})
													</Table.Cell>
													{numOfWorkers >= 5 ? (
														<Table.Cell style={centerStyle}></Table.Cell>
													) : null}
												</Table.Row>
											</>
										) : null}
										{numOfWorkers < 5 ? (
											<Table.Row>
												<Table.Cell colSpan='9'>
													* 5인미만 사업장은 근로기준법에 따라 소정근로시간이
													없으므로 연장근로가 아닌 초과근로로 표시되고, 초과된
													근로에 대한 1배만 지급되며, 연장근로, 야간근로,
													휴일근로 가산수당이 적용되지 않습니다.
												</Table.Cell>
											</Table.Row>
										) : null}
										<ClientRDaylyWorkerMonthWageTableAddWork
											centerStyle={centerStyle}
											worker={worker}
											numOfWorkers={numOfWorkers}
											daylyTimePay={daylyTimePay}
											ClientReqDLCDayWorkerWageTableTimeDetail={
												ClientReqDLCDayWorkerWageTableTimeDetail
											}
											handelInputChange={handelInputChange}
											inputTimeStyle={inputTimeStyle}
											values={values}
											timeWorkerPaidHoli={values?.timeWorkerPaidHoli}
											timeWorkerPlusMonthOWT={values?.timeWorkerPlusMonthOWT}
											timeWorkerPlusMonthNWT={values?.timeWorkerPlusMonthNWT}
											timeWorkerPlusMonthHoliBasicTime={
												values?.timeWorkerPlusMonthHoliBasicTime
											}
											timeWorkerPlusMonthOWTHoli={
												values?.timeWorkerPlusMonthOWTHoli
											}
											timeWorkerPlusMonthOffBasicTime={
												values?.timeWorkerPlusMonthOffBasicTime
											}
											timeWorkerPlusMonthOWTOff={
												values?.timeWorkerPlusMonthOWTOff
											}
											timeWorkerPlusHoliBasicTimeDates={
												values?.timeWorkerPlusHoliBasicTimeDates
											}
											timeWorkerPlusHoliBasicTime={
												values?.timeWorkerPlusHoliBasicTime
											}
											timeWorkerPlusMonthOWTHoliDates={
												values?.timeWorkerPlusMonthOWTHoliDates
											}
											timeWorkerPlusMonthOWTOffDates={
												values?.timeWorkerPlusMonthOWTOffDates
											}
											timeWorkerPlusOverTimeDates={
												values?.timeWorkerPlusOverTimeDates
											}
											timeWorkerPlusNightTimeDates={
												values?.timeWorkerPlusNightTimeDates
											}
											timeWorkerPlusHoliOverTimeDates={
												values?.timeWorkerPlusHoliOverTimeDates
											}
											timeWorkerPlusOffBasicTimeDates={
												values?.timeWorkerPlusOffBasicTimeDates
											}
											timeWorkerPlusOffOverTimeDates={
												values?.timeWorkerPlusOffOverTimeDates
											}
											dayWorkerPlusMinus4InsComment={
												values?.dayWorkerPlusMinus4InsComment
											}
											dayWorkerPlusMinus4Ins={values?.dayWorkerPlusMinus4Ins}
										/>
										<Table.Row>
											<Table.Cell colSpan='9'>{` `}</Table.Cell>
										</Table.Row>
										<ClientReqDLCWageTableDayWorkerPayDetail
											dayWorkerTaxText={dayWorkerTaxText}
											dayWorkerDayBasicWorkingTime={
												dayWorkerDayBasicWorkingTime
											}
											isDayOrTimeWorker={isDayOrTimeWorker}
											filteredSelectedDays={filteredSelectedDays}
											weekBLTimeTW={weekBLTimeTW}
											setWeekBLTimeTW={setWeekBLTimeTW}
											weekBLTimeTWThisMonth={weekBLTimeTWThisMonth}
											setWeekBLTimeTWThisMonth={setWeekBLTimeTWThisMonth}
											normalWorkerWeekWorkDays={normalWorkerWeekWorkDays}
											finalPaidWeekObj={finalPaidWeekObj}
											lawBasePaidWeekObj={lawBasePaidWeekObj}
											finalPaidHoliWageTimeSum={finalPaidHoliWageTimeSum}
											lawBaseWorkDatesWorkHourThisMonthOnlySum={
												lawBaseWorkDatesWorkHourThisMonthOnlySum
											}
											week14OverBLTime={week14OverBLTime}
											monthTotalWorkTime={monthTotalWorkTime}
											monthTotalWorkTimeDays={monthTotalWorkTimeDays}
											values={values}
											initialTax={initialTax}
											dayWorkerTax={dayWorkerTax}
											dayWokerTimeWage={roundUp2(dayWokerTimeWage)}
											healthInsureOver60DayWorker={healthInsureOver60DayWorker}
											constructOrNot={constructOrNot}
											dayWorkerWage={dayWorkerWage}
											dayWorkerHoliWage={dayWorkerHoliWage}
											dayWorkerOverWorkDayWage={dayWorkerOverWorkDayWage}
											countOfNomalDayWork={countOfNomalDayWork}
											countOfOverWorkingDayWork={countOfOverWorkingDayWork}
											countOfHoliDayWork={countOfHoliDayWork}
											thisYear={values?.thisYear}
											thisMonth={values?.thisMonth}
											handelInputChange={handelInputChange}
											selectedClientReq4In={selectedClientReq4In}
											numOfWorkers={numOfWorkers}
											inputWageStyle={inputWageStyle}
											centerStyle={centerStyle}
											gumakStyle={gumakStyle}
											inputCommentStyle={inputCommentStyle}
											dayWorkerPlusMonthOWT={values?.dayWorkerPlusMonthOWT}
											dayWorkerPlusMonthNWT={values?.dayWorkerPlusMonthNWT}
											dayWorkerPlusMonthHoliBasicTime={
												values?.dayWorkerPlusMonthHoliBasicTime
											}
											dayWorkerPlusMonthOWTHoli={
												values?.dayWorkerPlusMonthOWTHoli
											}
											dayWorkerPlusMonthNWTHoli={
												values?.dayWorkerPlusMonthNWTHoli
											}
											timeWorkerPlusMonthOWT={values?.timeWorkerPlusMonthOWT}
											timeWorkerPlusMonthNWT={values?.timeWorkerPlusMonthNWT}
											timeWorkerPlusMonthHoliBasicTime={
												values?.timeWorkerPlusMonthHoliBasicTime
											}
											timeWorkerPlusMonthOWTHoli={
												values?.timeWorkerPlusMonthOWTHoli
											}
											timeWorkerPlusMonthOffBasicTime={
												values?.timeWorkerPlusMonthOffBasicTime
											}
											timeWorkerPlusMonthOWTOff={
												values?.timeWorkerPlusMonthOWTOff
											}
											timeWorkerPaidHoli={values?.timeWorkerPaidHoli}
											workingDays={values?.workingDays}
											dayWorkerCAWage5={values?.dayWorkerCAWage5}
											dayWorkerCAWEName5={values?.dayWorkerCAWEName5}
											dayWorkerCAWage5Comment={values?.dayWorkerCAWage5Comment}
											dayWorkerCAWage6={values?.dayWorkerCAWage6}
											dayWorkerCAWEName6={values?.dayWorkerCAWEName6}
											dayWorkerCAWage6Comment={values?.dayWorkerCAWage6Comment}
											dayWorkerCAWage7={values?.dayWorkerCAWage7}
											dayWorkerCAWEName7={values?.dayWorkerCAWEName7}
											dayWorkerCAWage7Comment={values?.dayWorkerCAWage7Comment}
											dayWorkerDeductEtcName1={values?.dayWorkerDeductEtcName1}
											dayWorkerDeductEtcWage1={values?.dayWorkerDeductEtcWage1}
											dayWorkerDeductEtcWage1Comment={
												values?.dayWorkerDeductEtcWage1Comment
											}
											dayWorkerDeductEtcName2={values?.dayWorkerDeductEtcName2}
											dayWorkerDeductEtcWage2={values?.dayWorkerDeductEtcWage2}
											dayWorkerDeductEtcWage2Comment={
												values?.dayWorkerDeductEtcWage2Comment
											}
											dayWorkerDeductEtcName3={values?.dayWorkerDeductEtcName3}
											dayWorkerDeductEtcWage3={values?.dayWorkerDeductEtcWage3}
											dayWorkerDeductEtcWage3Comment={
												values?.dayWorkerDeductEtcWage3Comment
											}
											dayWorkerUserFixedTax={values?.dayWorkerUserFixedTax}
											dayWorkerUserFixedRegionTax={
												values?.dayWorkerUserFixedRegionTax
											}
											dayWorkerUserFixedHealthCare={
												values?.dayWorkerUserFixedHealthCare
											}
											dayWorkerUserFixedOldCare={
												values?.dayWorkerUserFixedOldCare
											}
											dayWorkerUserFixedPension={
												values?.dayWorkerUserFixedPension
											}
											dayWorkerUserFixedNoJobInsurance={
												values?.dayWorkerUserFixedNoJobInsurance
											}
											dayWorkerAbsentTimes={values?.dayWorkerAbsentTimes}
											inputTax={inputTax}
											dayWorkerPlusDeductHealthCare={
												values?.dayWorkerPlusDeductHealthCare
											}
											dayWorkerPlusDeductOldCare={
												values?.dayWorkerPlusDeductOldCare
											}
											dayWorkerPlusDeductWorkTax={
												values?.dayWorkerPlusDeductWorkTax
											}
											dayWorkerPlusDeductWorkRegionTax={
												values?.dayWorkerPlusDeductWorkRegionTax
											}
											dayWorkerPlusDeductDuru={values?.dayWorkerPlusDeductDuru}
											dayWorkerPlusMinus4Ins={values?.dayWorkerPlusMinus4Ins}
											dayWorkerPlusDeductWorkerCompanyLoan={
												values?.dayWorkerPlusDeductWorkerCompanyLoan
											}
											dayWorkerPlusDeductHealthCareComment={
												values?.dayWorkerPlusDeductHealthCareComment
											}
											dayWorkerPlusDeductOldCareComment={
												values?.dayWorkerPlusDeductOldCareComment
											}
											dayWorkerPlusDeductWorkTaxComment={
												values?.dayWorkerPlusDeductWorkTaxComment
											}
											dayWorkerPlusDeductWorkRegionTaxComment={
												values?.dayWorkerPlusDeductWorkRegionTaxComment
											}
											dayWorkerPlusMinus4InsComment={
												values?.dayWorkerPlusMinus4InsComment
											}
											dayWorkerPlusDeductWorkerCompanyLoanComment={
												values?.dayWorkerPlusDeductWorkerCompanyLoanComment
											}
											daylyTimePay={daylyTimePay}
											timeWorkerTimeWage={timeWorkerTimeWage}
											timeWorkerTimePaidHoliWage={timeWorkerTimePaidHoliWage}
											dayWorkerSpecialBonus={values?.dayWorkerSpecialBonus}
											timeWorkerSpecialBonus={values?.timeWorkerSpecialBonus}
											netAgeEntered={netAgeEntered}
										/>
										<Table.Row width='100%' align='center'>
											<Table.Cell colSpan='9' align='center'>
												{format(today, 'yyyy-MM-dd')}
											</Table.Cell>
										</Table.Row>
										<Table.Row>
											<Table.Cell
												colSpan='9'
												align='center'>{`임금명세서를 교부받았음을 확인합니다. : ${worker} (서명 또는 인)`}</Table.Cell>
										</Table.Row>
										<Table.Row>
											<Table.Cell
												colSpan='9'
												align='left'>{`근로기준법 제48조 제2항 및 2021.11.19.시행 임금명세서 교부 의무 설명자료(2021.11 고용노동부)에 따라 본 명세서는 ${worker}에게 교부된 것으로 본다.`}</Table.Cell>
										</Table.Row>
									</Table.Body>
								</Table>
							</form>
						</div>
						<br />
						<hr />
						<hr />
					</Grid.Column>
				</Grid>
			)}
		</div>
	);
}
