import React from 'react';
import { useHistory } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroller';
import { decrypto } from '../../../../../app/firestore/firestoreService';
import SortableTable from '../../../../../app/common/form/SortableTable';
import { safeStringCompare } from '../../../../../app/common/util/util';
import { Button } from 'semantic-ui-react';
import * as XLSX from 'xlsx';

export default function WorkerRegisterList({
	registeredWorkerPaydocuWorkerUid,
	workersList,
	getNextClientReqs,
	loading,
	moreClientReqs,
	title,
	authLevel,
}) {
	const history = useHistory();
	const workerListId = workersList.map((worker) => worker?.id);
	const unRegisteredWorkerId = workerListId.filter(
		(id) => !registeredWorkerPaydocuWorkerUid?.includes(id)
	);

	const headers = [
		{ key: 'status', label: '직원구분' },
		{ key: 'name', label: '직원성명' },
		...(authLevel > 100 ? [{ key: 'workerDivisions', label: '부서' }] : []),
		{ key: 'login', label: '직원로그인' },
		{ key: 'retiredDate', label: '퇴직구분' },
		{ key: 'companyName', label: '회사이름' },
		{ key: 'hostUid', label: '회사코드' },
		{ key: 'id', label: '직원코드' },
		{ key: 'email', label: '직원ID' },
		{ key: 'workerSocialNumberFront', label: '임시비번' },
		{ key: 'download', label: '엑셀 다운로드' }, // 엑셀 다운로드 버튼을 위한 헤더 추가
	];

	const sortFunctions = {
		status: (a, b) => safeStringCompare(a.status, b.status),
		name: (a, b) => safeStringCompare(a.name, b.name),
		companyName: (a, b) => safeStringCompare(a.companyName, b.companyName),
		hostUid: (a, b) => safeStringCompare(a.hostUid, b.hostUid),
		id: (a, b) => safeStringCompare(a.id, b.id),
		email: (a, b) => safeStringCompare(a.email, b.email),
		workerSocialNumberFront: (a, b) =>
			safeStringCompare(a.workerSocialNumberFront, b.workerSocialNumberFront),
		login: (a, b) => safeStringCompare(a.login, b.login),
		retiredDate: (a, b) => safeStringCompare(a.retiredDate, b.retiredDate),
		...(authLevel > 100
			? {
					workerDivisions: (a, b) =>
						safeStringCompare(a.workerDivisions, b.workerDivisions),
			  }
			: {}), // 부서 정렬 함수 추가 (조건부)
	};

	const exportToExcel = (row) => {
		if (!row.email) {
			alert('인사정보에 직원 이메일 등록하시면 엑셀 다운이 가능합니다.');
			if (row.status === '정규직') {
				history.push(`/manageClientReq4In/${row.id}`);
			} else {
				history.push(`/manageClientReqDayWorker/${row.id}`);
			}
			return;
		}

		const excludedKeys = ['status', 'login', 'retiredDate', 'download'];
		const headerLabels = headers
			.filter((header) => !excludedKeys?.includes(header.key))
			.map((header) => header.label);
		const rowData = headers
			.filter((header) => !excludedKeys?.includes(header.key))
			.map((header) => row[header.key]);
		const worksheetData = [headerLabels, rowData];
		const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
		XLSX.writeFile(workbook, `${row.name}_직원등록정보.xlsx`);
	};

	const data = workersList.map((workerList) => {
		let status = '정규직'; // 기본값
		if (workerList?.dayWorker?.daylyPay > 0) {
			status = '일용직';
		} else if (workerList?.dayWorker?.timeWage > 0) {
			status = '시급직';
		} else if (workerList?.isBizWorker) {
			status = '사업소득직';
		}

		return {
			status: status,
			name: workerList?.worker?.name,
			login: unRegisteredWorkerId?.includes(workerList?.id)
				? '미등록'
				: '등록완료',
			retiredDate: workerList?.retiredDate ? '퇴사자' : '',
			companyName: workerList?.companyName,
			hostUid: workerList?.hostUid,
			id: workerList?.id,
			email: workerList?.worker?.email,
			workerSocialNumberFront: decrypto(
				workerList?.workerSocialNumberFront,
				String(process.env.CRYPTO_KEY)
			),
			workerDivisions: workerList?.workerDivisions || '부서 없음', // 기본값 설정
		};
	});

	const customRenderers = {
		download: (row) => (
			<Button color='green' onClick={() => exportToExcel(row)}>
				다운로드
			</Button>
		),
	};

	return (
		<>
			{workersList.length !== 0 && (
				<InfiniteScroll
					pageStart={0}
					loadMore={getNextClientReqs}
					hasMore={!loading && moreClientReqs}
					initialLoad={false}>
					<br />
					<SortableTable
						headers={headers}
						data={data}
						sortFunctions={sortFunctions}
						title={title}
						customRenderers={customRenderers}
						enableSearch={true}
					/>
				</InfiniteScroll>
			)}
		</>
	);
}
