import React, { useEffect, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import SortableTable from '../../../../../app/common/form/SortableTable';
import {
	absentDaysYearCntForALFNew,
	contractOldWorkerF,
	getAgeFromSocialNumber,
	numberToCommaString,
	roundUp,
	safeStringCompare,
	sumLastYearPaidAnnualLeaveDaysForYearNew,
	sumPaidAnnualLeaveDaysForYearNew,
	usedAnnualLeavesInThisYearSumFNew,
} from '../../../../../app/common/util/util';
import { useDispatch, useSelector } from 'react-redux';
import {
	clearPaydocuAnnualLeaves,
	fetchPaydocusAnnualLeavesFormFirestoreService4In,
} from '../../../../companyManage/companyActions';

import {
	calALSpecificBagicYear,
	calALSpecificMonth,
	calALSpecificPlusYear,
} from '../../../../../annualLeave-libs/lawALSpec-lib';
import moment from 'moment';
import { decrypto } from '../../../../../app/firestore/firestoreService';
import { Item } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export default function WorkerAnnualLeavesLawBaseInfoList({
	workersList = [], // 기본값 설정
	getNextClientReqs,
	loading,
	moreClientReqs,
	currentUserProfile,
}) {
	const dispatch = useDispatch();
	const history = useHistory();

	const { paydocusAnnualLeaves, clientInfo, filter } = useSelector(
		(state) => state.companyReducer
	);

	const [calculatedData, setCalculatedData] = useState([]);
	const [calculatedUsedData, setCalculatedUsedData] = useState([]);
	const [calculatedPaidData, setCalculatedPaidData] = useState([]);
	const [calculatedLastYearPaidData, setCalculatedLastYearPaidData] = useState(
		[]
	);
	const [selectedYear, setSelectedYear] = useState(moment().year());

	const [loadingProgress, setLoadingProgress] = useState(0);
	const [isComplete, setIsComplete] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [targetPath, setTargetPath] = useState(null); // 이동할 경로 저장
	const [showRetired, setShowRetired] = useState(false); // 재직자/퇴사자 보기 상태

	const currentYear = moment().year();

	const specificDate =
		selectedYear === currentYear ? '' : `${selectedYear}-12-31`;

	const numOfWorkersOfCompany = clientInfo?.numOfWorkers
		? clientInfo?.numOfWorkers
		: currentUserProfile?.numOfWorkers;

	// 유효한 날짜 값인지 확인하고 포맷하는 함수
	const formatDateWithMoment = (dateString) => {
		return moment(dateString).isValid()
			? moment(dateString).format('YYYY-MM-DD')
			: '';
	};
	const title = `${selectedYear}년도 직원 연차 정보(입사일 기준, ${
		specificDate === '' ? '오늘날짜' : specificDate
	} 기준)`;

	const plusAnnualLeavesDays = clientInfo?.plusAnnualLeavesDays
		? clientInfo?.plusAnnualLeavesDays
		: currentUserProfile?.plusAnnualLeavesDays
		? currentUserProfile?.plusAnnualLeavesDays
		: 0;

	const overFiveStartDay = clientInfo?.overFiveStartDay
		? formatDateWithMoment(clientInfo?.overFiveStartDay)
		: currentUserProfile?.overFiveStartDay
		? formatDateWithMoment(currentUserProfile?.overFiveStartDay)
		: '';

	useEffect(() => {
		if (!workersList.length) return;
		const fetchPaydocusAnnualLeavesForWorkers = async () => {
			try {
				// Map and execute dispatch calls in parallel
				await Promise.all(
					workersList.map((worker) => {
						const workerId = worker?.id;
						return workerId
							? dispatch(
									fetchPaydocusAnnualLeavesFormFirestoreService4In(
										filter,
										workerId,
										clientInfo,
										String(selectedYear)
									)
							  )
							: Promise.resolve();
					})
				);
			} catch (error) {
				console.error('Error fetching paydocusAnnualLeaves:', error);
			}
		};

		fetchPaydocusAnnualLeavesForWorkers();

		return () => {
			dispatch(clearPaydocuAnnualLeaves());
		};
	}, [workersList, clientInfo, dispatch, filter, selectedYear]);

	// annualLeavesData를 메모이제이션
	const annualLeavesData = useMemo(() => {
		return paydocusAnnualLeaves.map((paydocu) => ({
			workerId: paydocu?.workerInfo?.workerId,
			workerName: paydocu?.workerInfo?.worker,
			yearMonth: paydocu?.info?.yearMonth,
			absentDays: paydocu?.absentMinusWage?.absentDays,
			usedAnnualLeaves: paydocu?.usedAnnualLeaves,
			plusUnusedAnnualLeavesDays:
				paydocu?.addAverWage?.plusUnusedAnnualLeavesDays,
			plusLastYearUnusedAnnualLeavesDays:
				paydocu?.addAverWage?.plusLastYearUnusedAnnualLeavesDays,
		}));
	}, [paydocusAnnualLeaves]);

	useEffect(() => {
		if (annualLeavesData.length <= 0) return;

		const calculateData = () => {
			const updatedAnnualData = [];
			const updatedUsedData = [];
			const updatedPaidData = [];
			const updatedPaidLastYearData = [];

			workersList.forEach((worker) => {
				const workerId = worker?.id;

				// 연차 데이터 계산
				const [id, , thisYearAbsentData] = absentDaysYearCntForALFNew(
					workerId,
					annualLeavesData,
					String(selectedYear)
				);
				updatedAnnualData.push({
					workerId: id,
					absentDaysYearCntForALFValue: thisYearAbsentData || 0,
				});

				// 사용 연차 데이터 계산
				const [usedId, usedAnnualLeavesInThisYearSum] =
					usedAnnualLeavesInThisYearSumFNew(
						annualLeavesData,
						workerId,
						String(selectedYear)
					);
				updatedUsedData.push({
					workerId: usedId,
					usedAnnualLeavesInThisYearSumFValue:
						usedAnnualLeavesInThisYearSum || 0,
				});

				// 지급 연차 데이터 계산
				const [paidId, totalDays] = sumPaidAnnualLeaveDaysForYearNew(
					workerId,
					annualLeavesData,
					String(selectedYear)
				);
				updatedPaidData.push({
					workerId: paidId,
					paidAnnualLeavesInThisYearSumFValue: totalDays || 0,
				});

				// 지급 연차 데이터 계산
				const [paidIdLastYear, totalDaysLastYear] =
					sumLastYearPaidAnnualLeaveDaysForYearNew(
						workerId,
						annualLeavesData,
						String(selectedYear)
					);
				updatedPaidLastYearData.push({
					workerId: paidIdLastYear,
					paidAnnualLeavesInLastYearSumFValue: totalDaysLastYear || 0,
				});
			});

			setCalculatedData(updatedAnnualData);
			setCalculatedUsedData(updatedUsedData);
			setCalculatedPaidData(updatedPaidData);
			setCalculatedLastYearPaidData(updatedPaidLastYearData);
		};

		calculateData();
	}, [annualLeavesData, workersList, selectedYear]);

	const sortFunctions = {
		status: (a, b) => safeStringCompare(a.status, b.status),
		name: (a, b) => safeStringCompare(a.name, b.name),
		workerDivisions: (a, b) =>
			safeStringCompare(a.workerDivisions, b.workerDivisions),
		workerTitles: (a, b) => safeStringCompare(a.workerTitles, b.workerTitles),
		workerEnteredDate: (a, b) =>
			safeStringCompare(a.workerEnteredDate, b.workerEnteredDate),
		retiredDate: (a, b) => safeStringCompare(a.retiredDate, b.retiredDate),
		calALTodMonthV: (a, b) => a.calALTodMonthV - b.calALTodMonthV,
		calALTodBagicYearV: (a, b) => a.calALTodBagicYearV - b.calALTodBagicYearV,
		calALTodPlusYearV: (a, b) => a.calALTodPlusYearV - b.calALTodPlusYearV,
		sumCalALTod: (a, b) => a.sumCalALTod - b.sumCalALTod,
		absentDaysYearCntForALFValue: (a, b) =>
			a.absentDaysYearCntForALFValue - b.absentDaysYearCntForALFValue,
		usedAnnualLeavesInThisYearSumFValue: (a, b) =>
			a.usedAnnualLeavesInThisYearSumFValue -
			b.usedAnnualLeavesInThisYearSumFValue,
		paidAnnualLeavesInThisYearSumFValue: (a, b) =>
			a.paidAnnualLeavesInThisYearSumFValue -
			b.paidAnnualLeavesInThisYearSumFValue,
		paidAnnualLeavesInLastYearSumFValue: (a, b) =>
			a.paidAnnualLeavesInLastYearSumFValue -
			b.paidAnnualLeavesInLastYearSumFValue,
		remainedAnnualLeaves: (a, b) =>
			a.remainedAnnualLeaves - b.remainedAnnualLeaves,
		remainedAnnualLeavesWage: (a, b) =>
			a.remainedAnnualLeavesWage - b.remainedAnnualLeavesWage,
		ordinaryDayWage: (a, b) => a.ordinaryDayWage - b.ordinaryDayWage,
	};

	const data = workersList
		.filter((workerList) => {
			// 시급직, 일용직, 사업소득직 제외
			const isHourlyWorker = workerList?.dayWorker?.timeWage > 0;
			const isDailyWorker = workerList?.dayWorker?.daylyPay > 0;
			const isBizWorker = workerList?.isBizWorker;
			// const isRetired = workerList?.retiredDate;

			return !(isHourlyWorker || isDailyWorker || isBizWorker);
		})

		.map((workerList) => {
			let status = '정규직';
			if (workerList?.contractEndDate) {
				status = '계약직';
			}
			const workerId = workerList?.id;
			const workerDivisions = workerList?.workerDivisions || '부서 없음';

			const calculatedWorker = calculatedData.find(
				(item) => item.workerId === workerId
			);

			const calculatedUsed = calculatedUsedData.find(
				(item) => item.workerId === workerId
			);

			const calculatedPaid = calculatedPaidData.find(
				(item) => item.workerId === workerId
			);

			const calculatedPaidLastYear = calculatedLastYearPaidData.find(
				(item) => item.workerId === workerId
			);

			const decryptoClientReq = {};
			for (const prop in workerList) {
				decryptoClientReq[prop] =
					prop === 'workerSocialNumberFront' ||
					prop === 'workerSocialNumberBack' ||
					prop === 'familySocialNumber' ||
					prop === 'healthCarePeopleComments'
						? decrypto(workerList[prop], String(process.env.CRYPTO_KEY))
						: workerList[prop];
			}

			const workerSocialNumberFront = decryptoClientReq.workerSocialNumberFront;
			const netAge = getAgeFromSocialNumber(workerSocialNumberFront);

			//상시근로지수
			const numOfWorkers = decryptoClientReq?.numOfWorkers;

			const contarctOldWorkerYes = contractOldWorkerF(
				decryptoClientReq?.contractOldWorker,
				decryptoClientReq?.afterRetireAgainContractStartDate,
				decryptoClientReq?.againContractStartDate,
				netAge
			);

			//입사일
			const enterDate = workerList
				? formatDateWithMoment(decryptoClientReq?.workerEnteredDate)
				: '';
			//
			const afterRetireAgainContractStartDate =
				workerList &&
				contarctOldWorkerYes &&
				decryptoClientReq?.afterRetireAgainContractStartDate
					? formatDateWithMoment(
							decryptoClientReq?.afterRetireAgainContractStartDate
					  )
					: '';

			//5인이상 적용일과 촉탁 입사일로 입사일 조정
			const anuualLeaveEnterDate =
				overFiveStartDay >= (enterDate || afterRetireAgainContractStartDate)
					? overFiveStartDay
					: afterRetireAgainContractStartDate >= enterDate
					? afterRetireAgainContractStartDate
					: enterDate;

			const workerEnteredDate = formatDateWithMoment(
				workerList?.workerEnteredDate
			);

			const formattedEnteredDate = workerEnteredDate;

			const retiredDate = workerList?.retiredDate;
			const formattedRetiredDate = retiredDate
				? formatDateWithMoment(retiredDate)
				: null;

			//연차 계산
			const annualLeaves = decryptoClientReq?.annualLeaves;

			const week14OverBLTime = workerList?.week14OverBLTime;

			const calALTodMonthV = calALSpecificMonth(
				anuualLeaveEnterDate,
				numOfWorkers,
				specificDate,
				formattedRetiredDate
			);
			const calALTodBagicYearV = calALSpecificBagicYear(
				anuualLeaveEnterDate,
				numOfWorkers,
				specificDate,
				formattedRetiredDate
			);
			const calALTodPlusYearV = calALSpecificPlusYear(
				anuualLeaveEnterDate,
				numOfWorkers,
				specificDate,
				formattedRetiredDate
			);

			const sumCalALTod =
				calALTodMonthV +
				calALTodBagicYearV +
				calALTodPlusYearV +
				Number(plusAnnualLeavesDays);

			let annualLeavesApplyDayComment;

			if (
				overFiveStartDay >= (enterDate || afterRetireAgainContractStartDate)
			) {
				annualLeavesApplyDayComment = (
					<span style={{ color: 'red' }}>5인이상 연차적용일</span>
				);
			} else if (
				afterRetireAgainContractStartDate &&
				afterRetireAgainContractStartDate >= enterDate
			) {
				annualLeavesApplyDayComment = (
					<span style={{ color: 'red' }}>정년이후 촉탁입사일</span>
				);
			} else {
				annualLeavesApplyDayComment = '최초입사일';
			}

			const ordinaryDayWage = numberToCommaString(
				roundUp(
					Number(workerList?.lawBase?.hourOrdinaryWage || 0) *
						Number(workerList?.lawBase?.paidHolidayLBTime || 0)
				)
			);
			// 월단위연차결근공제, 사용연차, 지급연차 값을 가져옴
			const absentDaysYearCntForALFValue =
				calculatedWorker?.absentDaysYearCntForALFValue || 0;
			const usedAnnualLeavesInThisYearSumFValue =
				calculatedUsed?.usedAnnualLeavesInThisYearSumFValue || 0;
			const paidAnnualLeavesInThisYearSumFValue =
				calculatedPaid?.paidAnnualLeavesInThisYearSumFValue || 0;

			const paidAnnualLeavesInLastYearSumFValue =
				calculatedPaidLastYear?.paidAnnualLeavesInLastYearSumFValue || 0;

			// 잔여연차 계산
			const remainedAnnualLeaves =
				sumCalALTod -
				absentDaysYearCntForALFValue -
				usedAnnualLeavesInThisYearSumFValue -
				paidAnnualLeavesInThisYearSumFValue -
				paidAnnualLeavesInLastYearSumFValue;

			const remainedAnnualLeavesWage = numberToCommaString(
				roundUp(
					Number(workerList?.lawBase?.hourOrdinaryWage || 0) *
						Number(workerList?.lawBase?.paidHolidayLBTime || 0) *
						remainedAnnualLeaves
				)
			);

			return {
				status: status,
				annualLeavesApplyDayComment: annualLeavesApplyDayComment,
				workerId: workerId,
				workerDivisions: workerDivisions || '부서 없음',
				workerTitles: workerList?.workerTitles || '직위 없음',
				name: workerList?.worker?.name,
				workerEnteredDate: formattedEnteredDate,
				anuualLeaveEnterDate: anuualLeaveEnterDate,
				retiredDate: formattedRetiredDate,

				ordinaryDayWage: ordinaryDayWage || 0,
				annualLeaves: annualLeaves || 0,
				week14OverBLTime: week14OverBLTime || 0,

				calALTodMonthV: calALTodMonthV,
				calALTodBagicYearV: calALTodBagicYearV,
				calALTodPlusYearV: calALTodPlusYearV,
				...(plusAnnualLeavesDays > 0 && {
					plusAnnualLeavesDays, // 값이 있는 경우에만 추가
				}),
				sumCalALTod: sumCalALTod || 0,
				absentDaysYearCntForALFValue:
					calculatedWorker?.absentDaysYearCntForALFValue || 0, // 계산된 값 매핑

				usedAnnualLeavesInThisYearSumFValue:
					calculatedUsed?.usedAnnualLeavesInThisYearSumFValue || 0,

				paidAnnualLeavesInThisYearSumFValue:
					calculatedPaid?.paidAnnualLeavesInThisYearSumFValue || 0,

				paidAnnualLeavesInLastYearSumFValue:
					calculatedPaidLastYear?.paidAnnualLeavesInLastYearSumFValue || 0,

				remainedAnnualLeaves: remainedAnnualLeaves,
				remainedAnnualLeavesWage: remainedAnnualLeavesWage || 0,
			};
		});

	const headers = [
		// { key: 'workerId', label: 'id' },
		{ key: 'workerDivisions', label: '부서' },
		{ key: 'workerTitles', label: '직위/직책' },
		// { key: 'status', label: '직원구분' },
		{ key: 'name', label: '직원성명' },
		{ key: 'anuualLeaveEnterDate', label: '연차적용입사일' },
		...(showRetired // 퇴사자 보기일 때만 퇴사일 추가
			? [{ key: 'retiredDate', label: '퇴사일' }]
			: []),
		{
			key: 'calALTodMonthV',
			label: '월단위 연차',
		},
		// {
		// 	key: 'calALFinTodRatioYearV',
		// 	label: '비례 연차(1년미만)',
		// },
		{
			key: 'calALTodBagicYearV',
			label: '기본 연차',
		},
		{
			key: 'calALTodPlusYearV',
			label: '가산 연차',
		},
		...(plusAnnualLeavesDays
			? [{ key: 'plusAnnualLeavesDays', label: '회사부여연차' }]
			: []),
		{
			key: 'sumCalALTod',
			label: '발생연차 합계',
		},
		{
			key: 'absentDaysYearCntForALFValue',
			label: '결근 연차 공제(1년미만)',
		},
		{
			key: 'usedAnnualLeavesInThisYearSumFValue',
			label: '사용연차',
		},
		{
			key: 'paidAnnualLeavesInThisYearSumFValue',
			label: '수당지급연차',
		},
		{
			key: 'remainedAnnualLeaves',
			label: '잔여연차',
		},
		{
			key: 'ordinaryDayWage',
			label: '연차수당(1일)',
		},
		{
			key: 'remainedAnnualLeavesWage',
			label: '잔여연차수당',
		},
	];

	const customRenderers = {
		maternityLeaves: (row) => (
			<div style={{ whiteSpace: 'pre-line' }}>{row.maternityLeaves}</div>
		),
		childLeaveOfAbsences: (row) => (
			<div style={{ whiteSpace: 'pre-line' }}>{row.childLeaveOfAbsences}</div>
		),
		childShortTimes: (row) => (
			<div style={{ whiteSpace: 'pre-line' }}>{row.childShortTimes}</div>
		),
		leaveOfAbsences: (row) => (
			<div style={{ whiteSpace: 'pre-line' }}>{row.leaveOfAbsences}</div>
		),
	};

	const handleYearChange = (event) => {
		setSelectedYear(Number(event.target.value));
	};

	const renderYearSelect = () => {
		const currentYear = moment().year();
		const years = Array.from({ length: 5 }, (_, i) => currentYear - i);
		return (
			<select value={selectedYear} onChange={handleYearChange}>
				{years.map((year) => (
					<option key={year} value={year}>
						{year}년
					</option>
				))}
			</select>
		);
	};

	const annualLeaves = clientInfo?.annualLeaves
		? clientInfo?.annualLeaves
		: currentUserProfile?.annualLeaves;

	const annualLeavesYearBase =
		annualLeaves === '특정일(회계년도 등)기준' ? true : false;

	const filteredData = data.filter((workerList) =>
		showRetired ? workerList.retiredDate : !workerList.retiredDate
	);

	const startLoading = (path) => {
		setTargetPath(path);
	};

	useEffect(() => {
		if (!isLoading) return;

		const interval = setInterval(() => {
			setLoadingProgress((prev) => {
				if (prev >= 100) {
					clearInterval(interval);
					setIsComplete(true);
					setIsLoading(false);
					history.push(targetPath);
					return 100;
				}
				return prev + 1;
			});
		}, 50);

		return () => clearInterval(interval);
	}, [isLoading, targetPath, history]);

	// isLoading을 별도로 제어
	useEffect(() => {
		if (targetPath) {
			setIsLoading(true);
		}
	}, [targetPath]);

	return (
		<>
			<div
				style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
				<label htmlFor='yearSelect' style={{ marginRight: '10px' }}>
					연도 선택:{' '}
				</label>
				{renderYearSelect()}

				{/* Buttons for 법정연차 and 회계연차 */}
				<button
					onClick={() => startLoading('/workerAnnualLeavesLawBaseInfo')}
					style={{
						marginLeft: '10px',
						padding: '5px 10px',
						cursor: 'pointer',
						color: 'blue',
					}}>
					법정연차
				</button>
				{annualLeavesYearBase ? (
					<button
						onClick={() => startLoading('/workerAnnualLeavesYearBaseInfo')}
						style={{
							marginLeft: '5px',
							padding: '5px 10px',
							cursor: 'pointer',
							color: 'purple',
						}}>
						회계연차
					</button>
				) : (
					<button
						onClick={() =>
							alert(
								'귀사는 법정 연차입니다. 회계 기준 연차는 효율적입니다. 전문가와 상의하세요.'
							)
						}
						style={{
							marginLeft: '5px',
							padding: '5px 10px',
							cursor: 'pointer',
							color: 'purple',
						}}>
						회계연차
					</button>
				)}
			</div>
			{/* Buttons to toggle active and retired workers */}
			<button
				onClick={() => setShowRetired(false)}
				style={{
					marginLeft: '10px',
					padding: '5px 10px',
					cursor: 'pointer',
					backgroundColor: showRetired ? 'gray' : 'green',
					color: 'white',
				}}>
				재직자 보기
			</button>
			<button
				onClick={() => setShowRetired(true)}
				style={{
					marginLeft: '5px',
					padding: '5px 10px',
					cursor: 'pointer',
					backgroundColor: showRetired ? 'green' : 'gray',
					color: 'white',
				}}>
				퇴사자 보기
			</button>
			{isLoading && !isComplete ? (
				<div>
					<h1>연차를 계산중입니다. 잠시만 기다려주세요........</h1>
					<h3>계산 진행률: {loadingProgress}%</h3>
				</div>
			) : isComplete ? (
				<div>
					<h1>계산이 완료되었습니다!</h1>
				</div>
			) : (
				<>
					{numOfWorkersOfCompany < 5 ? (
						<>
							<hr />
							<Item.Description>
								<div>
									<strong>연차 관리 대장</strong>
								</div>
								<div style={{ color: 'purple' }}>
									* 5인미만 사업장으로 연차가 발생되지 않습니다.
								</div>
							</Item.Description>
						</>
					) : (
						<>
							{workersList.length !== 0 ? (
								<InfiniteScroll
									pageStart={0}
									loadMore={getNextClientReqs}
									hasMore={!loading && moreClientReqs}
									initialLoad={false}>
									<br />
									<SortableTable
										headers={headers}
										data={filteredData}
										sortFunctions={sortFunctions}
										title={title}
										customRenderers={customRenderers}
										enableSearch={true}
									/>
								</InfiniteScroll>
							) : (
								<div>데이터가 없습니다.</div>
							)}
						</>
					)}
				</>
			)}
		</>
	);
}
