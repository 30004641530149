import React from 'react';
import CrwfWorkerBasicInfoTax4In from './CrwfWorkerBasicInfoTax4In';
import {
	lowestTimeWageOf2025,
	numberToCommaString,
} from '../../../../../app/common/util/util';
import { selectPaidWeekHolidays } from '../../../../../app/api/dropdownOption';
import MyTextArea from '../../../../../app/common/form/MyTextArea';

function CrwfDayWorkerTimeAndWageInfo({
	selectedClientReq4In,
	MyTextInput,
	MyNumberInput,
	over14InWeek,
	MyTimeInput,
	dayType,
	MySelectInput,
	construct,
	dayWorkerTaxOrNot,
	values,
	openTax4In,
	setOpenTax4In,
	openPensionPayType,
	setOpenPensionPayType,
	openHealthCarePayType,
	setOpenHealthCarePayType,
	openNoJobPayType,
	setOpenNoJobPayType,
	openOldCarePayType,
	setOpenOldCarePayType,
	lawBaselowWageIncludePaidHoliday,
	setWeek14OverBLTimeV,
	week14OverBLTimeV,
	dayWorker,
	timeWorker,
	bizIncomeWorker,
}) {
	const yearText = new Date().getFullYear();
	const yearTextComma = numberToCommaString(yearText);
	const lawlowWageText = ` (주의 : ${yearTextComma}년 최저시급은 ${numberToCommaString(
		lowestTimeWageOf2025
	)}원, 주휴포함시 ${numberToCommaString(
		lawBaselowWageIncludePaidHoliday
	)}원 미달시 저장이 되지 않습니다.)`;
	const week14OverBLTimeDb = selectedClientReq4In?.week14OverBLTime;

	return (
		<div>
			<hr />
			<div
				style={{
					display: 'flex',
					flexWrap: 'wrap',
					justifyContent: 'flex-start',
					alignItems: 'center',
					alignContent: 'flex-end',
				}}>
				<div
					style={{
						width: '100%',
						marginBottom: '30px',
						marginTop: '30px',
						fontSize: '17px',
					}}>
					ㅁ 근로계약 임금 및 시간 정보
				</div>

				{timeWorker && (
					<>
						<div
							className='margin'
							style={{ width: '43%', marginRight: '3px' }}>
							<MyNumberInput
								label={
									<>
										<span style={{ color: 'blue' }}>* 시급 </span>
										<span style={{ color: 'red', fontSize: '0.8rem' }}>
											{lawlowWageText}
										</span>
									</>
								}
								name='daylyPay.timeWage'
								placeholder='시급'
								autoComplete='off'
							/>
						</div>
						<div
							className='margin'
							style={{ width: '33%', marginRight: '3px', marginTop: '10px' }}>
							<MySelectInput
								onChangeCallback={setWeek14OverBLTimeV}
								label={
									<>
										<span style={{ color: 'blue' }}>
											{' '}
											* 1주 소정근로시간 15시간이상 근무 여부 (주휴수당 포함
											여부)
										</span>
									</>
								}
								name='week14OverBLTime'
								options={over14InWeek}
							/>
						</div>
						{week14OverBLTimeV === '주휴수당 포함' ||
						week14OverBLTimeDb === '주휴수당 포함' ? (
							<div
								className='margin'
								style={{ width: '23%', marginRight: '3px' }}>
								<MySelectInput
									label={
										<span style={{ color: 'blue' }}>
											* 법정 유급 주휴일 (하루만 선택)
										</span>
									}
									placeholder='예) 일요일'
									name='daylyPay.paidOffdays'
									options={selectPaidWeekHolidays}
								/>
							</div>
						) : null}
						<div
							className='margin'
							style={{ width: '99%', marginRight: '3px' }}>
							<MyTextArea
								label='* 근로계약 특약사항'
								name='daylyPay.specialWorkConditionContents'
								placeholder='타 사업장 파견 근무 동의 조항 등'
								autoComplete='off'
								rows='3'
							/>
						</div>
					</>
				)}
				{dayWorker && !timeWorker && (
					<>
						<div
							className='margin'
							style={{ width: '43%', marginRight: '3px' }}>
							<MyNumberInput
								label={
									<>
										<span style={{ color: 'blue' }}>* 일급</span>
									</>
								}
								name='daylyPay.wage'
								placeholder='하루 일당'
								autoComplete='off'
							/>
						</div>
						<div
							className='margin'
							style={{ width: '33%', marginRight: '3px', marginTop: '10px' }}>
							<MySelectInput
								onChangeCallback={setWeek14OverBLTimeV}
								label={
									<>
										<span style={{ color: 'blue' }}>
											{' '}
											* 1주 소정근로시간 15시간이상 근무 여부 (주휴수당 포함
											여부)
										</span>
									</>
								}
								name='week14OverBLTime'
								options={over14InWeek}
							/>
						</div>
						{week14OverBLTimeV === '주휴수당 포함' ||
						week14OverBLTimeDb === '주휴수당 포함' ? (
							<div
								className='margin'
								style={{ width: '23%', marginRight: '3px' }}>
								<MySelectInput
									label={
										<span style={{ color: 'blue' }}>
											* 법정 유급 주휴일 (하루만 선택)
										</span>
									}
									placeholder='예) 일요일'
									name='daylyPay.paidOffdays'
									options={selectPaidWeekHolidays}
								/>
							</div>
						) : null}
						<div
							className='margin'
							style={{ width: '33%', marginRight: '3px' }}>
							<MyTimeInput
								label={
									<>
										<span style={{ color: 'blue' }}>* 출근시간</span>
									</>
								}
								name='daylyPay.workingStartTime'
								autoComplete='off'
							/>
						</div>
						<div
							className='margin'
							style={{ width: '33%', marginRight: '3px' }}>
							<MySelectInput
								label={
									<>
										<span style={{ color: 'blue' }}>
											* 퇴근일(당일 또는 익일)
										</span>
									</>
								}
								placeholder='당일'
								name='daylyPay.workingEndTimeNextDayOrNot'
								options={dayType}
							/>
						</div>
						<div
							className='margin'
							style={{ width: '33%', marginRight: '3px' }}>
							<MyTimeInput
								label={
									<>
										<span style={{ color: 'blue' }}>* 퇴근시간</span>
									</>
								}
								name='daylyPay.workingEndTime'
								autoComplete='off'
							/>
						</div>
						<div
							className='margin'
							style={{ width: '24.5%', marginRight: '3px' }}>
							<MyNumberInput
								label={
									<>
										<span style={{ color: 'blue' }}>
											* 22시 이전 휴게시간 합계(분)
										</span>
									</>
								}
								name='daylyPay.dayBreakTimes'
								autoComplete='off'
							/>
						</div>
						<div
							className='margin'
							style={{ width: '24.5%', marginRight: '3px' }}>
							<MyNumberInput
								label={
									<>
										<span style={{ color: 'blue' }}>
											* 22시~익일06시 휴게시간 합계(분)
										</span>
									</>
								}
								name='daylyPay.nightBreakTimes'
								autoComplete='off'
							/>
						</div>
						<div
							className='margin'
							style={{ width: '24.5%', marginRight: '3px' }}>
							<MyTextInput
								label={
									<>
										<span style={{ color: 'blue' }}>
											* 휴게시작 및 종료(근로계약서 명기용)
										</span>
									</>
								}
								name='daylyPay.breakTimeContents'
								placeholder='12:00~13:00, 14:00~14:20, 23:00~ 02:00 등,'
								autoComplete='off'
							/>
						</div>
					</>
				)}
				{bizIncomeWorker && (
					<>
						<div
							style={{
								width: '100%',
								marginBottom: '30px',
								marginTop: '10px',
								fontSize: '14px',
							}}>
							* 사업소득자는 최소한 다음의 특징을 가지고 있기 때문에 근로시간 및
							정해진 임금이 없어 근로기준법상의 근로자로 인정되지 않고 근로자
							4대보험을 납부하지 않는 특성이 있습니다. <br />
							<br />
							<span style={{ color: 'blue', marginLeft: '30px' }}>
								1. 자율성:
							</span>{' '}
							사업소득자는 업무 수행에 있어 상당한 자율성을 가지고 있습니다.{' '}
							<span style={{ color: 'blue' }}>
								업무 시간, 장소, 방법 등을 스스로 결정할 수 있으며, 고용주로부터
								직접적인 지시나 감독을 받지 않습니다.
							</span>
							<br />
							<br />
							<span style={{ color: 'blue', marginLeft: '30px' }}>
								2. 위험부담:
							</span>{' '}
							사업소득자는 사업의 성패에 따른 경제적 위험을 스스로 부담합니다.{' '}
							<span style={{ color: 'blue' }}></span>수익이 발생하지 않으면
							소득이 없을 수 있으며, 비용도 스스로 부담합니다.
							<br />
							<br />
							<span style={{ color: 'blue', marginLeft: '30px' }}>
								3. 계약관계:
							</span>{' '}
							사업소득자는 주로{' '}
							<span style={{ color: 'blue' }}>
								용역 계약, 위임 계약, 도급 계약 등의 형태로 계약을 체결합니다.
							</span>{' '}
							이는 근로계약과는 다른 형태로, 법적으로는 독립적인 계약자로
							간주됩니다.
							<br />
							<br />
							<span style={{ color: 'blue', marginLeft: '30px' }}>
								4. 보수지급방식:
							</span>{' '}
							사업소득자는{' '}
							<span style={{ color: 'blue' }}>
								고정 급여 대신 작업 결과에 따라 대가를 받는 경우가 많습니다.
							</span>{' '}
							예를 들어, 프로젝트 단위로 대가를 받거나 성과에 따른 보수를
							받습니다.
						</div>
						<div
							style={{ width: '100%', marginBottom: '30px', fontSize: '14px' }}>
							* 위 특성에 부합하지 않는 직원임에도 불구하고 사업소득자 계약서를
							용역, 위임, 도급 계약서를 작성 했는데, 이후 업무시간과 장소와
							임금을 정하고, 업무지시 등으로 구속하여는 경우{' '}
							<span style={{ color: 'blue' }}>
								주휴수당, 연차휴가, 퇴직금, 4대보험료 소급 납부 등의 문제
							</span>
							가 발생할 수 있으니 주의하시기 바랍니다. <br />
							<br />
							<span style={{ color: 'red' }}>
								노무법인 최상인업은 이와 관련하여 발생하는 문제에 대한 책임을
								지지 않습니다.
							</span>
						</div>
						<div
							style={{
								width: '100%',
								marginBottom: '30px',
								marginTop: '10px',
								fontSize: '14px',
							}}>
							* 실제 근로자성을 진단해서 사업소득자로 운영하는 제도 설계를
							원하시는 경우, 노무법인 최상인업의 노무법률 전문가에게 문의하시기
							바랍니다.
						</div>
					</>
				)}
			</div>
			<hr />
			{(dayWorker || timeWorker) && (
				<>
					<div
						style={{
							display: 'flex',
							flexWrap: 'wrap',
							justifyContent: 'flex-start',
							alignItems: 'center',
							alignContent: 'flex-end',
						}}>
						<div
							className='margin'
							style={{ width: '33%', marginRight: '3px' }}>
							<MySelectInput
								label={
									<>
										<span style={{ color: 'blue' }}>* 건설업 유무</span>
									</>
								}
								placeholder='건설업은 8일이상 출근시 건강보험 부과, 나머지는 월근무시간 60시간 이상시 건강보험 부과'
								name='daylyPay.constructOrNot'
								options={construct}
							/>
						</div>
						<div
							className='margin'
							style={{ width: '33%', marginRight: '3px' }}>
							<MySelectInput
								label={
									<>
										<span style={{ color: 'blue' }}>* 소득세 선택</span>
									</>
								}
								placeholder='세법상 일용직근로자는 3개월 미만 고용된 근로자입니다. (건설업은 1개월 미만)'
								name='daylyPay.dayWorkerTax'
								options={dayWorkerTaxOrNot}
							/>
						</div>
						<div
							className='margin'
							style={{ width: '33%', marginRight: '3px' }}>
							<MyTextInput
								label='* 매월 지급하는 경우 임금지급일'
								name='daylyPay.payDay'
								placeholder='예)10일'
								autoComplete='off'
							/>
						</div>
					</div>
					{values?.daylyPay?.dayWorkerTax === '일용근로자세금' ? (
						<label style={{ color: 'red' }}>
							* 세법상 3개월(건설업은 1년)이상 계속근무(1달에 4~5일이상
							3달간(건설업은 1년) 연속근무-대법원 2006.04.28. 선고,
							2004다66995-67004 판결)하는 경우 정규직 세금으로 계산해야 직원과
							회사에 불이익이 없으니 반드시 참고하세요.
						</label>
					) : null}
					<hr />
					{/* 세금 및 4대보험 정보 */}
					<CrwfWorkerBasicInfoTax4In
						dayWorker={true}
						openTax4In={openTax4In}
						setOpenTax4In={setOpenTax4In}
						openPensionPayType={openPensionPayType}
						setOpenPensionPayType={setOpenPensionPayType}
						openHealthCarePayType={openHealthCarePayType}
						setOpenHealthCarePayType={setOpenHealthCarePayType}
						openNoJobPayType={openNoJobPayType}
						setOpenNoJobPayType={setOpenNoJobPayType}
						openOldCarePayType={openOldCarePayType}
						setOpenOldCarePayType={setOpenOldCarePayType}
					/>
				</>
			)}
		</div>
	);
}

export default CrwfDayWorkerTimeAndWageInfo;
