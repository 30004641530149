import React, { useEffect } from 'react';
import { Icon, Table } from 'semantic-ui-react';
import {
	selectkDates,
	typeOfAbsentTime,
} from '../../../../app/common/util/util';

function ClientWageTableTop3TimeExplain({
	values,
	worker,
	centerStyle,
	payPaperAddMoneyYTOpen,
	centerStyleBlue,
	underInOut5daysWorker,
	centerStyleInOutBack,
	perDayMealTaxFree,
	centerStyleTeal,
	isRetireMonth,
	handelSelectInputChange,
	handelAbsentTimeChange,
	handelAddAbsentTime,
	handelRemoveAbsentTime,
	handelAnnualLeavesChange,
	handelAddAnnualLeaves,
	handelRemoveAnnualLeaves,
	setValues,
	divideAnnualLeavesDay,
}) {
	// 추가: values.absentTimes가 변경되면 absentDates 초기화
	useEffect(() => {
		setValues((prevValues) => ({
			...prevValues,
			absentDates: [],
		}));
	}, [values.absentDays]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		setValues((prevValues) => ({
			...prevValues,
			absentPaidHoliDates: [],
		}));
	}, [values.absentPaidHoliDays]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (divideAnnualLeavesDay && values.usedAnnualLeaves) {
			setValues((prevValues) => ({
				...prevValues,
				usedAnnualLeaveDates:
					values.usedAnnualLeaveDates.length === 0
						? [{ date: '', content: '' }]
						: prevValues.usedAnnualLeaveDates,
			}));
		}
	}, [divideAnnualLeavesDay, values.usedAnnualLeaves]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		setValues((prevValues) => ({
			...prevValues,
			absentTimeDates: [{ date: '', type: '', content: '' }],
		}));
	}, [values.absentTimes]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<Table.Row>
				{values.absentDays || values.absentDates.length > 0 ? (
					<>
						<Table.Cell
							textAlign='center'
							className='gubun'
							style={centerStyle}>
							<h3>결근 일자</h3>
							<select
								id='absentDates'
								name='absentDates'
								placeholder='결근일자를 선택하세요'
								multiple
								value={values.absentDates} // 배열로 바인딩
								onChange={(e) => handelSelectInputChange(e)}
								style={{ width: '100%', height: '150px' }} // 스크롤 가능 영역 제공
							>
								{selectkDates.map((date) => (
									<option key={date} value={date}>
										{date}
									</option>
								))}
							</select>
							<div>
								{Array.isArray(values.absentDates) &&
								values.absentDates.length > 0
									? values?.absentDates?.join(', ')
									: '없음'}
							</div>
						</Table.Cell>
					</>
				) : null}
				{values.absentPaidHoliDays || values.absentPaidHoliDates.length > 0 ? (
					<>
						<Table.Cell
							textAlign='center'
							className='gubun'
							style={centerStyle}>
							<h3>주휴공제 일자</h3>
							<select
								id='absentPaidHoliDates'
								name='absentPaidHoliDates'
								placeholder='결근일자를 선택하세요'
								multiple
								value={values.absentPaidHoliDates} // 배열로 바인딩
								onChange={(e) => handelSelectInputChange(e)}
								style={{ width: '100%', height: '150px' }} // 스크롤 가능 영역 제공
							>
								{selectkDates.map((date) => (
									<option key={date} value={date}>
										{date}
									</option>
								))}
							</select>
							<div>
								{Array.isArray(values.absentPaidHoliDates) &&
								values.absentPaidHoliDates.length > 0
									? values?.absentPaidHoliDates?.join(', ')
									: '없음'}
							</div>
						</Table.Cell>
					</>
				) : null}

				{values.absentTimes ||
				(Array.isArray(values.absentTimeDates) &&
					values.absentTimeDates.length > 1) ? (
					<>
						<Table.Cell
							textAlign='center'
							colSpan='2'
							className='gubun'
							style={centerStyle}>
							<h3>공제시간</h3>
							{Array.isArray(values.absentTimeDates) &&
								values.absentTimeDates.map((entry, index) => (
									<div key={index} style={{ marginBottom: '10px' }}>
										<select
											name={`absentDate-${index}`}
											value={entry.date}
											onChange={(e) =>
												handelAbsentTimeChange(index, 'date', e.target.value)
											}
											style={{ width: '19%', marginRight: '10px' }}>
											<option value=''>날짜</option>
											{selectkDates.map((date, i) => (
												<option key={`${date}-${i}`} value={date}>
													{date}
												</option>
											))}
										</select>
										<select
											name={`absentType-${index}`}
											value={entry.type}
											onChange={(e) =>
												handelAbsentTimeChange(index, 'type', e.target.value)
											}
											style={{ width: '22%', marginRight: '10px' }}>
											<option value=''>종류</option>
											{typeOfAbsentTime.map((type, i) => (
												<option key={`${type}-${i}`} value={type}>
													{type}
												</option>
											))}
										</select>
										<input
											type='number'
											name={`absentContent-${index}`}
											placeholder='0.5'
											value={entry.content}
											onChange={(e) =>
												handelAbsentTimeChange(index, 'content', e.target.value)
											}
											style={{ width: '15%' }}
										/>
										시간
										<button
											onClick={() => handelRemoveAbsentTime(index)}
											style={{
												marginLeft: '10px',
												padding: '5px 10px',
												backgroundColor: 'red',
												color: '#fff',
												border: 'none',
												borderRadius: '5px',
												cursor: 'pointer',
											}}>
											삭제
										</button>
									</div>
								))}
							<button
								onClick={(e) => {
									e.preventDefault(); // 새로고침 방지
									handelAddAbsentTime(e);
								}}
								style={{
									marginTop: '10px',
									padding: '5px 10px',
									backgroundColor: '#007bff',
									color: '#fff',
									border: 'none',
									borderRadius: '5px',
									cursor: 'pointer',
								}}>
								항목 추가
							</button>
						</Table.Cell>
					</>
				) : null}

				{values.usedAnnualLeaves ||
				(Array.isArray(values.usedAnnualLeaveDates) &&
					values.usedAnnualLeaveDates.length > 1) ? (
					<>
						<Table.Cell
							textAlign='center'
							colSpan='2'
							className='gubun'
							style={centerStyle}>
							<h3>사용연차 일자</h3>
							{divideAnnualLeavesDay ? (
								<>
									{Array.isArray(values.usedAnnualLeaveDates) &&
										values.usedAnnualLeaveDates.map((entry, index) => (
											<div key={index} style={{ marginBottom: '10px' }}>
												<select
													name={`annualLeaveDate-${index}`}
													value={entry.date}
													onChange={(e) =>
														handelAnnualLeavesChange(
															index,
															'date',
															e.target.value
														)
													}
													style={{ width: '19%', marginRight: '10px' }}>
													<option value=''>날짜</option>
													{selectkDates.map((date, i) => (
														<option key={`${date}-${i}`} value={date}>
															{date}
														</option>
													))}
												</select>
												<input
													type='number'
													name={`annualLeaveContent-${index}`}
													placeholder='연차는 1, 반차는 0.5'
													value={entry.content}
													onChange={(e) =>
														handelAnnualLeavesChange(
															index,
															'content',
															e.target.value
														)
													}
													style={{ width: '35%' }}
													max='1' // 최대값 설정
													min='0' // 최소값 설정
													step='0.25' // 0.1 단위로 입력 제한
												/>
												일
												<button
													onClick={() => handelRemoveAnnualLeaves(index)}
													style={{
														marginLeft: '10px',
														padding: '5px 10px',
														backgroundColor: 'red',
														color: '#fff',
														border: 'none',
														borderRadius: '5px',
														cursor: 'pointer',
													}}>
													삭제
												</button>
											</div>
										))}
									<button
										onClick={(e) => {
											e.preventDefault(); // 새로고침 방지
											handelAddAnnualLeaves(e);
										}}
										style={{
											marginTop: '10px',
											padding: '5px 10px',
											backgroundColor: '#007bff',
											color: '#fff',
											border: 'none',
											borderRadius: '5px',
											cursor: 'pointer',
										}}>
										항목 추가
									</button>
								</>
							) : (
								<>
									<select
										id='usedAnnualLeaveDates'
										name='usedAnnualLeaveDates'
										placeholder='사용연차일자를 선택하세요'
										multiple
										value={values.usedAnnualLeaveDates || []}
										onChange={(e) => handelSelectInputChange(e)}
										style={{ width: '100%', height: '150px' }} // 스크롤 가능 영역 제공
									>
										{selectkDates.map((date) => (
											<option key={date} value={date}>
												{date}
											</option>
										))}
									</select>
									<div>
										{Array.isArray(values.usedAnnualLeaveDates) &&
										values.usedAnnualLeaveDates.length > 0
											? values?.usedAnnualLeaveDates?.join(', ')
											: '없음'}
									</div>
								</>
							)}
						</Table.Cell>
					</>
				) : null}
			</Table.Row>
			<Table.Row>
				<Table.Cell textAlign='left' colSpan='9'>
					<span
						style={{
							fontSize: '1.2rem',
							color: 'darkcyan',
							fontWeight: 'bold',
						}}>{`3-1. ${worker}의 추가 지급금액 내역`}</span>
					<span
						style={{
							position: 'absolute',
							right: 0,
							marginRight: '30px',
							cursor: 'pointer',
						}}>
						<span
							style={{ color: 'red' }}
							onClick={() => payPaperAddMoneyYTOpen()}>
							<Icon size='big' name='youtube' />
							상여금, 연차수당 등 설명
						</span>
					</span>
				</Table.Cell>
			</Table.Row>
			<Table.Row>
				<Table.Cell textAlign='left' colSpan='9' style={{ color: 'blue' }}>
					* 상여금, 년간 미사용 연차수당은 퇴직금 등 평균임금 계산시 3/12로
					계산되고,{' '}
					<span style={{ color: 'teal' }}>
						{' '}
						근로제공과 관련없는 특별 상여금, 퇴직 미사용 연차수당(퇴직월에
						표시됨.)은 평균임금에서 제외됩니다.
					</span>
				</Table.Cell>
			</Table.Row>
			<Table.Row>
				<Table.Cell
					textAlign='center'
					className='gubun'
					style={centerStyleBlue}>
					상여금
				</Table.Cell>
				{/* <Table.Cell textAlign='center' className='gubun' style={centerStyleBlue}>부정기상여금</Table.Cell> */}
				<Table.Cell
					textAlign='center'
					className='gubun'
					style={centerStyleBlue}>
					지급 <br /> 연차 <span style={{ color: 'red' }}>(일수)</span>
				</Table.Cell>
				<Table.Cell textAlign='center' className='gubun' style={centerStyle}>
					개인실적 <br /> 인센티브
				</Table.Cell>
				{underInOut5daysWorker ? (
					<Table.Cell
						textAlign='center'
						className='gubun'
						style={centerStyleInOutBack}>
						지급 조정
						<br />
						(일할 보정)
						<br />
						(과오납 등)
					</Table.Cell>
				) : (
					<Table.Cell textAlign='center' className='gubun' style={centerStyle}>
						지급 조정
						<br />
						(과오납 등)
					</Table.Cell>
				)}
				<Table.Cell textAlign='center'>
					{perDayMealTaxFree ? (
						<div>
							식대 지급
							<br />
							출근일수
						</div>
					) : (
						''
					)}
				</Table.Cell>
				<Table.Cell textAlign='center'>
					전년 미사용 <br /> 연차 <span style={{ color: 'red' }}>(일수)</span>
				</Table.Cell>
				<Table.Cell textAlign='center'></Table.Cell>
				<Table.Cell
					textAlign='center'
					className='gubun'
					style={centerStyleTeal}>
					기타금품 <br /> 특별상여금
				</Table.Cell>
				{isRetireMonth ? (
					<Table.Cell
						textAlign='center'
						className='gubun'
						style={centerStyleTeal}>
						퇴직 미사용<br></br>연차{' '}
						<span style={{ color: 'red' }}>(일수)</span>
					</Table.Cell>
				) : (
					<Table.Cell textAlign='center'></Table.Cell>
				)}
			</Table.Row>
		</>
	);
}

export default ClientWageTableTop3TimeExplain;
