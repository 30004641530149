import React from 'react';

export default function WorkerFormWageComments({ MyTextArea }) {
	return (
		<div
			style={{
				display: 'flex',
				flexWrap: 'wrap',
				justifyContent: 'flex-start',
				alignItems: 'center',
				alignContent: 'flex-end',
			}}>
			<div className='margin' style={{ width: '99.5%', marginRight: '3px' }}>
				<MyTextArea
					multiple
					label='(7). 임금 계약서 특약 사항'
					placeholder='임금계약서 제일 하단에 표시됩니다.'
					name='wageComment'
					rows='3'
				/>
			</div>
		</div>
	);
}
