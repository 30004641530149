import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import { collection, addDoc, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../../app/config/firebase';

function AnnualLeavesInputDataDashboard() {
	const [successList, setSuccessList] = useState([]);
	const [failList, setFailList] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	// 엑셀 날짜 숫자를 YYYY-MM-DD 형식으로 변환하는 함수
	const excelDateToJSDate = (serial) => {
		const excelEpoch = new Date(1900, 0, 1); // 1900-01-01
		const daysOffset = serial - 1; // Excel 날짜 시스템 보정
		const resultDate = new Date(
			excelEpoch.getTime() + daysOffset * 24 * 60 * 60 * 1000
		);
		return resultDate.toISOString().split('T')[0]; // YYYY-MM-DD 포맷으로 반환
	};

	const handleFileUpload = async (e) => {
		const file = e.target.files[0];
		const reader = new FileReader();

		reader.onload = async (event) => {
			const data = new Uint8Array(event.target.result);
			const workbook = XLSX.read(data, { type: 'array' });
			const sheetName = workbook.SheetNames[1]; // "휴가내역" 시트 가져오기
			const sheet = workbook.Sheets[sheetName];

			// 첫 번째 행을 무시하고 두 번째 행을 헤더로 강제 설정
			const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
			const headers = [
				'소속',
				'이름',
				'시작일',
				'종료일',
				'사용 휴가일수',
				'제목',
				'내용',
			]; // 열 이름 강제 지정
			const rows = jsonData.slice(2); // 데이터 행만 추출

			const success = [];
			const failed = [];
			setIsLoading(true);

			for (const row of rows) {
				const rowData = {};
				headers.forEach((key, index) => (rowData[key] = row[index]));

				// 시작일과 종료일을 날짜 형식으로 변환
				const employeeName = rowData['이름'];
				const startDate = rowData['시작일']
					? excelDateToJSDate(rowData['시작일'])
					: null;
				const endDate = rowData['종료일']
					? excelDateToJSDate(rowData['종료일'])
					: null;
				const usedDays = parseFloat(rowData['사용 휴가일수']);
				const title = rowData['제목'];
				const content = rowData['내용'];

				if (!employeeName || !startDate || !endDate) {
					failed.push(employeeName || '알 수 없는 이름');
					continue;
				}

				const parsedLeaveData = parseAnnualLeave(
					startDate,
					endDate,
					usedDays,
					title,
					content
				);
				const employeeData = await getEmployeeData(employeeName);

				if (employeeData) {
					try {
						const uploadData = {
							companyName: employeeData?.companyName,
							companyCode: employeeData?.companyCode,
							workerCode: employeeData?.workerCode,
							workerName: employeeName,
							annualLeavesData: parsedLeaveData,
						};

						await addDoc(collection(db, 'annualLeaves'), uploadData);
						success.push(employeeName);
					} catch (error) {
						console.error('Firestore 업로드 실패:', employeeName, error);
						failed.push(employeeName);
					}
				} else {
					failed.push(employeeName);
				}
			}

			setSuccessList(success);
			setFailList(failed);
			setIsLoading(false);
			alert('업로드 완료!');
		};

		reader.readAsArrayBuffer(file);
	};

	// 연차 데이터 변환 함수
	const parseAnnualLeave = (startDate, endDate, usedDays, title, content) => {
		const start = new Date(startDate);
		const end = new Date(endDate);
		const result = [];

		// 대한민국 국공휴일 배열 (YYYY-MM-DD 형식)
		const koreanHolidays2024 = [
			'2024-01-01', // 신정
			'2024-02-09',
			'2024-02-10',
			'2024-02-11',
			'2024-02-12', // 설날 연휴
			'2024-03-01', // 삼일절
			'2024-05-05',
			'2024-05-06', // 어린이날 및 대체공휴일
			'2024-06-06', // 현충일
			'2024-08-15', // 광복절
			'2024-09-16',
			'2024-09-17',
			'2024-09-18', // 추석 연휴
			'2024-10-03', // 개천절
			'2024-10-09', // 한글날
			'2024-12-25', // 성탄절
		];

		// 2024년 전체 연차 비워두기
		if (
			start.getTime() === new Date('2024-01-01').getTime() &&
			end.getTime() === new Date('2024-12-31').getTime()
		) {
			return [];
		}

		// 시작일과 종료일이 같은 경우
		if (start.getTime() === end.getTime()) {
			if (!isHolidayOrWeekend(start, koreanHolidays2024)) {
				result.push({
					Date: startDate.split(' ')[0],
					used: usedDays === 0 ? 0 : usedDays, // usedDays가 0인 경우 0으로 설정
					title: title || '',
					content: content || '',
				});
			}
		} else {
			let currentDate = new Date(start);
			while (currentDate <= end) {
				// 주말이나 공휴일이 아닌 경우에만 추가
				if (!isHolidayOrWeekend(currentDate, koreanHolidays2024)) {
					result.push({
						Date: currentDate.toISOString().split('T')[0],
						used: usedDays === 0 ? 0 : -1, // usedDays가 0인 경우 0으로 설정
						title: title || '',
						content: content || '',
					});
				}
				currentDate.setDate(currentDate.getDate() + 1);
			}
		}

		return result;
	};

	// 주말 또는 공휴일 여부 확인 함수
	const isHolidayOrWeekend = (date, holidays) => {
		const day = date.getDay(); // 0: 일요일, 6: 토요일
		const dateString = date.toISOString().split('T')[0]; // YYYY-MM-DD 형식
		return day === 0 || day === 6 || holidays.includes(dateString);
	};

	async function getEmployeeData(employeeName) {
		if (!employeeName) return null;

		const targetCompany = [
			'피엠지인티그레이티드커뮤니케이션즈(주)',
			'(주)무빙컨텐츠솔루션',
		];

		try {
			const employeesRef = collection(db, 'workers');
			const q = query(employeesRef, where('worker.name', '==', employeeName));
			const querySnapshot = await getDocs(q);

			if (!querySnapshot.empty) {
				// 모든 동명이인 목록 가져오기
				const employees = querySnapshot.docs.map((doc) => ({
					companyCode: doc.data()?.hostUid,
					workerCode: doc.id,
					companyName: doc.data()?.companyName,
					workerName: employeeName,
				}));

				// targetCompany에 있는 사람 필터링
				const filteredEmployees = employees.filter((emp) =>
					targetCompany.includes(emp.companyName)
				);

				// targetCompany에 있는 사람이 있으면 해당 데이터 반환, 없으면 첫 번째 동명이인 반환
				return filteredEmployees.length > 0
					? filteredEmployees[0]
					: employees[0];
			} else {
				return null;
			}
		} catch (error) {
			console.error('Firestore 조회 오류:', error);
			return null;
		}
	}

	return (
		<div>
			<h1>Annual Leaves 업로드</h1>
			<input type='file' accept='.xlsx, .xls' onChange={handleFileUpload} />
			{isLoading && <p>업로드 중입니다...</p>}
			<div>
				<h2>업로드 성공한 직원명</h2>
				<ul>
					{successList.map((name, index) => (
						<li key={index}>{name}</li>
					))}
				</ul>
				<h2>업로드 실패한 직원명</h2>
				<ul>
					{failList.map((name, index) => (
						<li key={index}>{name}</li>
					))}
				</ul>
			</div>
		</div>
	);
}

export default AnnualLeavesInputDataDashboard;
