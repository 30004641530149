import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import ClientDash2022NewLaw from './ClientDash2022NewLaw';
import ClientDash2023NewLaw from './ClientDash2023NewLaw';
import ClientDash2024NewLaw from './ClientDash2024NewLaw';
import ClientDash2025NewLaw from './ClientDash2025NewLaw';

import useScrollRestoration from '../../../../app/hooks/useScrollRestoration';
import { Button } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export default function ClientReqDashboard4InNewYearLawInfo() {
	const { saveScrollPosition, restoreScrollPosition } = useScrollRestoration();

	const { currentUserProfile } = useSelector((state) => state.profile);

	const [openWhatsNew2022, setOpenWhatsNew2022] = useState(false);
	const [openWhatsNew2023, setOpenWhatsNew2023] = useState(false);
	const [openWhatsNew2024, setOpenWhatsNew2024] = useState(false);
	const [openWhatsNew2025, setOpenWhatsNew2025] = useState(true);

	const isWorker = currentUserProfile?.isWorker;

	useEffect(() => {
		restoreScrollPosition();

		return () => {
			saveScrollPosition();
		};
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const history = useHistory();
	async function goBack() {
		history.goBack();
	}

	return (
		<>
			{isWorker ? null : (
				<>
					<Button color='blue' onClick={() => history.push('/clientReqs4In')}>
						메인페이지
					</Button>
					<Button color='facebook' onClick={() => goBack()}>
						이전페이지
					</Button>
					<br />
					<br />
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							alignContent: 'center',
						}}
						onClick={() => setOpenWhatsNew2025(!openWhatsNew2025)}>
						<span
							style={{
								fontSize: '23px',
								color: 'darkBlue',
								cursor: 'pointer',
							}}>
							2025년 <span style={{ color: 'red' }}> 변경 노동법 안내</span>
						</span>
					</div>
					{openWhatsNew2022 && (
						<ClientDash2022NewLaw
							openWhatsNew2022={openWhatsNew2022}
							setOpenWhatsNew2022={setOpenWhatsNew2022}
						/>
					)}
					{openWhatsNew2023 && (
						<ClientDash2023NewLaw
							openWhatsNew2023={openWhatsNew2023}
							setOpenWhatsNew2023={setOpenWhatsNew2023}
						/>
					)}
					{openWhatsNew2024 && (
						<ClientDash2024NewLaw
							openWhatsNew2024={openWhatsNew2024}
							setOpenWhatsNew2024={setOpenWhatsNew2024}
						/>
					)}
					{openWhatsNew2025 && (
						<ClientDash2025NewLaw
							openWhatsNew2025={openWhatsNew2025}
							setOpenWhatsNew2025={setOpenWhatsNew2025}
						/>
					)}
				</>
			)}
		</>
	);
}
