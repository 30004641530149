import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'semantic-ui-react';
import {
	calTaxF,
	commaStringToNumber,
	getAgeFromSocialNumberEnteredDate,
	getAgeFromSocialNumberPension,
	healthCare,
	healthCareTaxWageSumF,
	healthCareText,
	isThreeMonthsOrMore,
	noJobInsurance,
	noJobTaxWageSumF,
	noJobText,
	numberToCommaString,
	numStringToNumber,
	oldCare,
	oldCareTaxWageSumF,
	oldCareText,
	pension,
	pensionTaxWageSumF,
	pensionText,
	taxFreeCarWageLimitF,
	taxFreeChildWageLimitF,
	taxFreeMealWageLimitF,
	taxFreeStudyWageLimitF,
} from '../../../../../../../app/common/util/util';
import { decrypto } from '../../../../../../../app/firestore/firestoreService';

export default function ClientReqDLCWageTableDeductDetail({
	retiredMonthYes,
	plusMinus4InsComment,
	plusMinus4Ins,
	contractEndDate,
	workerEnteredDate,
	values,
	initialTax,
	cAWageTF1,
	cAWageTF2,
	cAWageTF3,
	cAWageTF4,
	userFixedCAWageTF1,
	userFixedCAWageTF2,
	userFixedCAWageTF3,
	userFixedCAWageTF4,

	isInterInWorker,
	thisYear,
	thisMonth,
	inputWageStyle,
	inputCommentStyle,
	totalMonthWageAmount,
	numMealWage,
	numCarWage,
	numChildWage,
	numStudyWage,
	numTFEtcWage,
	deductEtcName1,
	deductEtcAWage1,
	deductEtcWage1Comment,
	deductEtcName2,
	deductEtcAWage2,
	deductEtcWage2Comment,
	deductEtcName3,
	deductEtcAWage3,
	deductEtcWage3Comment,
	handelInputChange,

	userFixedTax,
	userFixedRegionTax,
	userFixedHealthCare,
	userFixedOldCare,
	userFixedPension,
	userFixedNoJobInsurance,

	inputTax,

	plusDeductHealthCare,
	plusDeductOldCare,
	plusDeductWorkTax,
	plusDeductWorkRegionTax,
	plusDeductDuru,
	plusDeductWorkerCompanyLoan,

	plusDeductHealthCareComment,
	plusDeductOldCareComment,
	plusDeductWorkTaxComment,
	plusDeductWorkRegionTaxComment,
	plusDeductDuruComment,
	plusDeductWorkerCompanyLoanComment,
	setValueTaxWageSum,
}) {
	const { selectedClientReq4In } = useSelector(
		(state) => state.clientReq4InStore
	);
	const workerSocialNumberFront = decrypto(
		selectedClientReq4In.workerSocialNumberFront,
		String(process.env.CRYPTO_KEY)
	);

	const netAgePension = getAgeFromSocialNumberPension(
		workerSocialNumberFront,
		values.thisYear,
		values.thisMonth
	);
	const netAgeEntered = getAgeFromSocialNumberEnteredDate(
		workerSocialNumberFront,
		workerEnteredDate
	);
	const over3MonthsWorker = isThreeMonthsOrMore(
		workerEnteredDate,
		contractEndDate
	);

	// const healthInsureJudge = healthInsureOver60;

	const taxPayType = selectedClientReq4In?.taxPayType;
	const pensionPayType = selectedClientReq4In?.pensionPayType;
	const healthCarePayType = selectedClientReq4In?.healthCarePayType;
	const oldCarePayType = selectedClientReq4In?.oldCarePayType;

	const noJobPayType = selectedClientReq4In?.noJobPayType;
	const weekLBTime = selectedClientReq4In?.lawBase?.weekLBTime;

	const pensionBaseWage = selectedClientReq4In?.pensionBaseWage
		? numStringToNumber(selectedClientReq4In?.pensionBaseWage)
		: null;
	const healthCareBaseWage = selectedClientReq4In?.healthCareBaseWage
		? numStringToNumber(selectedClientReq4In?.healthCareBaseWage)
		: null;
	const oldCareBaseWage = selectedClientReq4In?.oldCareBaseWage
		? numStringToNumber(selectedClientReq4In?.oldCareBaseWage)
		: null;
	const noJobBaseWage = selectedClientReq4In?.noJobBaseWage
		? numStringToNumber(selectedClientReq4In?.noJobBaseWage)
		: null;

	const calTaxRate =
		taxPayType === '120%' ? 1.2 : taxPayType === '80%' ? 0.8 : 1;
	const numOfTaxFamily = selectedClientReq4In.numOfTaxFamily
		? +selectedClientReq4In.numOfTaxFamily
		: 0;
	const numOfTaxFamily7To20 = selectedClientReq4In.numOfTaxFamily7To20
		? +selectedClientReq4In.numOfTaxFamily7To20
		: 0;

	const perDayMealTaxFree = values?.perDayMealTaxFree || 0;
	const plusIrregularDayMealTFDays = values.plusIrregularDayMealTFDays || 0;
	const plusIrregularDayMealTFWage =
		perDayMealTaxFree * plusIrregularDayMealTFDays || 0;

	const numMealWageJ = numMealWage ? numMealWage : 0;
	const maxTFnumMealWage =
		numMealWage || plusIrregularDayMealTFWage
			? numMealWageJ + +plusIrregularDayMealTFWage >=
			  taxFreeMealWageLimitF(thisYear)
				? taxFreeMealWageLimitF(thisYear)
				: numMealWageJ + +plusIrregularDayMealTFWage
			: 0;

	const maxTFnumCarWage = numCarWage
		? numCarWage >= taxFreeCarWageLimitF(thisYear)
			? taxFreeCarWageLimitF(thisYear)
			: numCarWage
		: 0;
	const maxTFnumChildWage = numChildWage
		? numChildWage >= taxFreeChildWageLimitF(thisYear)
			? taxFreeChildWageLimitF(thisYear)
			: numChildWage
		: 0;
	const maxTFnumStudyWage = numStudyWage
		? numStudyWage >= taxFreeStudyWageLimitF(thisYear)
			? taxFreeStudyWageLimitF(thisYear)
			: numStudyWage
		: 0;
	const maxTFnumTFEtcWage = numTFEtcWage ? numTFEtcWage : 0;

	const numCAWageTF1 =
		userFixedCAWageTF1 && cAWageTF1
			? 0
			: cAWageTF1
			? cAWageTF1 < 1
				? +cAWageTF1
				: commaStringToNumber(cAWageTF1)
			: 0;
	const numCAWageTF2 =
		userFixedCAWageTF2 && cAWageTF2
			? 0
			: cAWageTF2
			? cAWageTF2 < 1
				? +cAWageTF2
				: commaStringToNumber(cAWageTF2)
			: 0;
	const numCAWageTF3 =
		userFixedCAWageTF3 && cAWageTF3
			? 0
			: cAWageTF3
			? cAWageTF3 < 1
				? +cAWageTF3
				: commaStringToNumber(cAWageTF3)
			: 0;
	const numCAWageTF4 =
		userFixedCAWageTF4 && cAWageTF4
			? 0
			: cAWageTF4
			? cAWageTF4 < 1
				? +cAWageTF4
				: commaStringToNumber(cAWageTF4)
			: 0;

	const numUserFixedCAWageTF1 =
		userFixedCAWageTF1 === 0
			? 0
			: userFixedCAWageTF1
			? numStringToNumber(userFixedCAWageTF1)
			: numCAWageTF1;
	const numUserFixedCAWageTF2 =
		userFixedCAWageTF2 === 0
			? 0
			: userFixedCAWageTF2
			? numStringToNumber(userFixedCAWageTF2)
			: numCAWageTF2;
	const numUserFixedCAWageTF3 =
		userFixedCAWageTF3 === 0
			? 0
			: userFixedCAWageTF3
			? numStringToNumber(userFixedCAWageTF3)
			: numCAWageTF3;
	const numUserFixedCAWageTF4 =
		userFixedCAWageTF4 === 0
			? 0
			: userFixedCAWageTF4
			? numStringToNumber(userFixedCAWageTF4)
			: numCAWageTF4;

	const taxFreeWageSum =
		maxTFnumMealWage +
		maxTFnumCarWage +
		maxTFnumChildWage +
		maxTFnumStudyWage +
		maxTFnumTFEtcWage +
		(numUserFixedCAWageTF1 ? numUserFixedCAWageTF1 : 0) +
		(numUserFixedCAWageTF2 ? numUserFixedCAWageTF2 : 0) +
		(numUserFixedCAWageTF3 ? numUserFixedCAWageTF3 : 0) +
		(numUserFixedCAWageTF4 ? numUserFixedCAWageTF4 : 0);

	// // 중도입사공제(비과세 일할계산)
	// const daysOfInterInAbusentTaxFreeWage = daysOfInterInAbusent
	// 	? roundDown((taxFreeWageSum / daysInMonth) * daysOfInterInAbusent)
	// 	: 0;

	// // 중도퇴사공제(비과세 일할계산)
	// const daysOfInterOutAbusentTaxFreeWage = daysOfInterOutAbusent
	// 	? roundDown((taxFreeWageSum / daysInMonth) * daysOfInterOutAbusent)
	// 	: 0;

	// // 결근에 따른 비과세 공제금액
	// const daysOfAbusentTaxFreeWage = values?.absentDays
	// 	? roundDown((taxFreeWageSum / daysInMonth) * values?.absentDays)
	// 	: 0;

	// // 주휴공제에 따른 비과세 공제금액
	// const daysOfAbusentPaidHoliTaxFreeWage = values?.absentPaidHoliDays
	// 	? roundDown((taxFreeWageSum / daysInMonth) * values?.absentPaidHoliDays)
	// 	: 0;

	const taxWageSum = totalMonthWageAmount - taxFreeWageSum;

	useEffect(() => {
		setValueTaxWageSum(taxWageSum);
	}, [taxWageSum, setValueTaxWageSum]);

	const visaType = selectedClientReq4In?.foreigner?.visaType
		? selectedClientReq4In?.foreigner?.visaType
		: '';
	const selfNoJobPay = selectedClientReq4In?.foreigner?.selfNoJobPay
		? selectedClientReq4In?.foreigner?.selfNoJobPay
		: '';

	const endless65EmploymentInsurance =
		selectedClientReq4In?.endless65EmploymentInsurance
			? selectedClientReq4In?.endless65EmploymentInsurance
			: '';
	const pensionLimitWage2023 = 2200000;
	const pensionTaxWageSum = pensionTaxWageSumF(
		pensionLimitWage2023,
		pensionPayType,
		taxWageSum,
		pensionBaseWage,
		netAgePension,
		weekLBTime,
		isInterInWorker,
		visaType,
		workerEnteredDate
	);
	const healthCareTaxWageSum = healthCareTaxWageSumF(
		healthCarePayType,
		taxWageSum,
		healthCareBaseWage,
		weekLBTime,
		isInterInWorker
	);

	const oldCareTaxWageSum = oldCareTaxWageSumF(
		oldCarePayType,
		taxWageSum,
		oldCareBaseWage,
		weekLBTime,
		isInterInWorker
	);

	const noJobTaxWageSum = noJobTaxWageSumF(
		noJobPayType,
		taxWageSum,
		noJobBaseWage,
		netAgeEntered,
		weekLBTime,
		over3MonthsWorker,
		visaType,
		selfNoJobPay,
		isInterInWorker,
		endless65EmploymentInsurance
	);
	const numPlusDeductHealthCare = plusDeductHealthCare
		? numStringToNumber(plusDeductHealthCare)
		: 0;
	const numPlusDeductOldCare = plusDeductOldCare
		? numStringToNumber(plusDeductOldCare)
		: 0;
	const numPlusDeductWorkTax = plusDeductWorkTax
		? numStringToNumber(plusDeductWorkTax)
		: 0;
	const numPlusDeductWorkRegionTax = plusDeductWorkRegionTax
		? numStringToNumber(plusDeductWorkRegionTax)
		: 0;
	const numPlusDeductDuru = plusDeductDuru
		? numStringToNumber(plusDeductDuru)
		: 0;
	const numPlusMinus4Ins = plusMinus4Ins ? numStringToNumber(plusMinus4Ins) : 0;
	const numPlusDeductWorkerCompanyLoan = plusDeductWorkerCompanyLoan
		? numStringToNumber(plusDeductWorkerCompanyLoan)
		: 0;
	const numFinalRetireHealthCareRecalFee = numStringToNumber(
		values.finalRetireHealthCareRecalFee
			? values.finalRetireHealthCareRecalFee
			: 0
	);
	const numFinalRetireOldCareRecalFee = numStringToNumber(
		values.finalRetireOldCareRecalFee ? values.finalRetireOldCareRecalFee : 0
	);

	const numDeductEtcAWage1 = deductEtcAWage1
		? numStringToNumber(deductEtcAWage1)
		: 0;
	const numDeductEtcAWage2 = deductEtcAWage2
		? numStringToNumber(deductEtcAWage2)
		: 0;
	const numDeductEtcAWage3 = deductEtcAWage3
		? numStringToNumber(deductEtcAWage3)
		: 0;

	const gumakStyle = { textAlign: 'right' };
	//근로소득세, 지방소득세, 연금, 건보, 요양, 고용

	const calTax = calTaxF(
		taxWageSum,
		numOfTaxFamily,
		numOfTaxFamily7To20,
		calTaxRate,
		retiredMonthYes
	);
	const calRegionTax = Math.floor(calTax / 10 / 10) * 10;
	const calPension =
		pensionTaxWageSum === 0
			? 0
			: pension(pensionTaxWageSum, thisYear, thisMonth);
	const calHealthCare =
		healthCareTaxWageSum === 0
			? 0
			: healthCare(healthCareTaxWageSum, thisYear, healthCarePayType);
	const calOldCare =
		oldCareTaxWageSum === 0
			? 0
			: oldCare(oldCareTaxWageSum, thisYear, healthCarePayType);
	const calNoJobInsurance =
		noJobTaxWageSum === 0
			? 0
			: noJobInsurance(noJobTaxWageSum, thisYear, thisMonth);

	const numOfUserFixedTax =
		userFixedTax === 0
			? 0
			: userFixedTax
			? numStringToNumber(userFixedTax)
			: calTax;
	const numOfUserFixedRegionTax =
		userFixedRegionTax === 0
			? 0
			: userFixedRegionTax
			? numStringToNumber(userFixedRegionTax)
			: calRegionTax;
	const numOfUserFixedHealthCare =
		healthCarePayType === '건강보험료 공제안함' || userFixedHealthCare === 0
			? 0
			: userFixedHealthCare
			? numStringToNumber(userFixedHealthCare)
			: calHealthCare;
	const numOfUserFixedOldCare =
		oldCarePayType === '요양보험료 공제안함' || userFixedOldCare === 0
			? 0
			: userFixedOldCare
			? numStringToNumber(userFixedOldCare)
			: calOldCare;
	const numOfUserFixedPension =
		pensionPayType === '국민연금료 공제안함' || userFixedPension === 0
			? 0
			: userFixedPension
			? numStringToNumber(userFixedPension)
			: calPension;
	const numOfUserFixedNoJobInsurance =
		noJobPayType === '고용보험료 공제안함' || userFixedNoJobInsurance === 0
			? 0
			: userFixedNoJobInsurance
			? numStringToNumber(userFixedNoJobInsurance)
			: calNoJobInsurance;

	const sumOfDedectTax =
		numOfUserFixedTax +
		numOfUserFixedRegionTax +
		numOfUserFixedHealthCare +
		numOfUserFixedOldCare +
		numOfUserFixedPension +
		numOfUserFixedNoJobInsurance +
		numPlusDeductHealthCare +
		numPlusDeductOldCare +
		numPlusDeductWorkTax +
		numPlusDeductWorkRegionTax +
		numPlusDeductDuru +
		numPlusMinus4Ins +
		numPlusDeductWorkerCompanyLoan +
		numFinalRetireHealthCareRecalFee +
		numFinalRetireOldCareRecalFee +
		numDeductEtcAWage1 +
		numDeductEtcAWage2 +
		numDeductEtcAWage3;

	//실수령액(실제)
	const deductWage = totalMonthWageAmount - sumOfDedectTax;
	// -------------
	const taxComment = retiredMonthYes
		? '퇴직월 공제안함.'
		: `당월 과세 임금에 대한 소득세법상 근로소득세 ${
				taxPayType ? taxPayType : '100%'
		  }`;
	const regionTaxComment = retiredMonthYes
		? '퇴직월 공제안함.'
		: `당월 과세 임금에 대한 소득세법상 지방소득세 ${
				taxPayType ? taxPayType : '100%'
		  }`;

	return (
		<>
			<Table.Row textAlign='center'>
				<Table.Cell className='gubun'>공제</Table.Cell>
				<Table.Cell className='hangmok'>근로소득세</Table.Cell>
				{inputTax ? (
					<>
						<Table.Cell className='gumak' style={gumakStyle}>
							{numberToCommaString(
								initialTax
									? calTax
									: userFixedTax === 0
									? 0
									: userFixedTax
									? userFixedTax
									: calTax
							)}
						</Table.Cell>
						<Table.Cell textAlign='left' colSpan='6' className='sulmung'>
							{taxComment}
						</Table.Cell>
					</>
				) : (
					<>
						<Table.Cell className='gumak' style={gumakStyle}>
							<input
								style={inputWageStyle}
								name='userFixedTax'
								defaultValue={numberToCommaString(
									initialTax
										? calTax
										: userFixedTax === 0
										? 0
										: userFixedTax
										? userFixedTax
										: calTax
								)}
								onChange={(e) => handelInputChange(e)}
							/>
						</Table.Cell>
						<Table.Cell textAlign='left' colSpan='6' className='sulmung'>
							<input
								style={{ width: '100%' }}
								name='userFixedTaxComment'
								defaultValue={taxComment}
								onChange={(e) => handelInputChange(e)}
							/>
						</Table.Cell>
					</>
				)}
			</Table.Row>
			<Table.Row textAlign='center'>
				<Table.Cell className='gubun'>공제</Table.Cell>
				<Table.Cell className='hangmok'>지방소득세</Table.Cell>
				{inputTax ? (
					<>
						<Table.Cell className='gumak' style={gumakStyle}>
							{numberToCommaString(
								initialTax
									? calRegionTax
									: userFixedRegionTax === 0
									? 0
									: userFixedRegionTax
									? userFixedRegionTax
									: calRegionTax
							)}
						</Table.Cell>
						<Table.Cell textAlign='left' colSpan='6' className='sulmung'>
							{regionTaxComment}
						</Table.Cell>
					</>
				) : (
					<>
						<Table.Cell className='gumak' style={gumakStyle}>
							<input
								style={inputWageStyle}
								name='userFixedRegionTax'
								defaultValue={numberToCommaString(
									initialTax
										? calRegionTax
										: userFixedRegionTax === 0
										? 0
										: userFixedRegionTax
										? userFixedRegionTax
										: calRegionTax
								)}
								onChange={(e) => handelInputChange(e)}
							/>
						</Table.Cell>
						<Table.Cell textAlign='left' colSpan='6' className='sulmung'>
							<input
								style={{ width: '100%' }}
								name='userFixedRegionTaxComment'
								defaultValue={regionTaxComment}
								onChange={(e) => handelInputChange(e)}
							/>
						</Table.Cell>
					</>
				)}
			</Table.Row>
			{healthCarePayType === '건강보험료 공제안함' ? null : (
				<Table.Row textAlign='center'>
					<Table.Cell className='gubun'>공제</Table.Cell>
					<Table.Cell className='hangmok'>건강보험료</Table.Cell>
					{inputTax ? (
						<>
							<Table.Cell className='gumak' style={gumakStyle}>
								{numberToCommaString(
									initialTax
										? calHealthCare
										: userFixedHealthCare
										? userFixedHealthCare
										: calHealthCare
								)}
							</Table.Cell>
							<Table.Cell textAlign='left' colSpan='6' className='sulmung'>
								{healthCareText(
									healthCareBaseWage,
									healthCarePayType,
									thisYear,
									calHealthCare
								)}
							</Table.Cell>
						</>
					) : (
						<>
							<Table.Cell className='gumak' style={gumakStyle}>
								<input
									style={inputWageStyle}
									name='userFixedHealthCare'
									defaultValue={numberToCommaString(
										initialTax
											? calHealthCare
											: userFixedHealthCare
											? userFixedHealthCare
											: calHealthCare
									)}
									onChange={(e) => handelInputChange(e)}
								/>
							</Table.Cell>
							<Table.Cell textAlign='left' colSpan='6' className='sulmung'>
								<input
									style={{ width: '100%' }}
									name='userFixedHealthCareComment'
									defaultValue={healthCareText(
										healthCareBaseWage,
										healthCarePayType,
										thisYear,
										calHealthCare
									)}
									onChange={(e) => handelInputChange(e)}
								/>
							</Table.Cell>
						</>
					)}
				</Table.Row>
			)}
			{oldCarePayType === '요양보험료 공제안함' ? null : (
				<Table.Row textAlign='center'>
					<Table.Cell className='gubun'>공제</Table.Cell>
					<Table.Cell className='hangmok'>요양보험료</Table.Cell>
					{inputTax ? (
						<>
							<Table.Cell className='gumak' style={gumakStyle}>
								{numberToCommaString(
									initialTax
										? calOldCare
										: userFixedOldCare
										? userFixedOldCare
										: calOldCare
								)}
							</Table.Cell>
							<Table.Cell textAlign='left' colSpan='6' className='sulmung'>
								{oldCareText(
									oldCareBaseWage,
									oldCarePayType,
									thisYear,
									calOldCare
								)}
							</Table.Cell>
						</>
					) : (
						<>
							<Table.Cell className='gumak' style={gumakStyle}>
								<input
									style={inputWageStyle}
									name='userFixedOldCare'
									defaultValue={numberToCommaString(
										initialTax
											? calOldCare
											: userFixedOldCare
											? userFixedOldCare
											: calOldCare
									)}
									onChange={(e) => handelInputChange(e)}
								/>
							</Table.Cell>
							<Table.Cell textAlign='left' colSpan='6' className='sulmung'>
								<input
									style={{ width: '100%' }}
									name='userFixedOldCareComment'
									defaultValue={oldCareText(
										oldCareBaseWage,
										oldCarePayType,
										thisYear,
										calOldCare
									)}
									onChange={(e) => handelInputChange(e)}
								/>
							</Table.Cell>
						</>
					)}
				</Table.Row>
			)}
			{pensionPayType === '국민연금료 공제안함' ? null : (
				<Table.Row textAlign='center'>
					<Table.Cell className='gubun'>공제</Table.Cell>
					<Table.Cell className='hangmok'>국민연금</Table.Cell>
					{inputTax ? (
						<>
							<Table.Cell className='gumak' style={gumakStyle}>
								{numberToCommaString(
									initialTax
										? calPension
										: userFixedPension
										? userFixedPension
										: calPension
								)}
							</Table.Cell>
							<Table.Cell textAlign='left' colSpan='6' className='sulmung'>
								{pensionText(
									pensionBaseWage,
									pensionPayType,
									calPension,
									visaType
								)}
							</Table.Cell>
						</>
					) : (
						<>
							<Table.Cell className='gumak' style={gumakStyle}>
								<input
									style={inputWageStyle}
									name='userFixedPension'
									defaultValue={numberToCommaString(
										initialTax
											? calPension
											: userFixedPension
											? userFixedPension
											: calPension
									)}
									onChange={(e) => handelInputChange(e)}
								/>
							</Table.Cell>
							<Table.Cell textAlign='left' colSpan='6' className='sulmung'>
								<input
									style={{ width: '100%' }}
									name='userFixedPensionComment'
									defaultValue={pensionText(
										pensionBaseWage,
										pensionPayType,
										calPension
									)}
									onChange={(e) => handelInputChange(e)}
								/>
							</Table.Cell>
						</>
					)}
				</Table.Row>
			)}

			{noJobPayType === '고용보험료 공제안함' ? null : (
				<Table.Row textAlign='center'>
					<Table.Cell className='gubun'>공제</Table.Cell>
					<Table.Cell className='hangmok'>고용보험료</Table.Cell>
					{inputTax ? (
						<>
							<Table.Cell className='gumak' style={gumakStyle}>
								{numberToCommaString(
									initialTax
										? calNoJobInsurance
										: userFixedNoJobInsurance
										? userFixedNoJobInsurance
										: calNoJobInsurance
								)}
							</Table.Cell>
							<Table.Cell textAlign='left' colSpan='6' className='sulmung'>
								{' '}
								{noJobText(
									noJobBaseWage,
									noJobPayType,
									thisYear,
									calNoJobInsurance,
									visaType,
									selfNoJobPay
								)}
							</Table.Cell>
						</>
					) : (
						<>
							<Table.Cell className='gumak' style={gumakStyle}>
								<input
									style={inputWageStyle}
									name='userFixedNoJobInsurance'
									defaultValue={numberToCommaString(
										initialTax
											? calNoJobInsurance
											: userFixedNoJobInsurance
											? userFixedNoJobInsurance
											: calNoJobInsurance
									)}
									onChange={(e) => handelInputChange(e)}
								/>
							</Table.Cell>
							<Table.Cell textAlign='left' colSpan='6' className='sulmung'>
								<input
									style={{ width: '100%' }}
									name='userFixedNoJobInsuranceComment'
									defaultValue={noJobText(
										noJobBaseWage,
										noJobPayType,
										thisYear,
										calNoJobInsurance,
										visaType,
										selfNoJobPay
									)}
									onChange={(e) => handelInputChange(e)}
								/>
							</Table.Cell>
						</>
					)}
				</Table.Row>
			)}
			<Table.Row textAlign='center'>
				{numPlusDeductHealthCare ? (
					<>
						<Table.Cell className='gubun'>공제</Table.Cell>
						<Table.Cell className='hangmok'>건강보험정산분</Table.Cell>
						<Table.Cell className='gumak' style={gumakStyle}>
							{plusDeductHealthCare}
						</Table.Cell>
						<Table.Cell textAlign='left' colSpan='6' className='sulmung'>
							{plusDeductHealthCareComment}
						</Table.Cell>
					</>
				) : null}
			</Table.Row>
			<Table.Row textAlign='center'>
				{commaStringToNumber(plusDeductOldCare) ? (
					<>
						<Table.Cell className='gubun'>공제</Table.Cell>
						<Table.Cell className='hangmok'>장기요양보험정산분</Table.Cell>
						<Table.Cell className='gumak' style={gumakStyle}>
							{plusDeductOldCare}
						</Table.Cell>
						<Table.Cell textAlign='left' colSpan='6' className='sulmung'>
							{plusDeductOldCareComment}
						</Table.Cell>
					</>
				) : null}
			</Table.Row>
			<Table.Row textAlign='center'>
				{commaStringToNumber(plusDeductWorkTax) ? (
					<>
						<Table.Cell className='gubun'>공제</Table.Cell>
						<Table.Cell className='hangmok'>근로소득세정산분</Table.Cell>
						<Table.Cell className='gumak' style={gumakStyle}>
							{plusDeductWorkTax}
						</Table.Cell>
						<Table.Cell textAlign='left' colSpan='6' className='sulmung'>
							{plusDeductWorkTaxComment}
						</Table.Cell>
					</>
				) : null}
			</Table.Row>
			<Table.Row textAlign='center'>
				{commaStringToNumber(plusDeductWorkRegionTax) ? (
					<>
						<Table.Cell className='gubun'>공제</Table.Cell>
						<Table.Cell className='hangmok'>지방소득세정산분</Table.Cell>
						<Table.Cell className='gumak' style={gumakStyle}>
							{plusDeductWorkRegionTax}
						</Table.Cell>
						<Table.Cell textAlign='left' colSpan='6' className='sulmung'>
							{plusDeductWorkRegionTaxComment}
						</Table.Cell>
					</>
				) : null}
			</Table.Row>
			<Table.Row textAlign='center'>
				{commaStringToNumber(plusDeductWorkerCompanyLoan) ? (
					<>
						<Table.Cell className='gubun'>공제</Table.Cell>
						<Table.Cell className='hangmok'>대여금 반제</Table.Cell>
						<Table.Cell className='gumak' style={gumakStyle}>
							{plusDeductWorkerCompanyLoan}
						</Table.Cell>
						<Table.Cell textAlign='left' colSpan='6' className='sulmung'>
							{plusDeductWorkerCompanyLoanComment}
						</Table.Cell>
					</>
				) : null}
			</Table.Row>
			<Table.Row textAlign='center'>
				{commaStringToNumber(plusDeductDuru) ? (
					<>
						<Table.Cell className='gubun'>공제</Table.Cell>
						<Table.Cell className='hangmok'>두루누리지원금</Table.Cell>
						<Table.Cell className='gumak' style={gumakStyle}>
							{plusDeductDuru}
						</Table.Cell>
						<Table.Cell textAlign='left' colSpan='6' className='sulmung'>
							{plusDeductDuruComment}
						</Table.Cell>
					</>
				) : null}
			</Table.Row>
			<Table.Row textAlign='center'>
				{commaStringToNumber(plusMinus4Ins) ? (
					<>
						<Table.Cell className='gubun'>공제</Table.Cell>
						<Table.Cell className='hangmok'>공제 조정</Table.Cell>
						<Table.Cell className='gumak' style={gumakStyle}>
							{plusMinus4Ins}
						</Table.Cell>
						<Table.Cell textAlign='left' colSpan='6' className='sulmung'>
							{plusMinus4InsComment}
						</Table.Cell>
					</>
				) : null}
			</Table.Row>
			<Table.Row textAlign='center'>
				{numFinalRetireHealthCareRecalFee ? (
					<>
						<Table.Cell className='gubun'>공제</Table.Cell>
						<Table.Cell className='hangmok'>건강보험 퇴직 정산분</Table.Cell>
						<Table.Cell className='gumak' style={gumakStyle}>
							{numberToCommaString(+numFinalRetireHealthCareRecalFee)}
						</Table.Cell>
						<Table.Cell textAlign='left' colSpan='6' className='sulmung'>
							건강보험 퇴직 정산분
						</Table.Cell>
					</>
				) : null}
			</Table.Row>
			<Table.Row textAlign='center'>
				{numFinalRetireOldCareRecalFee ? (
					<>
						<Table.Cell className='gubun'>공제</Table.Cell>
						<Table.Cell className='hangmok'>
							장기요양보험 퇴직 정산분
						</Table.Cell>
						<Table.Cell className='gumak' style={gumakStyle}>
							{numberToCommaString(+numFinalRetireOldCareRecalFee)}
						</Table.Cell>
						<Table.Cell textAlign='left' colSpan='6' className='sulmung'>
							요양보험 퇴직 정산분
						</Table.Cell>
					</>
				) : null}
			</Table.Row>

			<Table.Row textAlign='center'>
				<Table.Cell className='gubun'>공제</Table.Cell>
				<Table.Cell className='gumak' style={gumakStyle}>
					<input
						autoComplete='off'
						style={inputWageStyle}
						name='deductEtcName1'
						value={deductEtcName1}
						onChange={(e) => handelInputChange(e)}
					/>
				</Table.Cell>
				<Table.Cell className='gumak' style={gumakStyle}>
					<input
						autoComplete='off'
						style={inputWageStyle}
						name='deductEtcAWage1'
						value={deductEtcAWage1}
						onChange={(e) => handelInputChange(e)}
					/>
				</Table.Cell>
				<Table.Cell textAlign='left' colSpan='7' className='sulmung'>
					<textarea
						autoComplete='off'
						style={inputCommentStyle}
						name='deductEtcWage1Comment'
						value={deductEtcWage1Comment}
						onChange={(e) => handelInputChange(e)}
					/>
				</Table.Cell>
			</Table.Row>
			<Table.Row textAlign='center'>
				<Table.Cell className='gubun'>공제</Table.Cell>
				<Table.Cell className='gumak' style={gumakStyle}>
					<input
						autoComplete='off'
						style={inputWageStyle}
						name='deductEtcName2'
						value={deductEtcName2}
						onChange={(e) => handelInputChange(e)}
					/>
				</Table.Cell>
				<Table.Cell className='gumak' style={gumakStyle}>
					<input
						autoComplete='off'
						style={inputWageStyle}
						name='deductEtcAWage2'
						value={deductEtcAWage2}
						onChange={(e) => handelInputChange(e)}
					/>
				</Table.Cell>
				<Table.Cell textAlign='left' colSpan='7' className='sulmung'>
					<textarea
						autoComplete='off'
						style={inputCommentStyle}
						name='deductEtcWage2Comment'
						value={deductEtcWage2Comment}
						onChange={(e) => handelInputChange(e)}
					/>
				</Table.Cell>
			</Table.Row>
			<Table.Row textAlign='center'>
				<Table.Cell className='gubun'>공제</Table.Cell>
				<Table.Cell className='gumak' style={gumakStyle}>
					<input
						autoComplete='off'
						style={inputWageStyle}
						name='deductEtcName3'
						value={deductEtcName3}
						onChange={(e) => handelInputChange(e)}
					/>
				</Table.Cell>
				<Table.Cell className='gumak' style={gumakStyle}>
					<input
						autoComplete='off'
						style={inputWageStyle}
						name='deductEtcAWage3'
						value={deductEtcAWage3}
						onChange={(e) => handelInputChange(e)}
					/>
				</Table.Cell>
				<Table.Cell textAlign='left' colSpan='7' className='sulmung'>
					<textarea
						autoComplete='off'
						style={inputCommentStyle}
						name='deductEtcWage3Comment'
						value={deductEtcWage3Comment}
						onChange={(e) => handelInputChange(e)}
					/>
				</Table.Cell>
			</Table.Row>

			<Table.Row textAlign='center'>
				<Table.Cell className='gubun'>공제</Table.Cell>
				<Table.Cell className='hangmok'>공제 합계</Table.Cell>
				<Table.Cell className='gumak' style={gumakStyle}>
					{numberToCommaString(sumOfDedectTax)}
				</Table.Cell>
				<Table.Cell textAlign='left' colSpan='6' className='sulmung'>
					세금 및 4대보험 기타 공제 합계
				</Table.Cell>
			</Table.Row>
			<Table.Row textAlign='center'>
				<Table.Cell className='gubun'>차인지급액</Table.Cell>
				<Table.Cell className='hangmok'>실수령액</Table.Cell>
				<Table.Cell className='gumak' style={gumakStyle}>
					{numberToCommaString(deductWage)}
				</Table.Cell>
				<Table.Cell textAlign='left' colSpan='6' className='sulmung'>
					실수령액
				</Table.Cell>
			</Table.Row>
		</>
	);
}
