import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Loader } from 'semantic-ui-react';
import {
	fetchClientReqs4In,
	fetchWorkerUsers,
} from '../../clientReqActions4In';

import WorkerRegisterList from './WorkerRegisterList';

export default function WorkerRegisterListDashboard({ title, clientReqs4In }) {
	const dispatch = useDispatch();
	const { workerUsers, moreClientReqs4In, filter } = useSelector(
		(state) => state.clientReq4InStore
	);

	const { currentUserProfile } = useSelector((state) => state.profile);
	const { clientInfo } = useSelector((state) => state.companyReducer);
	const { loading } = useSelector((state) => state.async);
	const { authenticated } = useSelector((state) => state.auth);

	const isWorker = currentUserProfile?.isWorker;
	const companyId = clientInfo?.id
		? clientInfo?.id
		: clientInfo?.clientUid?.clientUid
		? clientInfo?.clientUid
		: currentUserProfile?.id;

	const authLevel = currentUserProfile?.authLevel;

	const registeredWorkerPaydocuWorkerUid = workerUsers.map(
		(user) => user?.paydocuWorkerUid
	);
	//paydocuWorkerUid 아이디로 변경 예정

	function handleFetchNextClientReqs4In() {
		dispatch(fetchClientReqs4In(filter));
	}

	useEffect(() => {
		const fetchWorkerUsersData = async () => {
			try {
				await dispatch(fetchWorkerUsers(companyId));
			} catch (error) {
				console.error('Error fetching worker users:', error);
			}
		};

		fetchWorkerUsersData();
	}, [dispatch, companyId]);

	return (
		<>
			{/* 12월17일 아직 쓰지 않음 */}
			{isWorker ? null : (
				<Grid>
					<Grid.Column width={16}>
						<>
							{authenticated && (
								<>
									<WorkerRegisterList
										registeredWorkerPaydocuWorkerUid={
											registeredWorkerPaydocuWorkerUid
										}
										workersList={clientReqs4In}
										getNextClientReqs={handleFetchNextClientReqs4In}
										loading={loading}
										moreClientReqs={moreClientReqs4In}
										title={title}
										authLevel={authLevel}
									/>
								</>
							)}
						</>
					</Grid.Column>
					<Grid.Column width={16}>
						<Loader active={loading} />
					</Grid.Column>
				</Grid>
			)}
		</>
	);
}
