import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../config/firebase';
import {
	setFilterClientInfo,
	setFilterPaydocu,
} from '../../features/companyManage/companyActions';
import { setFilterClientReq4In } from '../../features/clientsRequest/clientReq4In/clientReqActions4In';

const viewLevel = 10;

const CompanySelector = ({
	userUid,
	filter,
	superAdmin,
	clientInfo,
	clientReqs4In,
	realCount,
}) => {
	const dispatch = useDispatch();
	const [companiesInfo, setCompaniesInfo] = useState([]);
	const [lastSignInTime, setLastSignInTime] = useState(''); // 사용자의 마지막 로그인 시간 상태
	const [selectedCompany, setSelectedCompany] = useState('');
	const [searchTerm, setSearchTerm] = useState(''); // Add this line for search term state

	const isMounted = useRef(true); // useRef로 isMounted를 정의

	useEffect(() => {
		isMounted.current = true; // 컴포넌트가 마운트되었음을 표시

		return () => {
			isMounted.current = false; // 컴포넌트 언마운트 시 false로 설정
		};
	}, []); // 빈 배열은 컴포넌트 마운트 시 한 번 실행

	// retiredDate가 있는 사람들 중에서 isDayWorker가 true와 false인 사람의 숫자 구하기
	const retired = clientReqs4In.filter((item) => item.retiredDate);
	const notRetired = clientReqs4In.filter((item) => !item.retiredDate);
	const bizWorker = clientReqs4In.filter((item) => item.isBizWorker);
	const notRetiredBizWorkersCount = bizWorker.length;

	const retiredDayWorkersCount = retired.filter(
		(item) => item.isDayWorker
	).length;
	const retiredNotDayWorkersCount = retired.filter(
		(item) => !item.isDayWorker
	).length;

	const notRetiredDayWorkersCount = notRetired.filter(
		(item) => item.isDayWorker
	).length;

	const notRetiredNotDayWorkersCount = notRetired.filter(
		(item) => !item.isDayWorker && !item.isBizWorker
	).length;

	useEffect(() => {
		const fetchCompanies = async () => {
			const companiesData = []; // 중복을 방지하기 위해 먼저 배열 사용
			try {
				if (superAdmin) {
					// 모든 문서 쿼리
					const queryAll = query(collection(db, 'users'));
					const allSnapshot = await getDocs(queryAll);

					const filteredSnapshot = allSnapshot.docs
						.map((doc) => ({ id: doc.id, ...doc.data() }))
						.filter(
							(company) =>
								!company?.isWorker &&
								company?.authLevel >= viewLevel &&
								company?.companyName !== '노무법인 최상인업 본점' &&
								company?.deletedView !== true && // deletedView가 true가 아닌 문서만 포함
								company?.ceoName
						);

					filteredSnapshot.forEach((company) => {
						const existingIndex = companiesData.findIndex(
							(comp) => comp.companyName === company.companyName
						);

						if (existingIndex !== -1) {
							// 이미 존재하는 회사 데이터가 있고, 새 데이터의 authLevel이 11 이상인 경우에만 업데이트
							if (company.authLevel >= 20) {
								companiesData[existingIndex] = {
									...companiesData[existingIndex], // 기존 데이터 유지
									...company, // 새로운 데이터 병합
								};
							}
						} else {
							// 아직 배열에 없는 경우 추가
							companiesData.push(company);
						}
					});
				} else {
					// agentUid가 userUid와 일치하는 문서 쿼리
					const queryAgentUid = query(
						collection(db, 'users'),
						where('agentUid', '==', userUid)
					);
					const agentUidSnapshot = await getDocs(queryAgentUid);

					// id가 userUid와 일치하는 문서 쿼리
					const queryId = query(
						collection(db, 'users'),
						where('id', '==', userUid)
					);
					const idSnapshot = await getDocs(queryId);

					// agentUid 결과 처리
					agentUidSnapshot.forEach((doc) => {
						const data = doc.data();
						companiesData?.push(data);
					});

					// id 결과 처리
					idSnapshot.forEach((doc) => {
						const data = doc.data();
						// 중복 제거를 위해 companyName 기준으로 이미 배열에 포함되었는지 확인
						if (
							!companiesData?.some(
								(company) => company?.companyName === data?.companyName
							)
						) {
							companiesData?.push(data);
						}
					});
				}
				if (isMounted.current) {
					setCompaniesInfo(companiesData); // 컴포넌트가 마운트된 상태일 때만 상태 업데이트
				}
			} catch (error) {
				console.error('Error fetching companies: ', error); // 오류 처리 추가
			}
		};

		fetchCompanies();

		return () => {
			isMounted.current = false; // 컴포넌트 언마운트 시 상태 업데이트 방지
		};
	}, [userUid, superAdmin]);

	const handleCompanySelect = async (companyName) => {
		setSelectedCompany(companyName);
		setLastSignInTime('');

		const selectedCompanyInfo = companiesInfo.find(
			(company) => company.companyName === companyName
		);
		if (selectedCompanyInfo) {
			dispatch(setFilterPaydocu(selectedCompanyInfo.companyName));
			dispatch(setFilterClientReq4In(selectedCompanyInfo.companyName));
			dispatch(setFilterClientInfo(selectedCompanyInfo));

			if (selectedCompanyInfo.id) {
				const userRef = collection(db, 'users');
				const q = query(userRef, where('id', '==', selectedCompanyInfo.id));
				const querySnapshot = await getDocs(q);
				if (!querySnapshot.empty) {
					querySnapshot.forEach((doc) => {
						const userData = doc.data();
						if (userData?.lastSignInTime && isMounted.current) {
							const lastSignInTimeValue = new Date(
								userData.lastSignInTime.seconds * 1000
							).toLocaleString();
							setLastSignInTime(lastSignInTimeValue);
						}
					});
				} else if (isMounted.current) {
					setLastSignInTime('No data available');
				}
			}
		} else if (isMounted.current) {
			setLastSignInTime('No company selected');
		}
	};

	// Filter companiesInfo based on searchTerm
	const filteredCompanies = companiesInfo.filter((company) =>
		company.companyName.toLowerCase()?.includes(searchTerm.toLowerCase())
	);

	return (
		<div>
			<h1 style={{ textAlign: 'center', margin: '30px' }}>SafeHR</h1>
			<h3 style={{ textAlign: 'left' }}>거래처 선택</h3>
			<div
				style={{
					display: 'flex',
					justifyContent: 'left',
					alignItems: 'left',
					gap: '20px',
					marginBottom: '20px',
				}}>
				<label style={{ marginLeft: '10px' }}>
					전체 등록 회사수 : {companiesInfo.length}개
				</label>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						gap: '10px',
						marginBottom: '20px',
					}}>
					{' '}
					<input
						type='text'
						placeholder='회사검색...'
						value={selectedCompany}
						onChange={(e) => {
							setSearchTerm(e.target.value);
							setSelectedCompany(e.target.value); // 입력값을 selectedCompany로 설정
							handleCompanySelect(e.target.value); // 선택 시 handleCompanySelect 호출
						}}
						style={{
							padding: '10px',
							width: '200px',
							borderRadius: '5px',
							border: '1px solid #ccc',
						}}
						list='companiesList'
					/>
					<datalist id='companiesList'>
						{filteredCompanies.map((company, index) => (
							<option key={index} value={company.companyName} />
						))}
					</datalist>
				</div>

				<label style={{ marginLeft: '10px' }}>
					회사명 : {clientInfo?.companyName}
				</label>
				<label style={{ marginLeft: '10px' }}>
					사업자번호 : {clientInfo?.companyBizNumber}
				</label>
				<label style={{ marginLeft: '10px' }}>
					마지막 로그인 : {lastSignInTime}
				</label>
				<label style={{ marginLeft: '10px' }}>
					등급: {clientInfo?.authLevel}
				</label>
				<label style={{ marginLeft: '10px' }}>
					입력된 직원수: {clientInfo?.numOfWorkers}
				</label>
			</div>
			{realCount && (
				<>
					<hr />
					<div
						style={{
							display: 'flex',
							justifyContent: 'left',
							alignItems: 'left',
							gap: '20px',
							marginBottom: '20px',
						}}>
						<label style={{ marginLeft: '10px' }}>
							등록된 재직 직원: {notRetired.length}
						</label>
						<label style={{ marginLeft: '10px' }}>
							등록된 재직 정직원 직원: {notRetiredNotDayWorkersCount}
						</label>
						<label style={{ marginLeft: '10px' }}>
							등록된 재직 일용직 직원: {notRetiredDayWorkersCount}
						</label>{' '}
						<label style={{ marginLeft: '10px' }}>
							등록된 재직 사업자소득직: {notRetiredBizWorkersCount}
						</label>
						||
						<label style={{ marginLeft: '10px' }}>
							등록된 퇴직 직원수: {retired.length}
						</label>
						<label style={{ marginLeft: '10px' }}>
							등록된 퇴직 정직원 직원수: {retiredNotDayWorkersCount}
						</label>
						<label style={{ marginLeft: '10px' }}>
							등록된 퇴직 일용직 직원수: {retiredDayWorkersCount}
						</label>
					</div>
				</>
			)}
		</div>
	);
};

export default CompanySelector;
