import { format } from 'date-fns';
import React from 'react';
import {
	childCompanyCOWTitleName,
	cTFCarWageLimit,
	cTFChildWageLimit,
	cTFMealWageLimit,
	cTFStudyWageLimit,
	josaGaType,
	josaUnType,
	josaWaType,
	numberToCommaString,
	numStringToNumber,
	renderWithNewline,
	roundUp,
	roundUp2,
} from '../../../../../app/common/util/util';

import DeawangOilSC from './specialComment/DeawangOilSC';

export default function ClientReqDLCDWageMonthWageCon({
	isExceptTimeLawWorker,
	isEtcWorker,
	selectedClientReq4In,
	weekLBTime,
	numOfWorkers,
	companyName,
	worker,
	wageComment,
	perDayMealTaxFree,
}) {
	//임금계약 시작일
	const cWageContractSDate = selectedClientReq4In?.wageContractStartDate
		? format(selectedClientReq4In?.wageContractStartDate, 'yyyy-MM-dd')
		: null;

	//임금계약 종료일
	const cWageContractEDate = selectedClientReq4In?.wageContarctEndDate
		? format(selectedClientReq4In?.wageContarctEndDate, 'yyyy-MM-dd')
		: null;

	// 월급 기산일
	const cStartToEndDate = selectedClientReq4In?.monthlyPay?.startToEndDate;

	// 월급날
	const cPayDay = selectedClientReq4In?.monthlyPay?.payDay;

	// 	// 월급총액(이미 넘버이므로 commaStringToNumber가 필요없음.)
	const cTotalMonthWageAmount = selectedClientReq4In?.totalMonthWageAmount
		? selectedClientReq4In?.totalMonthWageAmount
		: 0;

	// 통상비과세식대
	const cTFMealWage = selectedClientReq4In?.taxFreeWages?.mealWage
		? numStringToNumber(selectedClientReq4In?.taxFreeWages?.mealWage)
		: 0;

	// 통상비과세차량유지비
	const cTFCarWage = selectedClientReq4In?.taxFreeWages?.carWage
		? numStringToNumber(selectedClientReq4In?.taxFreeWages?.carWage)
		: 0;

	// 통상비과세육아수당
	const cTFChildWage = selectedClientReq4In?.taxFreeWages?.childWage
		? numStringToNumber(selectedClientReq4In?.taxFreeWages?.childWage)
		: 0;

	// 통상비과세연구수당
	const cTFStudyWage = selectedClientReq4In?.taxFreeWages?.studyWage
		? numStringToNumber(selectedClientReq4In?.taxFreeWages?.sMONHWagetudyWage)
		: 0;

	// 통상비과세기타 계
	const cTFEtc = selectedClientReq4In?.taxFreeWages?.etc
		? numStringToNumber(selectedClientReq4In?.taxFreeWages?.etc)
		: 0;
	// 통상직책수당
	const cOWTitle = selectedClientReq4In?.ordinaryWage?.title
		? numStringToNumber(selectedClientReq4In?.ordinaryWage?.title)
		: 0;
	// 통상직무수당
	const cOWJob = selectedClientReq4In?.ordinaryWage?.job
		? numStringToNumber(selectedClientReq4In?.ordinaryWage?.job)
		: 0;
	// 통상자격수당
	const cOWLicense = selectedClientReq4In?.ordinaryWage?.license
		? numStringToNumber(selectedClientReq4In?.ordinaryWage?.license)
		: 0;
	// 통상월상여금
	const cOWMonthlyBonus = selectedClientReq4In?.ordinaryWage?.monthlyBonus
		? numStringToNumber(selectedClientReq4In?.ordinaryWage?.monthlyBonus)
		: 0;
	// 통상기타수당계
	const cOWEtc = selectedClientReq4In?.ordinaryWage?.etc
		? numStringToNumber(selectedClientReq4In?.ordinaryWage?.etc)
		: 0;
	const cOWEtcComment = selectedClientReq4In?.ordinaryWage?.etcComment
		? selectedClientReq4In?.ordinaryWage?.etcComment
		: 0;

	// 어린이집 통상임금 개발 내용
	const cOWChildManageStudy = selectedClientReq4In?.ordinaryWage
		?.childManageStudy
		? numStringToNumber(selectedClientReq4In?.ordinaryWage?.childManageStudy)
		: 0;
	const cOWChildStudy = selectedClientReq4In?.ordinaryWage?.childStudy
		? numStringToNumber(selectedClientReq4In?.ordinaryWage?.childStudy)
		: 0;
	const cOWChildCare = selectedClientReq4In?.ordinaryWage?.childCare
		? numStringToNumber(selectedClientReq4In?.ordinaryWage?.childCare)
		: 0;
	const cOWChildActivity = selectedClientReq4In?.ordinaryWage?.childActivity
		? numStringToNumber(selectedClientReq4In?.ordinaryWage?.childActivity)
		: 0;

	// 평균임금수당1조건
	const cAWECondi1 = selectedClientReq4In?.averageWage?.irregularAWCondition1
		? selectedClientReq4In?.averageWage.irregularAWCondition1
		: '';
	// 평균임금수당1 명칭
	const cAWEName1 = selectedClientReq4In?.averageWage?.irregularAWName1
		? selectedClientReq4In?.averageWage.irregularAWName1
		: '';
	// 평균임금수당1 금액
	const cAWage1 = selectedClientReq4In?.averageWage?.irregularAWage1
		? numStringToNumber(selectedClientReq4In?.averageWage.irregularAWage1)
		: 0;
	const cAWECondi2 = selectedClientReq4In?.averageWage?.irregularAWCondition2
		? selectedClientReq4In?.averageWage.irregularAWCondition2
		: '';
	const cAWEName2 = selectedClientReq4In?.averageWage?.irregularAWName2
		? selectedClientReq4In?.averageWage.irregularAWName2
		: '';
	const cAWage2 = selectedClientReq4In?.averageWage?.irregularAWage2
		? numStringToNumber(selectedClientReq4In?.averageWage.irregularAWage2)
		: 0;
	const cAWECondi3 = selectedClientReq4In?.averageWage?.irregularAWCondition3
		? selectedClientReq4In?.averageWage.irregularAWCondition3
		: '';
	const cAWEName3 = selectedClientReq4In?.averageWage?.irregularAWName3
		? selectedClientReq4In?.averageWage.irregularAWName3
		: '';
	const cAWage3 = selectedClientReq4In?.averageWage?.irregularAWage3
		? numStringToNumber(selectedClientReq4In?.averageWage.irregularAWage3)
		: 0;
	const cAWECondi4 = selectedClientReq4In?.averageWage?.irregularAWCondition4
		? selectedClientReq4In?.averageWage.irregularAWCondition4
		: '';
	const cAWEName4 = selectedClientReq4In?.averageWage?.irregularAWName4
		? selectedClientReq4In?.averageWage.irregularAWName4
		: '';
	const cAWage4 = selectedClientReq4In?.averageWage?.irregularAWage4
		? numStringToNumber(selectedClientReq4In?.averageWage.irregularAWage4)
		: 0;

	// 평균임금 비과세 수당1조건
	const cAWECondiTF1 = selectedClientReq4In?.averageWageTF
		?.irregularAWCondition1
		? selectedClientReq4In?.averageWageTF.irregularAWCondition1
		: '';
	// 평균임금 비과세 수당1 명칭
	const cAWENameTF1 = selectedClientReq4In?.averageWageTF?.irregularAWName1
		? selectedClientReq4In?.averageWageTF.irregularAWName1
		: '';
	// 평균임금비과세 수당1 금액
	const cAWageTF1 = selectedClientReq4In?.averageWageTF?.irregularAWage1
		? numStringToNumber(selectedClientReq4In?.averageWageTF.irregularAWage1)
		: 0;
	const cAWECondiTF2 = selectedClientReq4In?.averageWageTF
		?.irregularAWCondition2
		? selectedClientReq4In?.averageWageTF.irregularAWCondition2
		: '';
	const cAWENameTF2 = selectedClientReq4In?.averageWageTF?.irregularAWName2
		? selectedClientReq4In?.averageWageTF.irregularAWName2
		: '';
	const cAWageTF2 = selectedClientReq4In?.averageWageTF?.irregularAWage2
		? numStringToNumber(selectedClientReq4In?.averageWageTF.irregularAWage2)
		: 0;
	const cAWECondiTF3 = selectedClientReq4In?.averageWageTF
		?.irregularAWCondition3
		? selectedClientReq4In?.averageWageTF.irregularAWCondition3
		: '';
	const cAWENameTF3 = selectedClientReq4In?.averageWageTF?.irregularAWName3
		? selectedClientReq4In?.averageWageTF.irregularAWName3
		: '';
	const cAWageTF3 = selectedClientReq4In?.averageWageTF?.irregularAWage3
		? numStringToNumber(selectedClientReq4In?.averageWageTF.irregularAWage3)
		: 0;
	const cAWECondiTF4 = selectedClientReq4In?.averageWageTF
		?.irregularAWCondition4
		? selectedClientReq4In?.averageWageTF.irregularAWCondition4
		: '';
	const cAWENameTF4 = selectedClientReq4In?.averageWageTF?.irregularAWName4
		? selectedClientReq4In?.averageWageTF.irregularAWName4
		: '';
	const cAWageTF4 = selectedClientReq4In?.averageWageTF?.irregularAWage4
		? numStringToNumber(selectedClientReq4In?.averageWageTF.irregularAWage4)
		: 0;

	// 1일 소정근로시간(주휴수당근거수당)
	const paidHolidayLBTime = selectedClientReq4In?.lawBase?.paidHolidayLBTime;

	// 1달 통상임금산정기준시간수(속칭 1달 소정근로시간)
	const monthLBTime = selectedClientReq4In?.lawBase?.monthLBTime;

	// 월간 휴일근무 일수(년간 휴일수/12개월)
	const monthHoliWD = selectedClientReq4In?.realWorkTime?.monthHoliWD;

	// 년간 휴일근무 일수
	const numOfYearlyHoliWD =
		selectedClientReq4In?.realWorkTime?.numOfYearlyHoliWD;

	//휴일근로 1일 실 야간 근로시간(휴게제외)
	const daysNWTHoli = selectedClientReq4In?.realWorkTime?.daysNWTHoli;

	//1일 8시간 이내 기본 휴일근로시간
	const daysHoliBasicTime =
		selectedClientReq4In?.realWorkTime?.daysHoliBasicTime;
	//1일 휴일연장시간
	const daysNOWTHoli = selectedClientReq4In?.realWorkTime?.daysNOWTHoli;

	// 최종 1주 연장근로시간
	const finalWeekOWT = selectedClientReq4In?.realWorkTime?.finalWeekOWT;

	//1주 총 야간근로시간
	const sumOneWeekNWT = selectedClientReq4In?.realWorkTime?.sumOneWeekNWT;

	//1주 총 야간근로시간
	const sumOneWeekNWTETL = roundUp2(
		selectedClientReq4In?.realWorkTime?.sumOneWeekNWTETL
	);

	// 월간 환산연장근로시간
	const converseMonthOWT = selectedClientReq4In?.calWorkTime?.converseMonthOWT;

	// 월간 환산야간시간
	let converseMonthNWT = selectedClientReq4In?.calWorkTime?.converseMonthNWT;
	// converseMonthNWT = isExceptTimeLawWorker ? monthNWT / 2 : converseMonthNWT;

	// 월간 환산 휴일 기본시간
	const converseMonthHoliBasicTime =
		selectedClientReq4In?.calWorkTime?.converseMonthHoliBasicTime;
	// 월간 환산 휴일 연장시간
	const conversemonthOWTHoli =
		selectedClientReq4In?.calWorkTime?.conversemonthOWTHoli;
	// 월간 환산 휴일 야간시간
	const converseMonthNWTHoli =
		selectedClientReq4In?.calWorkTime?.converseMonthNWTHoli;

	// 총 유급시간(5인미만의 경우 기본급 시간) 5인이상인 경우 월통상임금 시간 : monthLBTime
	const mTPCTime = selectedClientReq4In?.calWorkTime?.mTPCTime;

	// 통상 시급(금액)
	const hourOrdinaryWage = selectedClientReq4In?.lawBase?.hourOrdinaryWage;

	// 월 연장근로수당(금액)
	const mOWage = selectedClientReq4In?.calWorkWage?.mOWage;
	//월 야간근로수당(금액)
	const mNWage = selectedClientReq4In?.calWorkWage?.mNWage;
	//월 휴일기본근로수당(금액)
	const mHBWage = selectedClientReq4In?.calWorkWage?.mHBWage;
	//월 휴일연장근로수당(금액)
	const mHOWage = selectedClientReq4In?.calWorkWage?.mHOWage;
	//월 휴일야간근로수당(금액)
	const mHNWage = selectedClientReq4In?.calWorkWage?.mHNWage;

	// 월 통상임금((평균임금제외) -법정가산수당))
	const monthOrdinaryWage =
		selectedClientReq4In?.calWorkWage?.monthOrdinaryWage;

	// 월 기본급(5인이상이면 월 통상임금 - 비과세(통상) - 통상수당  )
	const monthBasicWage = selectedClientReq4In?.calWorkWage?.monthBasicWage;

	///////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// 최저임금 판단부분
	const calTotalMonthLowestWage =
		selectedClientReq4In?.lawBase?.calTotalMonthLowestWage;
	// 계산된 최저 시급
	const calHourLowestWage = selectedClientReq4In?.lawBase?.calHourLowestWage;

	const annualLeavesIncludeMonthWage =
		selectedClientReq4In?.annualLeavesIncludeMonthWage;
	const sumOneWeekLBDWTETL = selectedClientReq4In?.sumOneWeekLBDWTETL;
	const daysWTETL7 = selectedClientReq4In?.realWorkTime?.daysWTETL7;

	const paidHolidayLBTimeMonthWage = numberToCommaString(
		roundUp((((paidHolidayLBTime / 7) * 365) / 12) * hourOrdinaryWage)
	);

	const wageDetailOver15 = (
		<ol>
			{monthBasicWage && (
				<li>
					{isExceptTimeLawWorker
						? `[통상임금] 기본급 : ${numberToCommaString(
								monthBasicWage
						  )}원, 월간 기본근로에 대한 임금.`
						: `[통상임금(주휴포함)] 기본급 : ${numberToCommaString(
								monthBasicWage
						  )}원, 월간 기본근로에 대한 임금(주휴수당 포함).`}
				</li>
			)}
			{cTFMealWage ? (
				<li>
					{`${
						isExceptTimeLawWorker
							? '[통상임금] 식대'
							: '[통상임금(주휴포함)] 식대'
					} : ${numberToCommaString(cTFMealWage)}원, 
        복리후생적으로 매월 정기적, 일률적, 고정적으로 지급하는 식대보조비${cTFMealWageLimit} `}
				</li>
			) : null}
			{cTFCarWage ? (
				<li>
					{`${
						isExceptTimeLawWorker
							? '[통상임금] 차량유지비'
							: '[통상임금(주휴포함)] 차량유지비'
					} : ${numberToCommaString(cTFCarWage)}원, 
          자기소유 차량을 회사업무에 이용하는 직원에게 지급하는 실비변상적으로 매월 정기적, 일률적, 고정적으로 지급하는 차량유지비${cTFCarWageLimit} `}
				</li>
			) : null}
			{cTFChildWage ? (
				<li>
					{`${
						isExceptTimeLawWorker
							? '[통상임금] 육아수당'
							: '[통상임금(주휴포함)] 육아수당'
					} : ${numberToCommaString(cTFChildWage)}원, 
          만6세미만 자녀를 양육하는 직원에게 지급하는 복리후생적으로 매월 정기적, 일률적, 고정적으로 지급하는 육아수당${cTFChildWageLimit} `}
				</li>
			) : null}
			{cTFStudyWage ? (
				<li>
					{`${
						isExceptTimeLawWorker
							? '[통상임금] 연구수당'
							: '[통상임금(주휴포함)] 연구수당'
					} : ${numberToCommaString(cTFStudyWage)}원, 
          기업부설 연구소 연구원의 연구보조비로 매월 정기적, 일률적, 고정적으로 지급하는 연구수당${cTFStudyWageLimit} `}
				</li>
			) : null}
			{cTFEtc ? (
				<li>
					{`${
						isExceptTimeLawWorker
							? '[통상임금] 기타비과세수당합계'
							: '[통상임금(주휴포함)] 기타비과세수당합계'
					} : ${numberToCommaString(cTFEtc)}원, 
          기타 소득세법상 비과세 임금으로 매월 정기적, 일률적, 고정적으로 지급하는 수당들의 비과세 금액 합계`}
				</li>
			) : null}

			{cOWTitle ? (
				<li>
					{`${
						isExceptTimeLawWorker
							? `[통상임금] ${childCompanyCOWTitleName(companyName)}`
							: `[통상임금(주휴포함)] ${childCompanyCOWTitleName(companyName)}`
					} : ${numberToCommaString(cOWTitle)}원, 
          본인 및 하위 직급자의 근태와 인사고과의 전부 또는 일부 권한을 행사하는 근로기준법 제63조의 관리감독자에게 매월 정기적, 일률적, 고정적으로 지급하는 직책수당`}
				</li>
			) : null}
			{cOWJob ? (
				<li>
					{`${
						isExceptTimeLawWorker
							? '[통상임금] 직무수당'
							: '[통상임금(주휴포함)] 직무수당'
					} : ${numberToCommaString(cOWJob)}원, 
          직무에 따른 직무급 성격의 임금으로 매월 정기적, 일률적, 고정적으로 지급하는 직무수당`}
				</li>
			) : null}
			{cOWLicense ? (
				<li>
					{`${
						isExceptTimeLawWorker
							? '[통상임금] 자격(면허)수당'
							: '[통상임금(주휴포함)] 자격(면허)수당'
					} : ${numberToCommaString(cOWLicense)}원, 
          특정 직무 수행에 필요한 자격(또는 면허)를 보유한 직원에게 매월 정기적, 일률적, 고정적으로 지급하는 자격(면허)수당`}
				</li>
			) : null}
			{cOWMonthlyBonus ? (
				<li>
					{`${
						isExceptTimeLawWorker
							? '[통상임금] 월정기상여금'
							: '[통상임금(주휴포함)] 월정기상여금'
					} : ${numberToCommaString(cOWMonthlyBonus)}원, 
          매월 정기적, 일률적, 고정적으로 지급하는 월상여금`}
				</li>
			) : null}
			{cOWEtc ? (
				<li>
					{`${
						isExceptTimeLawWorker
							? '[통상임금] 기타통상수당합계'
							: '[통상임금(주휴포함)] 기타통상수당합계'
					} : ${numberToCommaString(cOWEtc)}원, 
          ${cOWEtcComment}`}
				</li>
			) : null}
			{cOWChildManageStudy ? (
				<li>
					{`${
						isExceptTimeLawWorker
							? '[통상임금] 관리 및 연구수당'
							: '[통상임금(주휴포함)] 관리 및 연구수당'
					} : ${numberToCommaString(cOWChildManageStudy)}원,
          관리 및 연구를 위해 정기적, 일률적, 고정적으로 지급하는 수당`}
				</li>
			) : null}
			{cOWChildStudy ? (
				<li>
					{`${
						isExceptTimeLawWorker
							? '[통상임금] 연구수당'
							: '[통상임금(주휴포함)] 연구수당'
					} : ${numberToCommaString(cOWChildStudy)}원,
          직원에게 연구 독려를 위해 매월 정기적, 일률적, 고정적으로 지급하는 연구수당`}
				</li>
			) : null}
			{cOWChildCare ? (
				<li>
					{`${
						isExceptTimeLawWorker
							? '[통상임금] 취약보육수당'
							: '[통상임금(주휴포함)] 취약보육수당'
					} : ${numberToCommaString(cOWChildCare)}원,
          원생 보육을 지원하는 직원에게 매월 정기적, 일률적, 고정적으로 지급하는 취약보육수당`}
				</li>
			) : null}
			{cOWChildActivity ? (
				<li>
					{`${
						isExceptTimeLawWorker
							? '[통상임금] 활동보조수당'
							: '[통상임금(주휴포함)] 활동보조수당'
					} : ${numberToCommaString(cOWChildActivity)}원,
          원생의 활동을 지원하는 직원에게 매월 정기적, 일률적, 고정적으로 지급하는 활동보조수당`}
				</li>
			) : null}

			<>
				{isEtcWorker ? (
					<>
						{mOWage ? (
							<li>
								{`[평균임금] 약정시간외근로수당 : ${numberToCommaString(
									mOWage
								)}원, 
                  당사자간에 합의한 소정근로시간을 초과하는 연장근로 월 ${roundUp2(
										converseMonthOWT
									)}시간(1.5배 환산한 시간)에 대한 법정연장근로수당
                  [(1개월 평균 ${numberToCommaString(
										converseMonthOWT / 1.5
									)})연장근로시간 * 1.5배 * 통상시급]
                `}
							</li>
						) : null}
						{mNWage ? (
							<li>
								{`[평균임금] 약정야간근로수당 : ${numberToCommaString(
									mNWage
								)}원, 
                  당사자간에 합의한 22시~06시의 야간근로 월 ${roundUp2(
										converseMonthNWT
									)}시간(0.5배 환산한 시간)에 대한 법정야간근로수당
                  [1개월 평균 (${numberToCommaString(
										converseMonthNWT / 0.5
									)})야간근로시간 * 0.5배 * 통상시급]
                  `}
							</li>
						) : null}
						{mHBWage ? (
							<li>
								{`[평균임금] 약정휴일기본근로수당 : ${numberToCommaString(
									mHBWage
								)}원, 
                  당사자간에 합의한 휴일 기본 근무 월 (${roundUp2(
										converseMonthHoliBasicTime
									)})시간(1.5배 환산한 시간)에 대한 법정휴일기본근로수당
                  [1개월 평균 (${numberToCommaString(
										converseMonthHoliBasicTime / 1.5
									)})휴일기본(휴일 1일당 8시간 미만)근로시간 * 1.5배 * 통상시급]
                `}
							</li>
						) : null}
						{mHOWage ? (
							<li>
								{`[평균임금] 약정휴일연장근로수당 : ${numberToCommaString(
									mHOWage
								)}원, 
                  당사자간에 합의한 휴일 연장 근무 월 ${roundUp2(
										conversemonthOWTHoli
									)}시간(2배 환산한 시간)에 대한 법정휴일연장근로수당 
                  [1개월 평균 (${numberToCommaString(
										conversemonthOWTHoli / 2
									)})휴일기본(휴일 1일당 8시간 이상)근로시간 * 1.5배 * 통상시급]

                `}
							</li>
						) : null}
						{mHNWage ? (
							<li>
								{`[평균임금] 약정휴일야간근로수당 : ${numberToCommaString(
									mHNWage
								)}원, 
                  당사자간에 합의한 휴일 야간(22시~06시) 근무 월 ${roundUp2(
										converseMonthNWTHoli
									)}시간(0.5배 환산한 시간)에 대한 법정휴일야간근로수당 
                  [1개월 평균 (${numberToCommaString(
										converseMonthNWTHoli / 0.5
									)})휴일야간근로시간 * 0.5배 * 통상시급]
                `}
							</li>
						) : null}
					</>
				) : (
					<>
						{mOWage ? (
							<li>
								{`[평균임금] 약정시간외근로수당 : ${numberToCommaString(
									mOWage
								)}원, 
                  당사자간에 합의한 소정근로시간을 초과하는 연장근로 월 ${roundUp2(
										converseMonthOWT
									)}시간(1.5배 환산한 시간)에 대한 법정연장근로수당
                  [(1주 ${numberToCommaString(
										finalWeekOWT
									)})연장근로시간 * 1.5배] / 7일 * 365일 /12개월 * 통상시급
                `}
							</li>
						) : null}
						{mNWage ? (
							<li>
								{`[평균임금] 약정야간근로수당 : ${numberToCommaString(
									mNWage
								)}원, 
                  당사자간에 합의한 22시~06시의 야간근로 월 ${roundUp2(
										converseMonthNWT
									)}시간에 대한 법정야간근로수당
                  [1주 (${
										isExceptTimeLawWorker
											? numberToCommaString(sumOneWeekNWTETL)
											: numberToCommaString(sumOneWeekNWT)
									})야간근로시간 * 0.5배] / 7일 * 365일 /12개월 * 통상시급
                  `}
							</li>
						) : null}
						{mHBWage ? (
							<li>
								{`[평균임금] 약정휴일기본근로수당 : ${numberToCommaString(
									mHBWage
								)}원, 
                  당사자간에 합의한 휴일 기본 근무 월 (${roundUp2(
										converseMonthHoliBasicTime
									)})시간에 대한 법정휴일기본근로수당
                  [(월간휴일근무일수(년간휴일근로/12개월) (${numberToCommaString(
										monthHoliWD
									)})일 * (${daysHoliBasicTime})휴일기본근로시간 * 1.5배] * 통상시급, 
                  단, 위 동 수당은 년간 ${numberToCommaString(
										numOfYearlyHoliWD
									)}일에 해당하는 약정휴일기본근로수당을 12개월로 나눈 값으로 12개월을 채우지 아니하는 경우 등의 이유로 휴일근로를 제공했음에도 불구하고 차액이 발생하는 경우 그 차액을 지급해야 한다.
                `}
							</li>
						) : null}
						{mHOWage ? (
							<li>
								{`[평균임금] 약정휴일연장근로수당 : ${numberToCommaString(
									mHOWage
								)}원, 
                  당사자간에 합의한 휴일 연장 근무 월 ${roundUp2(
										conversemonthOWTHoli
									)}시간에 대한 법정휴일연장근로수당 
                  [(월간휴일근무일수(년간휴일근로/12개월) (${numberToCommaString(
										monthHoliWD
									)})일 * (${roundUp2(
									daysNOWTHoli
								)})휴일연장근로시간 * 2배] * 통상시급
                  단, 위 동 수당은 년간 ${numberToCommaString(
										numOfYearlyHoliWD
									)}일에 해당하는 약정휴일연장근로수당을 12개월로 나눈 값으로 12개월을 채우지 아니하는 경우 등의 이유로 휴일근로를 제공했음에도 불구하고 차액이 발생하는 경우 그 차액을 지급해야 한다.
                `}
							</li>
						) : null}
						{mHNWage ? (
							<li>
								{`[평균임금] 약정휴일야간근로수당 : ${numberToCommaString(
									mHNWage
								)}원, 
                  당사자간에 합의한 휴일 야간(22시~06시) 근무 월 ${roundUp2(
										converseMonthNWTHoli
									)}시간에 대한 법정휴일야간근로수당 
                  [(월간휴일근무일수(년간휴일근로/12개월) (${numberToCommaString(
										monthHoliWD
									)})일 * (${roundUp2(
									daysNWTHoli
								)})휴일야간근로시간 * 0.5배] * 통상시급
                  단, 위 동 수당은 년간 ${numberToCommaString(
										numOfYearlyHoliWD
									)}일에 해당하는 약정휴일야간근로수당을 12개월로 나눈 값으로 12개월을 채우지 아니하는 경우 등의 이유로 휴일근로를 제공했음에도 불구하고 차액이 발생하는 경우 그 차액을 지급해야 한다.
                `}
							</li>
						) : null}
						{annualLeavesIncludeMonthWage ? (
							<li>
								{`[평균임금] 약정유급연차휴가근로수당 : ${numberToCommaString(
									Math.ceil(annualLeavesIncludeMonthWage)
								)}원, 
                당사자간에 합의한 년간 15일분의 연차휴가에 대한 임금을 12개월 분할한 한달분의 임금[1년 15일 연차휴가 / 12개월 * ([1주간 실근무시간(${roundUp2(
									sumOneWeekLBDWTETL - daysWTETL7
								)}시간) / 6일](1일 평균 실근무시간) * 통상시급]
              `}
							</li>
						) : null}
					</>
				)}
			</>
			{cAWage1 ? (
				<li>
					{`[평균임금] ${cAWEName1} : ${numberToCommaString(
						cAWage1
					)}원, ${cAWECondi1}`}
				</li>
			) : null}
			{cAWage2 ? (
				<li>
					{`[평균임금] ${cAWEName2} : ${numberToCommaString(
						cAWage2
					)}원, ${cAWECondi2}`}
				</li>
			) : null}
			{cAWage3 ? (
				<li>
					{`[평균임금] ${cAWEName3} : ${numberToCommaString(
						cAWage3
					)}원, ${cAWECondi3}`}
				</li>
			) : null}
			{cAWage4 ? (
				<li>
					{`[평균임금] ${cAWEName4} : ${numberToCommaString(
						cAWage4
					)}원, ${cAWECondi4}`}
				</li>
			) : null}

			{cAWageTF1 ? (
				<li>
					{`[평균임금] ${cAWENameTF1} : ${numberToCommaString(
						cAWageTF1
					)}원, ${cAWECondiTF1}`}
				</li>
			) : null}
			{cAWageTF2 ? (
				<li>
					{`[평균임금] ${cAWENameTF2} : ${numberToCommaString(
						cAWageTF2
					)}원, ${cAWECondiTF2}`}
				</li>
			) : null}
			{cAWageTF3 ? (
				<li>
					{`[평균임금] ${cAWENameTF3} : ${numberToCommaString(
						cAWageTF3
					)}원, ${cAWECondiTF3}`}
				</li>
			) : null}
			{cAWageTF4 ? (
				<li>
					{`[평균임금] ${cAWENameTF4} : ${numberToCommaString(
						cAWageTF4
					)}원, ${cAWECondiTF4}`}
				</li>
			) : null}
		</ol>
	);

	const wageDetailUnder15 = (
		<ol>
			{monthBasicWage ? (
				<li>
					{`[통상임금] 기본급 : ${numberToCommaString(monthBasicWage)}원, 
        월간 기본근로에 대한 임금.`}
				</li>
			) : null}
			{cTFMealWage ? (
				<li>
					{`[통상임금] 식대 : ${numberToCommaString(cTFMealWage)}원, 
        복리후생적으로 매월 정기적, 일률적, 고정적으로 지급하는 식대보조비${cTFMealWageLimit} `}
				</li>
			) : null}
			{cTFCarWage ? (
				<li>
					{`[통상임금] 차량유지비 : ${numberToCommaString(cTFCarWage)}원, 
          자기소유 차량을 회사업무에 이용하는 직원에게 지급하는 실비변상적으로 매월 정기적, 일률적, 고정적으로 지급하는 차량유지비${cTFCarWageLimit} `}
				</li>
			) : null}
			{cTFChildWage ? (
				<li>
					{`[통상임금] 육아수당 : ${numberToCommaString(cTFChildWage)}원, 
          만6세미만 자녀를 양육하는 직원에게 지급하는 복리후생적으로 매월 정기적, 일률적, 고정적으로 지급하는 육아수당${cTFChildWageLimit} `}
				</li>
			) : null}
			{cTFStudyWage ? (
				<li>
					{`[통상임금] 연구수당 : ${numberToCommaString(cTFStudyWage)}원, 
          기업부설 연구소 연구원의 연구보조비로 매월 정기적, 일률적, 고정적으로 지급하는 연구수당${cTFStudyWageLimit} `}
				</li>
			) : null}
			{cTFEtc ? (
				<li>
					{`[통상임금] 기타비과세수당합계 : ${numberToCommaString(cTFEtc)}원, 
          기타 소득세법상 비과세 임금으로 매월 정기적, 일률적, 고정적으로 지급하는 수당들의 비과세 금액 합계`}
				</li>
			) : null}

			{cOWTitle ? (
				<li>
					{`[통상임금] ${childCompanyCOWTitleName(
						companyName
					)} : ${numberToCommaString(cOWTitle)}원, 
          본인 및 하위 직급자의 근태와 인사고과의 전부 또는 일부 권한을 행사하는 근로기준법 제63조의 관리감독자에게 매월 정기적, 일률적, 고정적으로 지급하는 직책수당`}
				</li>
			) : null}
			{cOWJob ? (
				<li>
					{`[통상임금] 직무수당 : ${numberToCommaString(cOWJob)}원, 
          직무에 따른 직무급 성격의 임금으로 매월 정기적, 일률적, 고정적으로 지급하는 직무수당`}
				</li>
			) : null}
			{cOWLicense ? (
				<li>
					{`[통상임금] 자격(면허)수당 : ${numberToCommaString(cOWLicense)}원, 
          특정 직무 수행에 필요한 자격(또는 면허)를 보유한 직원에게 매월 정기적, 일률적, 고정적으로 지급하는 자격(면허)수당`}
				</li>
			) : null}
			{cOWMonthlyBonus ? (
				<li>
					{`[통상임금] 월정기상여금 : ${numberToCommaString(
						cOWMonthlyBonus
					)}원, 
          매월 정기적, 일률적, 고정적으로 지급하는 월상여금`}
				</li>
			) : null}
			{cOWEtc ? (
				<li>
					{`[통상임금] 기타통상수당합계 : ${numberToCommaString(cOWEtc)}원, 
          매월 정기적, 일률적, 고정적으로 지급하는 기타통상수당 합계`}
				</li>
			) : null}
			{mOWage ? (
				<li>
					{`[평균임금] 약정시간외근로수당 : ${numberToCommaString(mOWage)}원, 
          당사자간에 합의한 소정근로시간을 초과하는 연장근로 월 ${roundUp2(
						converseMonthOWT
					)}시간(1.5배 환산한 시간)에 대한 법정연장근로수당
          [(1주 ${numberToCommaString(
						finalWeekOWT
					)})연장근로시간 * 1.5배] / 7일 * 365일 /12개월 * 통상시급
        `}
				</li>
			) : null}
			{mNWage ? (
				<li>
					{`[평균임금] 약정야간근로수당 : ${numberToCommaString(mNWage)}원, 
          당사자간에 합의한 22시~06시의 야간근로 월 ${roundUp2(
						converseMonthNWT
					)}시간에 대한 법정야간근로수당
          [1주 (${numberToCommaString(
						sumOneWeekNWT
					)})야간근로시간 * 0.5배] / 7일 * 365일 /12개월 * 통상시급
          `}
				</li>
			) : null}
			{mHBWage ? (
				<li>
					{`[평균임금] 약정휴일기본근로수당 : ${numberToCommaString(
						mHBWage
					)}원, 
          당사자간에 합의한 휴일 기본 근무 월 (${roundUp2(
						converseMonthHoliBasicTime
					)})시간에 대한 법정휴일기본근로수당
          [(월간휴일근무일수(년간휴일근로/12개월) (${numberToCommaString(
						monthHoliWD
					)})일 * (${daysHoliBasicTime})휴일기본근로시간 * 1.5배] * 통상시급, 
          단, 위 동 수당은 년간 ${numberToCommaString(
						numOfYearlyHoliWD
					)}일에 해당하는 약정휴일기본근로수당을 12개월로 나눈 값으로 12개월을 채우지 아니하는 경우 등의 이유로 휴일근로를 제공했음에도 불구하고 차액이 발생하는 경우 그 차액을 지급해야 한다.
        `}
				</li>
			) : null}
			{mHOWage ? (
				<li>
					{`[평균임금] 약정휴일연장근로수당 : ${numberToCommaString(
						mHOWage
					)}원, 
          당사자간에 합의한 휴일 연장 근무 월 ${roundUp2(
						conversemonthOWTHoli
					)}시간에 대한 법정휴일연장근로수당 
          [(월간휴일근무일수(년간휴일근로/12개월) (${numberToCommaString(
						monthHoliWD
					)})일 * (${roundUp2(daysNOWTHoli)})휴일연장근로시간 * 2배] * 통상시급
          단, 위 동 수당은 년간 ${numberToCommaString(
						numOfYearlyHoliWD
					)}일에 해당하는 약정휴일연장근로수당을 12개월로 나눈 값으로 12개월을 채우지 아니하는 경우 등의 이유로 휴일근로를 제공했음에도 불구하고 차액이 발생하는 경우 그 차액을 지급해야 한다.
        `}
				</li>
			) : null}
			{mHNWage ? (
				<li>
					{`[평균임금] 약정휴일야간근로수당 : ${numberToCommaString(
						mHNWage
					)}원, 
          당사자간에 합의한 휴일 야간(22시~06시) 근무 월 ${roundUp2(
						converseMonthNWTHoli
					)}시간에 대한 법정휴일야간근로수당 
          [(월간휴일근무일수(년간휴일근로/12개월) (${numberToCommaString(
						monthHoliWD
					)})일 * (${roundUp2(daysNWTHoli)})휴일야간근로시간 * 0.5배] * 통상시급
          단, 위 동 수당은 년간 ${numberToCommaString(
						numOfYearlyHoliWD
					)}일에 해당하는 약정휴일야간근로수당을 12개월로 나눈 값으로 12개월을 채우지 아니하는 경우 등의 이유로 휴일근로를 제공했음에도 불구하고 차액이 발생하는 경우 그 차액을 지급해야 한다.
        `}
				</li>
			) : null}
			{cAWage1 ? (
				<li>
					{`[평균임금] ${cAWEName1} : ${numberToCommaString(
						cAWage1
					)}원, ${cAWECondi1}`}
				</li>
			) : null}
			{cAWage2 ? (
				<li>
					{`[평균임금] ${cAWEName2} : ${numberToCommaString(
						cAWage2
					)}원, ${cAWECondi2}`}
				</li>
			) : null}
			{cAWage3 ? (
				<li>
					{`[평균임금] ${cAWEName3} : ${numberToCommaString(
						cAWage3
					)}원, ${cAWECondi3}`}
				</li>
			) : null}

			{cAWage4 ? (
				<li>
					{`[평균임금] ${cAWEName4} : ${numberToCommaString(
						cAWage4
					)}원, ${cAWECondi4}`}
				</li>
			) : null}
			{cAWageTF1 ? (
				<li>
					{`[평균임금] ${cAWENameTF1} : ${numberToCommaString(
						cAWageTF1
					)}원, ${cAWECondiTF1}`}
				</li>
			) : null}
			{cAWageTF2 ? (
				<li>
					{`[평균임금] ${cAWENameTF2} : ${numberToCommaString(
						cAWageTF2
					)}원, ${cAWECondiTF2}`}
				</li>
			) : null}
			{cAWageTF3 ? (
				<li>
					{`[평균임금] ${cAWENameTF3} : ${numberToCommaString(
						cAWageTF3
					)}원, ${cAWECondiTF3}`}
				</li>
			) : null}
			{cAWageTF4 ? (
				<li>
					{`[평균임금] ${cAWENameTF4} : ${numberToCommaString(
						cAWageTF4
					)}원, ${cAWECondiTF4}`}
				</li>
			) : null}
		</ol>
	);
	//회사 국어조사
	const companyWa = josaWaType(companyName);
	const companyUn = josaUnType(companyName);
	const companyGa = josaGaType(companyName);

	//직원 국어조사
	const workerUn = josaUnType(worker);
	const workerGa = josaGaType(worker);
	const workerWa = josaWaType(worker);

	function choDan() {
		return weekLBTime < 15 ? wageDetailUnder15 : wageDetailOver15;
	}

	const payDayBeforeAfter =
		selectedClientReq4In?.monthlyPay?.payDayBeforeAfter === '지급일 다음 근무일'
			? '지급일 다음 근무일'
			: '지급일 전근무일';

	return (
		<>
			<div className='jau__contents section'>
				<div></div>
				<span style={{ fontSize: '1rem' }}>
					<strong>제1조 임금계약 기간</strong>
				</span>
				<ul>
					<li>
						{`① ${companyWa} ${worker}의 임금계약 기간은 ${cWageContractSDate}부터 ${cWageContractEDate}로 한다.`}
					</li>
					<li>
						{`② ${workerUn} 제1항의 임금계약기간 만료 후 ${companyWa} 별도의 임금 계약이 체결되기 전까지는 임금과 관련된 근로조건은 
            해당년도 최저임금법에 위반되지 않는 이상 본 임금계약서에 따른다.   `}
					</li>
				</ul>
			</div>
			<div className='jau__contents section'>
				<span style={{ fontSize: '1rem' }}>
					<strong>제2조 임금의 구성항목, 지급방법 및 계산방법 등</strong>
				</span>
				<ul>
					<>
						<li>
							{`① ${companyUn} ${workerGa} ${cStartToEndDate}까지 약정한 근로계약서에 의한 근로를 모두 제공하면`}
							<strong style={{ color: 'blue' }}>{` 월급 ${numberToCommaString(
								cTotalMonthWageAmount
							)}원`}</strong>
							{`을 매월 ${cPayDay}[지급일이 휴일인 경우 ${payDayBeforeAfter}]에 ${worker}에게 통화 또는 요구불 예금으로 직접 지급하되, 
                  4대보험료와 세금, ${workerGa} 동의한 금품(가불금 등), 
                  급여과오납 정산금 또는 확정된 손해배상액 등 법률에 의해 
                  공제 가능한 금품은 원천징수(공제)하고 지급한다.
                `}
						</li>
						<li>
							{`② 제1항의 ${companyGa} ${worker}에게 지급하는 임금(월급)의 구성항목 및 계산방법은 다음과 같다. `}
						</li>
						<li></li>
						{choDan()}
						{perDayMealTaxFree ? (
							<li>
								{`③ ${companyUn} ${worker}에게 근무월 중 근로의무가 있는 날 소정근로시간 외의 약정근무 80%이상인 경우 근무한 날 1일당 ${numberToCommaString(
									Math.ceil(perDayMealTaxFree)
								)}원(평균임금)의 근무식대를 월급 총액과 별도 지급한다.`}
							</li>
						) : null}
					</>
				</ul>
			</div>
			<div className='jau__contents section'>
				<span style={{ fontSize: '1rem' }}>
					<strong>제3조 시간급 통상임금 및 최저시급, 주휴수당</strong>
				</span>
				<ul>
					<li>{`① ${worker}의 월 통상임금 산정기준 시간수, 통상시급, 최저시급은 다음과 같다.`}</li>
					{numOfWorkers >= 5 ? (
						<ol>
							{weekLBTime < 15 ? (
								<li>
									{`월 통상임금 산정기준 시간수 : ${numberToCommaString(
										roundUp2(monthLBTime)
									)}시간 
                      [(${
												isExceptTimeLawWorker
													? '1주 기본근로시간'
													: '1주 소정근로시간'
											} ${numberToCommaString(
										roundUp2(weekLBTime)
									)})] / 7일 * 365일 /12개월(초단시간 근로자로 유급주휴시간 없음.)`}
								</li>
							) : (
								<>
									{isExceptTimeLawWorker ? (
										<li>
											{`월 통상임금 산정기준 시간수 : ${numberToCommaString(
												roundUp2(monthLBTime)
											)}시간 
                          [(${
														isExceptTimeLawWorker
															? '1주 기본근로시간'
															: '1주 소정근로시간'
													} ${numberToCommaString(
												roundUp2(weekLBTime)
											)})] / 7일 * 365일 /12개월`}
										</li>
									) : (
										<li>
											{`월 통상임금 산정기준 시간수 : ${numberToCommaString(
												roundUp2(monthLBTime)
											)}시간 
                          [(${
														isExceptTimeLawWorker
															? '1주 기본근로시간'
															: '1주 소정근로시간'
													} ${numberToCommaString(
												roundUp2(weekLBTime)
											)})  + (유급주휴시간 ${numberToCommaString(
												paidHolidayLBTime
											)})] / 7일 * 365일 /12개월`}
										</li>
									)}
								</>
							)}
							<li>
								통상시급 : {numberToCommaString(roundUp(hourOrdinaryWage))}원
								[통상임금의 합계{' '}
								{numberToCommaString(roundUp2(monthOrdinaryWage))}원 / 월
								통상임금산정기준 시간수(
								{numberToCommaString(roundUp2(monthLBTime))})]
							</li>
							<li>
								최저임금시급 : {numberToCommaString(roundUp(calHourLowestWage))}
								원 [최저임금의 합계{' '}
								{numberToCommaString(roundUp2(calTotalMonthLowestWage))}원 / 월
								최저임금산정기준 시간수(
								{numberToCommaString(roundUp2(monthLBTime))})]
							</li>
							<li>
								{isExceptTimeLawWorker
									? '주휴수당 : 근로기준법 제63조에 따라 주휴수당 해당 없음.'
									: `주휴수당 : ${paidHolidayLBTimeMonthWage}원[
										${paidHolidayLBTime} (1주 주휴시간) / 7 *365 / 12 *
										${numberToCommaString(hourOrdinaryWage)}
										(통상시급)], 근로기준법 제55조에 따른 월간 주휴수당`}
							</li>
						</ol>
					) : (
						<ol>
							<li>
								{`
                    월 통상임금 산정기준 시간수 : 월 전체 ${numberToCommaString(
											roundUp(mTPCTime)
										)} 
                    시간(5인미만 사업장은 근로기준법 제50조 근로시간과 동법 제2조 소정근로시간, 동법 제56조 연장야간휴일근로 
                    가산임금이 적용되지 아니하므로 1개월의 총 시간을 뜻함.)
										[(1주 ${numberToCommaString(
											roundUp2(weekLBTime)
										)}시간(실근로))  + (유급주휴시간 ${numberToCommaString(
									paidHolidayLBTime
								)})] / 7일 * 365일 /12개월
                  `}
							</li>

							<li>
								통상시급 : {numberToCommaString(roundUp(hourOrdinaryWage))}원
								[통상임금의 합계 {numberToCommaString(monthOrdinaryWage)}원 / 월
								통상임금 산정기준 시간수{' '}
								{numberToCommaString(roundUp(mTPCTime))}시간]
							</li>
							<li>
								최저임금시급 : {numberToCommaString(roundUp(calHourLowestWage))}
								원 [최저임금 판단 전체월급{' '}
								{numberToCommaString(roundUp(calTotalMonthLowestWage))}원 / 월
								최저임금 산정기준 시간수{' '}
								{numberToCommaString(roundUp(mTPCTime))}시간]
							</li>
							<li>
								{isExceptTimeLawWorker
									? '주휴수당 : 근로기준법 제63조에 따라 주휴수당 해당 없음.'
									: `주휴수당 : ${paidHolidayLBTimeMonthWage}원[
										${paidHolidayLBTime} (1주 주휴시간) / 7 *365 / 12 *
										${numberToCommaString(hourOrdinaryWage)}
										(통상시급)], 근로기준법 제55조에 따른 월간 주휴수당`}
							</li>
						</ol>
					)}
					{numOfWorkers < 5 && (
						<>
							<li>
								{`② 향후 ${companyName}의 근로자수가 5인이상으로 변경되는 경우 근로계약서 제5조의 근로시간 등이 같을 때에는 시급 계산방법 변경으로 인해 전조의 통상시급 및 최저시급이 낮아 지더라도
                    ${worker}의 월급이 최저임금법에 위반되지 않은 이상 제2조 제1항의 월급 ${numberToCommaString(
									cTotalMonthWageAmount
								)}원을 받는 것에 대해 동의합니다.
                  `}
							</li>
							<b>
								<li style={{ color: 'blue' }}>
									{`위 ②항 동의자 : ${worker} (인 또는 sign)`}
								</li>
							</b>
						</>
					)}
				</ul>
			</div>
			<div className='jau__contents section'>
				<span style={{ fontSize: '1rem' }}>
					<strong>제4조 추가 지급 및 결근 공제</strong>
				</span>
				<ul>
					<li>
						{`① ${companyUn} 제2조 월급에 포함된 약정 연장,야간,휴일근로 시간 및 수당(있는 경우에 한함.) 외에 추가로 ${companyWa} ${workerGa} 합의한 연장근로 또는 ${companyGa} 지시한 야간 및 휴일근로가 있는 경우에는 해당 임금을 추가 지급하고, ${worker}의 귀책사유로 지각, 조퇴, 외출할 경우 해당 시간만큼 상기 임금에서 공제한다.`}
					</li>
					{weekLBTime < 15 ? (
						<li>
							{`② ${companyUn} ${worker}의 귀책사유로 결근하는 경우 결근에 따른 징계 여부는 별론으로 하고, 결근일의 임금은 상기 임금에서 공제된다.`}
						</li>
					) : (
						<li>
							{`② ${companyUn} ${worker}의 귀책사유로 결근하는 경우 결근에 따른 징계 여부는 별론으로 하고, 해당 주의 주휴수당도 결근일과 함께 공제된다.`}
						</li>
					)}
					<li>
						{`③  ${companyUn} ${workerGa} 민법상 역월 기준으로 중도에 입퇴사하는 경우와 ${workerGa} 제2항에 따른 결근시 해당 월 일수의 다소를 불문하고, 월 통상임금을 해당 월의 일수(예, 28일인 달은 28일, 29일인 달은 29일, 30일인 달은 30일, 31일인 달은 31일)로 일할 계산한 일급에 ${workerGa} 해당월에 재직한 일수를 곱해서 월급을 책정한다.
            (단시간 근로자(초단시간 근로자 포함)의 경우 임금명세서상 일할 계산이 재 보정될 수 있음.)
            단, 전체월급에 약정연장/약정야간/약정휴일근로수당(이하 "약정초과수당"이라 한다.)이 있는 경우는 월 통상임금에 약정초과수당을 더한 월 임금을 일할 계산한다. `}
					</li>
				</ul>
			</div>
			{weekLBTime < 15 ? (
				<>
					<div className='jau__contents section'>
						<span style={{ fontSize: '1rem' }}>
							<strong>제5조 퇴직금</strong>
						</span>
						{`① ${workerUn} 1주 소정근로시간은 ${weekLBTime}시간인 초단시간 근로자로 근로자퇴직급여보장법 제4조 단서에 따라 퇴직금 관련 조항이 적용되지 아니하므로 ${companyUn} ${worker}에게 퇴직금을 지급하지 아니한다.`}
					</div>
				</>
			) : (
				<>
					<div className='jau__contents section'>
						<span style={{ fontSize: '1rem' }}>
							<strong>제5조 퇴직금</strong>
						</span>
						<ul>
							<li>
								{`① 퇴직금 : ${companyUn} ${worker}의 입사부터 퇴사까지 계속 근로 연수(군복무휴직과 근로자 개인 청원 휴직(학업 휴직 등)을 제외한 모든 휴직기간을 포함.) 
                1년당 30일분 이상의 평균임금을 퇴직금으로 ${workerGa} 퇴직할 때 퇴직금(법정 퇴직연금 포함)을 퇴직일로부터 14일 이내에 지급한다.`}
							</li>
							<li>
								{`② ${companyGa} 퇴직연금(DB형은 중도인출 불가, DC형 중도인출 사유는 근로자퇴직급여보장법 시행령 제14조에 따름.)이 아닌 법정퇴직금 제도를 운영하는 경우 다음 각 호의 퇴직금 중간정산 사유가 있는 경우에 ${companyUn} ${worker}의 요청에 따라 ${worker}의 기왕의 근로에 대한 퇴직금을 중간정산하여 지급할 수 있다.`}
							</li>
							<ol>
								<li>무주택자인 {workerGa} 본인 명의로 주택을 구입하는 경우</li>
								<li>
									무주택자인 {workerGa} 주거를 목적으로 「민법」 제303조에 따른
									전세금 또는 「주택임대차보호법」 제3조의2에 따른 보증금을
									부담하는 경우. 이 경우 {workerGa} {companyName}에 근로하는
									동안 1회로 한정한다.
								</li>
								<li>
									{workerGa} 6개월 이상 요양을 필요로 하는 다음 각 목의 어느
									하나에 해당하는 사람의 질병이나 부상에 대한 의료비를 해당{' '}
									{workerGa} 본인 연간 임금총액의 1천분의 125를 초과하여
									부담하는 경우
								</li>
								<ol>
									<li>{worker} 본인</li>
									<li>{worker}의 배우자</li>
									<li>{worker} 또는 그 배우자의 부양가족</li>
								</ol>
								<li>
									퇴직금 중간정산을 신청하는 날부터 거꾸로 계산하여 5년 이내에{' '}
									{workerGa}「채무자 회생 및 파산에 관한 법률」에 따라
									파산선고를 받은 경우
								</li>
								<li>
									퇴직금 중간정산을 신청하는 날부터 거꾸로 계산하여 5년 이내에{' '}
									{workerGa}「채무자 회생 및 파산에 관한 법률」에 따라
									개인회생절차개시 결정을 받은 경우
								</li>
								<li>
									{companyGa} 기존의 정년을 연장하거나 보장하는 조건으로
									단체협약 및 취업규칙 등을 통하여 일정 나이, 근속시점 또는
									임금액을 기준으로 임금을 줄이는 제도를 시행하는 경우
								</li>
								<li>
									{companyGa} {workerWa}의 합의에 따라 소정근로시간을 1일 1시간
									또는 1주 5시간 이상 변경하여 그 변경된 소정근로시간에 따라{' '}
									{workerGa} 3개월 이상 계속 근로하기로 한 경우
								</li>
								<li>
									법률 제15513호 근로기준법 일부개정법률의 시행에 따른
									근로시간의 단축으로 {worker}의 퇴직금이 감소되는 경우
								</li>
								<li>
									재난으로 피해를 입은 경우로서 고용노동부장관이 정하여 고시하는
									사유에 해당하는 경우
								</li>
							</ol>
							<li>
								{`③ ${companyUn} ${worker}에 대해 제2항의 퇴직금 중간정산을 한 경우 ${worker}의 계속 근로 연수는 정산일부터 새로이 기산하되 중간정산 
                이후 최종 퇴직일까지 기간이 1년 미만이라 하더라도 ${companyUn} ${worker}에게 동 기간의 퇴직금을 지급해야 한다.`}
							</li>
						</ul>
					</div>
				</>
			)}
			<div className='jau__contents section'>
				<span style={{ fontSize: '1rem' }}>
					<strong>제6조 금품청산 및 비밀유지의무</strong>
				</span>
				<ul>
					<li>
						{`① ${workerUn} 본 임금계약서 제1조 내지 제5조에도 불구하고 근로기준법 제36조(금품청산) 단서조항에 의거 건강보험정산, 
            회사 대여금 정산 등의 사유로 퇴직금을 포함한 근로관계로 인한 모든 금품 지급을 ${worker}의 퇴직월 다음달 15일 이전까지 연장하는 것에 대해 동의한다.`}
					</li>
					<b>
						<li style={{ color: 'blue' }}>
							{`위 ①항 동의자 : ${worker} (인 또는 sign)`}
						</li>
					</b>
					<li>
						{`② ${workerUn} 상기 임금을 동료 직원을 포함한 회사 내외 모든 타인에게 비밀로 해야 하며, 누설 시 어떠한 인사상 불이익도 감수하는 것에 대해 동의한다.`}
					</li>
					<b>
						<li style={{ color: 'blue' }}>
							{`위 ②항 동의자 : ${worker} (인 또는 sign)`}
						</li>
					</b>
				</ul>
			</div>
			<div className='jau__contents section'>
				<span style={{ fontSize: '1rem' }}>
					<strong>제7조 관할 법원 및 준용 규정</strong>
				</span>
				<ul>
					<li>
						{`① ${companyWa} ${workerWa}의 법적인 해결이 필요한 경우 소송은 ${companyName} 소지재 관할 법원으로 한다.`}
					</li>
					<li>
						{`② 본 계약서에 명시하지 아니한 사항에 대하여서는 노동관계법 및 ${companyGa} 정하는 제 규정을 준용한다.`}
					</li>
				</ul>
			</div>
			{wageComment && (
				<div className='jau__contents'>
					<span style={{ fontSize: '1rem' }}>
						<strong>제8조 임금계약 특약사항</strong>
					</span>
					<ul>
						<li>{`* ${companyWa} ${workerUn} 다음과 같이 특약한다.`}</li>
						<li>{renderWithNewline(wageComment)}</li>
						<b>
							<li style={{ color: 'blue' }}>
								본 조항 확인자 : {worker} (인 또는 sign)
							</li>
						</b>
					</ul>
				</div>
			)}
			<DeawangOilSC worker={worker} companyName={companyName} />
		</>
	);
}
