import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../../app/config/firebase';
import { Tab, Table, Input, Button } from 'semantic-ui-react';
import * as XLSX from 'xlsx';
import PaydocuShortCut from '../paydocuDashboard/PaydocuShortCut';
import { useSelector } from 'react-redux';

function AnnualLeavesDataTable() {
	const [annualLeavesData, setAnnualLeavesData] = useState([]);
	const [filteredData, setFilteredData] = useState([]);
	const [summaryData, setSummaryData] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [searchName, setSearchName] = useState('');

	const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
	const { currentUserProfile } = useSelector((state) => state.profile);

	const { filter, clientInfo } = useSelector((state) => state.companyReducer);
	const { clientReqs4In } = useSelector((state) => state.clientReq4InStore);
	const isWorker = currentUserProfile?.isWorker;

	const companyName = clientInfo?.companyName
		? clientInfo?.companyName
		: currentUserProfile?.companyName;

	useEffect(() => {
		const fetchAnnualLeavesData = async () => {
			try {
				setIsLoading(true);
				const querySnapshot = await getDocs(collection(db, 'annualLeaves'));

				const data = querySnapshot.docs
					.map((doc) => ({
						id: doc.id,
						...doc.data(),
					}))
					// companyName이 같은 것만 필터링
					.filter((record) => record.companyName === companyName);

				setAnnualLeavesData(data);
				setFilteredData(data);
				setSummaryData(calculateEmployeeSummary(data));
				setIsLoading(false);
			} catch (error) {
				console.error('데이터 불러오기 실패:', error);
				setIsLoading(false);
			}
		};

		fetchAnnualLeavesData();
	}, [companyName]); // companyName이 변경되면 다시 실행

	// 요일을 한국어로 변환하는 함수
	const getDayOfWeek = (dateString) => {
		const days = ['일', '월', '화', '수', '목', '금', '토'];
		const date = new Date(dateString);
		return days[date.getDay()];
	};

	// 직원 이름별 사용 연차 총합 계산 함수
	const calculateEmployeeSummary = (data) => {
		const summary = {};

		data.forEach((record) => {
			const { workerName, annualLeavesData } = record;

			const leaves = Array.isArray(annualLeavesData)
				? annualLeavesData
				: [annualLeavesData];

			if (!summary[workerName]) {
				summary[workerName] = 0;
			}

			leaves.forEach((leave) => {
				const usedDays = leave?.used || 0;
				summary[workerName] += usedDays;
			});
		});

		return Object.entries(summary).map(([workerName, totalUsedDays]) => ({
			workerName,
			totalUsedDays,
		}));
	};

	// 이름으로 데이터 필터링
	const handleSearch = () => {
		if (searchName.trim() === '') {
			setFilteredData(
				annualLeavesData.filter((record) => record.companyName === companyName)
			);
		} else {
			const filtered = annualLeavesData.filter(
				(record) =>
					record.companyName === companyName &&
					record.workerName.includes(searchName)
			);
			setFilteredData(filtered);
		}
	};

	// 데이터 정렬 함수
	const handleSort = (key) => {
		let direction = 'asc';
		if (sortConfig.key === key && sortConfig.direction === 'asc') {
			direction = 'desc';
		}

		const sortedData = [...filteredData].sort((a, b) => {
			const leavesA = Array.isArray(a.annualLeavesData)
				? a.annualLeavesData[0]
				: a.annualLeavesData || {};
			const leavesB = Array.isArray(b.annualLeavesData)
				? b.annualLeavesData[0]
				: b.annualLeavesData || {};

			let valueA, valueB;

			// 정렬 기준에 따라 값을 추출
			if (key === '날짜') {
				valueA = new Date(leavesA?.Date || '');
				valueB = new Date(leavesB?.Date || '');
			} else if (key === '사용 연차') {
				valueA = leavesA?.used || 0;
				valueB = leavesB?.used || 0;
			} else if (key === '제목') {
				valueA = leavesA?.title || '';
				valueB = leavesB?.title || '';
			} else if (key === '내용') {
				valueA = leavesA?.content || '';
				valueB = leavesB?.content || '';
			} else if (key === '회사 이름') {
				valueA = leavesA?.companyName || '';
				valueB = leavesB?.companyName || '';
			} else {
				// 직원명처럼 상위 필드
				valueA = a[key] || '';
				valueB = b[key] || '';
			}

			// 정렬 방향에 따라 값 비교
			if (typeof valueA === 'string' && typeof valueB === 'string') {
				return direction === 'asc'
					? valueA.localeCompare(valueB)
					: valueB.localeCompare(valueA);
			} else {
				return direction === 'asc' ? valueA - valueB : valueB - valueA;
			}
		});

		setFilteredData(sortedData);
		setSortConfig({ key, direction });
	};

	// 데이터 엑셀로 다운로드
	const exportToExcel = () => {
		const rows = [];
		filteredData.forEach((record) => {
			const leaves = Array.isArray(record.annualLeavesData)
				? record.annualLeavesData
				: [record.annualLeavesData];

			leaves.forEach((leave) => {
				rows.push({
					직원명: record.workerName,
					날짜: `${leave.Date} (${getDayOfWeek(leave.Date)})`,
					사용연차: leave.used,
					제목: leave.title,
					내용: leave.content,
				});
			});
		});

		const worksheet = XLSX.utils.json_to_sheet(rows);
		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, worksheet, 'Annual Leaves');
		XLSX.writeFile(workbook, 'Annual_Leaves_Data.xlsx');
	};

	// 왼쪽 탭: 모든 연차 데이터 표시
	const renderAnnualLeavesData = () => (
		<div>
			<div style={{ marginBottom: '10px' }}>
				<Input
					placeholder='직원 이름을 입력하세요...'
					value={searchName}
					onChange={(e) => setSearchName(e.target.value)}
					action={{
						icon: 'search',
						onClick: handleSearch,
					}}
				/>
				<Button
					color='blue'
					onClick={exportToExcel}
					style={{ marginLeft: '10px' }}>
					엑셀 다운로드
				</Button>
			</div>
			<Table celled sortable>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell onClick={() => handleSort('companyName')}>
							회사 이름
						</Table.HeaderCell>
						<Table.HeaderCell onClick={() => handleSort('workerName')}>
							직원명
						</Table.HeaderCell>
						<Table.HeaderCell onClick={() => handleSort('날짜')}>
							날짜
						</Table.HeaderCell>
						<Table.HeaderCell onClick={() => handleSort('사용 연차')}>
							사용 연차
						</Table.HeaderCell>
						<Table.HeaderCell onClick={() => handleSort('제목')}>
							제목
						</Table.HeaderCell>
						<Table.HeaderCell onClick={() => handleSort('내용')}>
							내용
						</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{filteredData.map((record) => {
						const leaves = Array.isArray(record.annualLeavesData)
							? record.annualLeavesData
							: [record.annualLeavesData];

						return leaves.map((leave, index) => {
							const formattedDate = leave?.Date
								? `${leave.Date} (${getDayOfWeek(leave.Date)})`
								: '-';

							return (
								<Table.Row key={`${record.id}-${index}`}>
									<Table.Cell>{record.companyName || '-'}</Table.Cell>
									<Table.Cell>{record.workerName}</Table.Cell>
									<Table.Cell>{formattedDate}</Table.Cell>
									<Table.Cell>{leave?.used || '-'}</Table.Cell>
									<Table.Cell>{leave?.title || '-'}</Table.Cell>
									<Table.Cell>{leave?.content || '-'}</Table.Cell>
								</Table.Row>
							);
						});
					})}
				</Table.Body>
			</Table>
		</div>
	);

	// 오른쪽 탭: 직원별 사용 연차 총합 표시
	const renderEmployeeSummary = () => (
		<Table celled>
			<Table.Header>
				<Table.Row>
					<Table.HeaderCell>직원명</Table.HeaderCell>
					<Table.HeaderCell>사용 연차 총합</Table.HeaderCell>
				</Table.Row>
			</Table.Header>
			<Table.Body>
				{summaryData.map((summary, index) => (
					<Table.Row key={index}>
						<Table.Cell>{summary.workerName}</Table.Cell>
						<Table.Cell>{summary.totalUsedDays}</Table.Cell>
					</Table.Row>
				))}
			</Table.Body>
		</Table>
	);

	// 탭 구성
	const panes = [
		{
			menuItem: '전체 데이터',
			render: () => (
				<Tab.Pane loading={isLoading}>{renderAnnualLeavesData()}</Tab.Pane>
			),
		},
		{
			menuItem: '직원별 연차 합계',
			render: () => (
				<Tab.Pane loading={isLoading}>{renderEmployeeSummary()}</Tab.Pane>
			),
		},
	];

	return (
		<div>
			{isWorker ? null : (
				<PaydocuShortCut filter={filter} clientReqs4In={clientReqs4In} />
			)}{' '}
			<h1>Annual Leaves 데이터 조회</h1>
			<Tab panes={panes} />
		</div>
	);
}

export default AnnualLeavesDataTable;
