import React from 'react';
import { Button, Accordion, Icon, Label } from 'semantic-ui-react';

export default function ClientReqInfoDashboard4In({
	activeIndex,
	handleAccordionClick,
	reloadToClientReqs4InInfo,
	reloadToClientReqs4InNewYearLawInfo,
	lastPreventData,
	lastPreventDataShow,
	Link,
}) {
	const handleParagraphClick = (index) => {
		handleAccordionClick(index);
	};
	return (
		<>
			<div
				style={{
					display: 'flex',
					alignItems: 'flex-start',
					gap: '20px',
				}}>
				{/* 첫 번째 Accordion */}
				<Accordion styled>
					<Accordion.Title
						active={activeIndex === 0}
						index={0}
						onClick={() => handleAccordionClick(0)}>
						<Icon name='dropdown' />
						<span style={{ fontWeight: 'bold', color: 'red' }}>
							2025년 변경 노동법
						</span>
					</Accordion.Title>
					<Accordion.Content active={activeIndex === 0}>
						<div onClick={() => handleParagraphClick(1)}>
							<p>2025년 최저임금: 10,030원</p>
							<p>월 최저임금 (주 40시간 기준): 2,096,270원</p>
						</div>
						<br />
						<Button color='blue' onClick={reloadToClientReqs4InNewYearLawInfo}>
							전체보기
						</Button>
					</Accordion.Content>
				</Accordion>

				{/* 두 번째 Accordion */}
				<Accordion styled>
					<Accordion.Title
						active={activeIndex === 1}
						index={1}
						onClick={() => handleAccordionClick(1)}>
						<Icon name='dropdown' />
						<span style={{ fontWeight: 'bold', color: 'red' }}>What's New</span>
					</Accordion.Title>
					<Accordion.Content active={activeIndex === 1}>
						<div onClick={() => handleParagraphClick(1)}>
							<p>25년 1월 1일: 직원별 근로시간 정보, 임금정보 조회 기능 추가</p>
							<p>25년 1월 1일: 계약기간 만료일 표시 기능 추가</p>
							<p>25년 1월 3일: 근로계약서, 임금계약서 특약 입력 기능 추가</p>
							<p>
								25년 1월 5일 : 직원 List 이름순, 입사일순 정렬, 정보
								accordion으로 디자인 개선 추가
							</p>
							<p> 25년 1월 13일 : 년도별 직원 전체 연차 현황 조회 기능 추가</p>
							<p>
								25년 1월 15일 : 회사 임의 부여 연차일수 추가 연차계산 로직 추가
							</p>
							<p>
								25년 1월 17일 : 연차사용일, 결근일, 지각/조퇴/외출 등 근태 내역
								명확히 DB에 저장
							</p>
							<p>
								25년 1월 18일 : 연차사용 반차, 반반차 등 소수점 사용 가능하도록
								로직 추가
							</p>
							<p>
								25년 1월 19일 : 월급직 임금계약서에 주휴수당 금액과 계산방법
								표시 기능 추가
							</p>
							<p>
								* 25년 1월 21일 : 연도별 퇴사자 연차 계산 로직 추가 및 연차대장
								개발
							</p>
						</div>
						<br />
						<Button color='blue' onClick={reloadToClientReqs4InInfo}>
							전체보기
						</Button>
					</Accordion.Content>
				</Accordion>

				{/* 세 번째 Accordion: 고용안정지원금 감원방지기간 안내 */}
				<Accordion styled>
					<Accordion.Title
						active={activeIndex === 2}
						index={2}
						onClick={() => handleAccordionClick(2)}>
						<Icon name='dropdown' />
						<span style={{ fontWeight: 'bold', color: 'red' }}>
							고용안정지원금 감원방지기간 안내
						</span>
					</Accordion.Title>
					<Accordion.Content active={activeIndex === 2}>
						<div onClick={() => handleParagraphClick(2)}>
							{/* ClientReqDashboard4InAuthenticated 내용 */}
							{lastPreventData && lastPreventDataShow ? (
								<>
									<Label>{lastPreventData[3]}</Label>
									<Label>{lastPreventData[4]}</Label>
									<Label>{lastPreventData[5]}</Label>
									<hr />
								</>
							) : (
								<>
									<h3
										style={{ color: 'blue' }}
										as={Link}
										to={`/pricePolicyPaper`}>
										중요 : 입력된 퇴사자 정보에 의하면 고용안정지원금
										감원방지기간에 해당되지 않습니다.
									</h3>
									<hr />
								</>
							)}
						</div>
					</Accordion.Content>
				</Accordion>
			</div>
		</>
	);
}
