import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Dropdown, Menu } from 'semantic-ui-react';

export default function RiskSuspectMenu({ FaHelmetSafety }) {
	const history = useHistory();

	const [dropdownOpen, setDropdownOpen] = useState(false);
	const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 1000);
		};

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	const handleMouseOver = () => {
		setDropdownOpen(true);
	};

	const handleMouseLeave = () => {
		setDropdownOpen(false);
	};

	const handleDropdownToggle = () => {
		setDropdownOpen(!dropdownOpen);
	};

	function reloadToRiskSuspectReports() {
		history.push('/riskSuspectReports');
	}

	const navigateToCreateRiskSuspectReport = () => {
		history.push('/createRiskSuspectReport');
	};

	return (
		<Menu.Item
			className='menu-item'
			onMouseOver={handleMouseOver}
			onMouseLeave={handleMouseLeave}>
			{!isMobile && <FaHelmetSafety className='icon-spacing' />}
			<Dropdown
				pointing='top left'
				text='안전보건 확보'
				open={dropdownOpen}
				onClick={handleDropdownToggle}>
				<Dropdown.Menu>
					<Dropdown.Item
						onClick={navigateToCreateRiskSuspectReport}
						text='안전보건활동 입력'
					/>
					<Dropdown.Item
						onClick={() => reloadToRiskSuspectReports()}
						text='안전보건활동 REPORT'
					/>
				</Dropdown.Menu>
			</Dropdown>
		</Menu.Item>
	);
}
