import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Sidebar, Menu, Divider } from 'semantic-ui-react';
import SignedInMenu from './SignedInMenu';
import SignedOutMenu from './SignedOutMenu';
// import RuleOfEmployeeMenu from './RuleOfEmployeeMenu';
// import CompetencyMenu from './CompetencyMenu';
import PaydocuMenu from './PaydocuMenu';
import NomuWorksMenu from './NomuWorksMenu';
import { getWorkerUserProfile } from '../../app/firestore/firestoreService4In';
import { listenToWorkerUsers } from '../profiles/profileActions';
import { dataFromSnapshotIdDate } from '../../app/firestore/firestoreService';
// import RiskSuspectMenu from './RiskSuspectMenu';
import { freeComClient, isAgentF } from '../../app/common/util/util';
import WorkerManageMenu from './WorkerManageMenu';
import PaydocuAnnualLeaveMenu from './PaydocuAnnualLeaveMenu';
// import PaydocuEvaluationMenu from './PaydocuEvaluationMenu';
// import NoticeEtcMenu from './NoticeEtcMenu';
import CalculatorMenu from './CalculatorMenu';
import PriceIntroMenu from './PriceIntroMenu';
import PaydocuTableMenu from './PaydocuTableMenu';
// import ChatBotMenu from './ChatBotMenu';
// import { TbMessageChatbot } from "react-icons/tb";
import { MdOutlinePriceCheck } from 'react-icons/md';
import { FaMousePointer } from 'react-icons/fa';

// import { FaBookMedical, FaMousePointer } from 'react-icons/fa';
// import { HiInformationCircle } from 'react-icons/hi';
// import { GrVulnerability } from 'react-icons/gr';
// import { FaHelmetSafety } from 'react-icons/fa6';
// import { BsFillFileEarmarkRuledFill } from 'react-icons/bs';
// import { VscHorizontalRule } from 'react-icons/vsc';
import { GrUserAdmin } from 'react-icons/gr';
import { CiCalculator1 } from 'react-icons/ci';
import { FcLeave } from 'react-icons/fc';
import { FaWonSign } from 'react-icons/fa';
import { PiUserListFill } from 'react-icons/pi';
import { MdSecurityUpdateGood } from 'react-icons/md';
import InsureRegister from './InsureRegister';
import CompanyContractMenu from './CompanyContractMenu';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const MySidebar = () => {
	const dispatch = useDispatch();
	const [searchQuery, setSearchQuery] = useState('');

	const history = useHistory();
	const { currentUserProfile } = useSelector((state) => state.profile);
	const { authenticated } = useSelector((state) => state.auth);
	const { clientReqs4In } = useSelector((state) => state.clientReq4InStore);
	const { clientInfo, superAdminForFreeTrue } = useSelector(
		(state) => state.companyReducer
	);

	const authLevel =
		currentUserProfile?.authLevel > 100
			? currentUserProfile?.authLevel
			: clientInfo?.authLevel
			? clientInfo?.authLevel
			: currentUserProfile?.authLevel;

	const ceoName = clientInfo?.ceoName
		? clientInfo?.ceoName
		: currentUserProfile?.ceoName;
	// const personalEvaluationYes =
	// 	clientInfo?.personalEvaluation === 'BSC 기반 인사고과 기능 사용'
	// 		? true
	// 		: currentUserProfile?.personalEvaluation === 'BSC 기반 인사고과 기능 사용'
	// 		? true
	// 		: false;
	// const viewId = clientInfo?.id || currentUserProfile?.id;

	useEffect(() => {
		if (currentUserProfile) {
			// currentUserProfile이 존재할 때만 로직 실행
			let isMounted = true;
			const unSubscribe = getWorkerUserProfile({
				next: (snapshot) => {
					if (isMounted) {
						dispatch(
							listenToWorkerUsers(
								snapshot.docs.map((docSnapshot) =>
									dataFromSnapshotIdDate(docSnapshot)
								)
							)
						);
					}
				},
				error: (error) => console.log(error),
			});

			return () => {
				unSubscribe();
				isMounted = false;
			};
		}
	}, [dispatch, currentUserProfile]); // 의존성 배열에 currentUserProfile 추가

	// // "AI노무사 매뉴얼 다운" 클릭 시 파일 다운로드 함수
	// const downloadManual = () => {
	// 	const link = document.createElement('a');
	// 	link.href = '/AI 노무사 매뉴얼.pdf'; // 실제 PDF 파일의 경로로 변경
	// 	link.download = 'AI_노무사_사용매뉴얼.pdf';
	// 	link.click();
	// };

	// const isCoWorkSemusa =
	// 	currentUserProfile?.isAgent === '스폰서' &&
	// 	currentUserProfile.agentType === 'bs세무사'
	// 		? true
	// 		: false;
	const companyName = clientInfo?.companyName
		? clientInfo?.companyName
		: currentUserProfile?.companyName;
	const retirementPayDC =
		clientInfo?.retirementPay === 'DC형 퇴직연금'
			? true
			: currentUserProfile?.retirementPay === 'DC형 퇴직연금'
			? true
			: false;

	const isSuperAdminForFix = currentUserProfile?.authLevel >= 110;

	// const viewId = clientInfo?.id || currentUserProfile?.id;
	// const isWorker = false;

	// const roeConsulting = clientInfo?.roeConsulting
	// 	? clientInfo?.roeConsulting
	// 	: currentUserProfile?.roeConsulting;
	// const rlcExcuteDueDate = clientInfo?.rlcExcuteDueDate
	// 	? clientInfo?.rlcExcuteDueDate
	// 	: currentUserProfile?.rlcExcuteDueDate;
	// const rOEBizSecretConsulting = clientInfo?.rOEBizSecretConsulting
	// 	? clientInfo?.rOEBizSecretConsulting
	// 	: currentUserProfile?.rOEBizSecretConsulting;

	// const roeExcuteDueDate = clientInfo?.roeExcuteDueDate
	// 	? clientInfo?.roeExcuteDueDate
	// 	: currentUserProfile?.roeExcuteDueDate;
	// const ruleLaborCouncilConsulting = clientInfo?.ruleLaborCouncilConsulting
	// 	? clientInfo?.ruleLaborCouncilConsulting
	// 	: currentUserProfile?.ruleLaborCouncilConsulting;
	// const bizSecretExcuteDueDate = clientInfo?.bizSecretExcuteDueDate
	// 	? clientInfo?.bizSecretExcuteDueDate
	// 	: currentUserProfile?.bizSecretExcuteDueDate;

	const freeComClientTrue =
		!superAdminForFreeTrue && freeComClient(authLevel, ceoName) ? true : false;
	const childCompany = companyName?.includes('어린이집') ? true : false;
	const numOfWorkers = clientInfo?.numOfWorkers
		? clientInfo?.numOfWorkers
		: +currentUserProfile?.numOfWorkers;

	// const isRoeServiceOk = isWorker
	// 	? Boolean(roeExcuteDueDate) &&
	// 	  (roeConsulting === '취업규칙 컨설팅 요청' ||
	// 			roeConsulting === '노동법률 자문사')
	// 	: roeConsulting === '취업규칙 컨설팅 요청' ||
	// 	  roeConsulting === '노동법률 자문사';

	// const isLaborCouncilServiceOk = isWorker
	// 	? Boolean(rlcExcuteDueDate) &&
	// 	  (ruleLaborCouncilConsulting === '노사협의회 컨설팅 요청' ||
	// 			ruleLaborCouncilConsulting === '노동법률 자문사')
	// 	: ruleLaborCouncilConsulting === '노사협의회 컨설팅 요청' ||
	// 	  ruleLaborCouncilConsulting === '노동법률 자문사';

	// const isROEBSServiceOk = isWorker
	// 	? Boolean(bizSecretExcuteDueDate) &&
	// 	  (rOEBizSecretConsulting === '영업비밀 컨설팅 요청' ||
	// 			rOEBizSecretConsulting === '영업비밀 노동법률 자문사')
	// 	: rOEBizSecretConsulting === '영업비밀 컨설팅 요청' ||
	// 	  rOEBizSecretConsulting === '영업비밀 노동법률 자문사';

	const menuItems = [
		{ name: '전체직원 List보기', path: '/clientReqs4In' },
		{ name: '직원등록 코드보기', path: '/mapaydocuWorkerCodenual' },
		{ name: '고용노동부 자율점검', path: '/selfCheckLaborLaw' },
		freeComClientTrue
			? { name: '유료버전 안내', path: '/pricePolicyPaper' }
			: { name: '월급직 등록', path: '/createClientReq4In' },
		freeComClientTrue
			? { name: '유료버전 안내', path: '/pricePolicyPaper' }
			: { name: '일용/시급/사업소득 등록', path: '/createClientReqDayWorker' },
		{ name: '월 전체 급여 복사', path: '/paydocusCopy' },
		{
			name: '급여작업/명세서보기',
			path: '/paydocus',
		},
		{
			name: '월급직 임금대장(가로 형식)',
			path: '/paydocusTable',
		},
		{
			name: '월급직 임금대장(계좌 이체용)',
			path: '/paydocusTableBank',
		},
		{
			name: '월급직 임금대장(48조1항)',
			path: '/paydocusTableLaw',
		},
		{
			name: '월급직 임금대장(개인별)',
			path: '/paydocusTableWorker',
		},
		retirementPayDC && {
			name: 'DC 퇴직연금 대장',
			path: '/paydocusTableBank',
		},
		childCompany && {
			name: '연장근로 대장',
			path: '/paydocusTableOverTime',
		},
		{
			name: '일용직 임금대장(가로 형식)',
			path: '/paydocusDayWorkerTable',
		},
		{
			name: '일용직 임금대장(개인별)',
			path: '/paydocusTableDayWorker',
		},
		{
			name: '일용직 임금대장(신고용)',
			path: '/paydocusDayWorkerTableReport',
		},
		{
			name: '사업소득직 임금대장(가로 형식)',
			path: '/paydocusTableBiz',
		},
		{
			name: '사업소득직 임금대장(개인별)',
			path: '/paydocusTableBizWorker',
		},
		{
			name: '월급직 4대보험신고',
			path: '/insureManage',
		},
		{
			name: '일용근로내역확인신고',
			path: '/paydocusDayWorkerTableReport',
		},
		{
			name: '일용직 국민/건강 신고',
			path: '/paydocusDayWorkerTableReportHP',
		},
		numOfWorkers > 5 && {
			name: '월별 연차/근태대장',
			path: '/annualLeavesTable',
		},
		numOfWorkers > 5 && {
			name: '개인별 연차/근태대장',
			path: '/annualLeavesWorkerTable',
		},
		// {
		// 	name: '역량모델링 입력',
		// 	path: '/createCompetencyMonthlyReport',
		// },
		// {
		// 	name: '역량 REPORT',
		// 	path: '/monthlyReports',
		// },
		// (authLevel <= 30 || !personalEvaluationYes) && {
		// 	name: '업무수행평가월별 대장',
		// 	path: '/paydocusTableHr',
		// },
		// (authLevel <= 30 || !personalEvaluationYes) && {
		// 	name: '업무수행평가(개인별)',
		// 	path: '/paydocusTableWorkerHr',
		// },
		// isRoeServiceOk && {
		// 	name: '취업규칙',
		// 	path: `/ruleOfEmployee/${viewId}`,
		// },
		// isLaborCouncilServiceOk && {
		// 	name: '노사협의회규정',
		// 	path: `/ruleOfLaborCouncil/${viewId}`,
		// },
		// isROEBSServiceOk && {
		// 	name: '영업비밀보호규정',
		// 	path: `/ruleOfEmployeeBizSecret/${viewId}`,
		// },
		{
			name: '실수령액 계산기',
			path: `/netCalc`,
		},
		{
			name: '연차 계산기',
			path: `/annualLeavesCalc`,
		},
		{
			name: '퇴직금 계산기',
			path: `/retireFundCalc`,
		},
		// {
		// 	name: '상시근로자수 계산기',
		// 	path: `/numOfWorkersJudgeCalc`,
		// },
	].filter(Boolean);

	// 초성으로 필터링하는 로직
	const filteredMenuItems = menuItems.filter((item) =>
		item.name.toLowerCase()?.includes(searchQuery.toLowerCase())
	);

	const handleSearchChange = (e) => {
		setSearchQuery(e.target.value);
	};

	const handleMenuItemClick = (route) => {
		history.push(route);
	};
	const handleRemoteControl = () => {
		window.open('https://remotedesktop.google.com/', '_blank');
	};

	const isAgent =
		isAgentF(
			currentUserProfile?.agentType,
			currentUserProfile?.authLevel,
			currentUserProfile?.isAgent
		) || authLevel > 100
			? true
			: false;

	return (
		<Sidebar
			as={Menu}
			// animation='overlay'
			icon='labeled'
			inverted
			vertical
			visible
			// width='wide'
			style={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'flex-start',
				alignItems: 'flex-start',
				width: '15%',
				height: '100vh',
				overflowY: 'auto',
			}}>
			<Menu.Item className='menu-item' header as={NavLink} exact to='/'>
				<img src='logo.png' alt='logo' />
			</Menu.Item>
			{/* 검색창 추가 */}
			<Menu.Item>
				<input
					icon='search'
					placeholder='매뉴 검색...'
					value={searchQuery}
					onChange={handleSearchChange}
					style={{ width: '100%', height: '30px' }} // 크기 조정
				/>
			</Menu.Item>

			{/* 검색어가 입력된 경우에만 필터링된 메뉴 렌더링 */}
			{searchQuery && filteredMenuItems.length > 0
				? filteredMenuItems.map((item) => (
						<Menu.Item
							key={item.path}
							onClick={() => handleMenuItemClick(item.path)}>
							{item.name}
						</Menu.Item>
				  ))
				: searchQuery && <Menu.Item>검색 결과가 없습니다.</Menu.Item>}
			<Divider />
			<div
				style={{
					flex: 1,
					overflowY: 'auto',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'flex-start',
				}}>
				{authLevel >= 10 || !authenticated ? null : (
					<PriceIntroMenu MdOutlinePriceCheck={MdOutlinePriceCheck} />
				)}
				<Menu.Item onClick={handleRemoteControl} style={{ color: 'yellow' }}>
					<FaMousePointer className='icon-spacing' /> 구글 원격 시작
				</Menu.Item>
				{/* "AI노무사 매뉴얼 다운" 메뉴 추가 */}
				{/* <Menu.Item onClick={downloadManual} style={{ color: 'yellow' }}>
					<FaBookMedical className='icon-spacing' />
					매뉴얼 다운로드
				</Menu.Item> */}

				{authLevel >= 10 ? (
					<>
						<WorkerManageMenu
							authLevel={authLevel}
							ceoName={ceoName}
							clientReqs4In={clientReqs4In}
							superAdminForFreeTrue={superAdminForFreeTrue}
						/>
						<PaydocuMenu FaWonSign={FaWonSign} authLevel={authLevel} />
						<PaydocuTableMenu
							PiUserListFill={PiUserListFill}
							companyName={companyName}
							retirementPayDC={retirementPayDC}
						/>
						<InsureRegister
							MdSecurityUpdateGood={MdSecurityUpdateGood}
							currentUserProfile={currentUserProfile}
						/>
						<PaydocuAnnualLeaveMenu
							currentUserProfile={currentUserProfile}
							clientInfo={clientInfo}
							FcLeave={FcLeave}
						/>
						{/* <CompetencyMenu GrVulnerability={GrVulnerability} />
						<RiskSuspectMenu FaHelmetSafety={FaHelmetSafety} />
						<PaydocuEvaluationMenu
							authLevel={authLevel}
							personalEvaluationYes={personalEvaluationYes}
							VscHorizontalRule={VscHorizontalRule}
						/> */}
						{/* <RuleOfEmployeeMenu
							BsFillFileEarmarkRuledFill={BsFillFileEarmarkRuledFill}
							isCoWorkSemusa={isCoWorkSemusa}
							freeComClient5={freeComClient(authLevel, ceoName)}
							viewId={viewId}
							isWorker={isWorker}
							roeConsulting={roeConsulting}
							rlcExcuteDueDate={rlcExcuteDueDate}
							rOEBizSecretConsulting={rOEBizSecretConsulting}
							roeExcuteDueDate={roeExcuteDueDate}
							ruleLaborCouncilConsulting={ruleLaborCouncilConsulting}
							bizSecretExcuteDueDate={bizSecretExcuteDueDate}
						/> */}
					</>
				) : null}
				<CalculatorMenu CiCalculator1={CiCalculator1} />
				{/* <NoticeEtcMenu HiInformationCircle={HiInformationCircle} /> */}
				<br />
				<br />
				<PriceIntroMenu MdOutlinePriceCheck={MdOutlinePriceCheck} />
				{isAgent && <CompanyContractMenu profile={currentUserProfile} />}

				{/* <ChatBotMenu TbMessageChatbot={TbMessageChatbot} authLevel={authLevel} /> */}
			</div>
			<div>
				{isAgent ? (
					<NomuWorksMenu
						GrUserAdmin={GrUserAdmin}
						isSuperAdminForFix={isSuperAdminForFix}
					/>
				) : null}
				{authenticated ? <SignedInMenu /> : <SignedOutMenu />}
			</div>
		</Sidebar>
	);
};

export default MySidebar;
