import React from 'react';
import PaydocuDetailedPageWorkDayPlusTimeForDTWorker from './PaydocuDetailedPageWorkDayPlusTimeForDTWorker';
import PaydocuDetailedPageWorkDayPlusTimeForRWorker from './PaydocuDetailedPageWorkDayPlusTimeForRWorker';

export default function PaydocuDetailedPageWorkDayPlusTime({
	daylyTimePay,
	worker,
	dayWorker,
	countOfNomalDayWork,
	countOfHoliDayWork,
	countOfOverWorkingDayWork,
	sumOfCountWorkDays,
	workDatesPaydocu,
	dayWorkerPlusOTime,
	numOfWorkers,

	timeWorkerPlusMonthOWT,
	timeWorkerPlusMonthNWT,
	timeWorkerPlusMonthHoliBasicTime,
	timeWorkerPlusMonthOWTHoli,
	timeWorkerPlusMonthOffBasicTime,
	timeWorkerPlusMonthOWTOff,
	timeWorkerPlusOverTimeDates,
	timeWorkerPlusNightTimeDates,
	timeWorkerPlusHoliBasicTimeDates,
	timeWorkerPlusHoliOverTimeDates,
	timeWorkerPlusOffBasicTimeDates,
	timeWorkerPlusOffOverTimeDates,

	dayWorkerPlusNTime,
	dayWorkerPlusHBTime,
	dayWorkerPlusHOTime,
	dayWorkerPlusHNTime,
	dayWorkerAbsentTimes,
	dayWorkerPlusOverTimeDates,
	dayWorkerPlusNightTimeDates,
	dayWorkerPlusHoliBasicTimeDates,
	dayWorkerPlusHoliOverTimeDates,
	dayWorkerPlusHoliNightTimeDates,
	dayWorkerAbsentTimeDates,
	plusMonthOWT,
	plusMonthNWT,
	plusMonthHoliBasicTime,
	plusMonthOWTHoli,
	plusMonthNWTHoli,
	absentDays,
	absentPaidHoliDays,
	absentTimes,
	usedAnnualLeaves,
	absentDates,
	absentPaidHoliDates,
	absentTimeDates,
	usedAnnualLeaveDates,
}) {
	return (
		<>
			{dayWorker ? (
				<PaydocuDetailedPageWorkDayPlusTimeForDTWorker
					daylyTimePay={daylyTimePay}
					worker={worker}
					dayWorker={dayWorker}
					countOfNomalDayWork={countOfNomalDayWork}
					countOfHoliDayWork={countOfHoliDayWork}
					countOfOverWorkingDayWork={countOfOverWorkingDayWork}
					sumOfCountWorkDays={sumOfCountWorkDays}
					workDatesPaydocu={workDatesPaydocu}
					dayWorkerPlusOTime={dayWorkerPlusOTime}
					numOfWorkers={numOfWorkers}
					dayWorkerPlusNTime={dayWorkerPlusNTime}
					dayWorkerPlusHBTime={dayWorkerPlusHBTime}
					dayWorkerPlusHOTime={dayWorkerPlusHOTime}
					dayWorkerPlusHNTime={dayWorkerPlusHNTime}
					dayWorkerAbsentTimes={dayWorkerAbsentTimes}
					dayWorkerPlusOverTimeDates={dayWorkerPlusOverTimeDates}
					dayWorkerPlusNightTimeDates={dayWorkerPlusNightTimeDates}
					dayWorkerPlusHoliBasicTimeDates={dayWorkerPlusHoliBasicTimeDates}
					dayWorkerPlusHoliOverTimeDates={dayWorkerPlusHoliOverTimeDates}
					dayWorkerPlusHoliNightTimeDates={dayWorkerPlusHoliNightTimeDates}
					dayWorkerAbsentTimeDates={dayWorkerAbsentTimeDates}
					timeWorkerPlusMonthOWT={timeWorkerPlusMonthOWT}
					timeWorkerPlusMonthNWT={timeWorkerPlusMonthNWT}
					timeWorkerPlusMonthHoliBasicTime={timeWorkerPlusMonthHoliBasicTime}
					timeWorkerPlusMonthOWTHoli={timeWorkerPlusMonthOWTHoli}
					timeWorkerPlusMonthOffBasicTime={timeWorkerPlusMonthOffBasicTime}
					timeWorkerPlusMonthOWTOff={timeWorkerPlusMonthOWTOff}
					timeWorkerPlusOverTimeDates={timeWorkerPlusOverTimeDates}
					timeWorkerPlusNightTimeDates={timeWorkerPlusNightTimeDates}
					timeWorkerPlusHoliBasicTimeDates={timeWorkerPlusHoliBasicTimeDates}
					timeWorkerPlusHoliOverTimeDates={timeWorkerPlusHoliOverTimeDates}
					timeWorkerPlusOffBasicTimeDates={timeWorkerPlusOffBasicTimeDates}
					timeWorkerPlusOffOverTimeDates={timeWorkerPlusOffOverTimeDates}
				/>
			) : (
				<PaydocuDetailedPageWorkDayPlusTimeForRWorker
					worker={worker}
					numOfWorkers={numOfWorkers}
					plusMonthOWT={plusMonthOWT}
					plusMonthNWT={plusMonthNWT}
					plusMonthHoliBasicTime={plusMonthHoliBasicTime}
					plusMonthOWTHoli={plusMonthOWTHoli}
					plusMonthNWTHoli={plusMonthNWTHoli}
					absentDays={absentDays}
					absentPaidHoliDays={absentPaidHoliDays}
					absentTimes={absentTimes}
					usedAnnualLeaves={usedAnnualLeaves}
					absentDates={absentDates}
					absentPaidHoliDates={absentPaidHoliDates}
					absentTimeDates={absentTimeDates}
					usedAnnualLeaveDates={usedAnnualLeaveDates}
				/>
			)}
		</>
	);
}
