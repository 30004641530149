import React from 'react';
import { Button, Table } from 'semantic-ui-react';

import { useDispatch, useSelector } from 'react-redux';
import PaydocuShortCut from '../paydocuDashboard/PaydocuShortCut';
import {
	companyBankAccountSeajong,
	josaGaType,
	josaUnType,
	printUseContract,
} from '../../../app/common/util/util';
import { format } from 'date-fns';
import useFirestoreDoc from '../../../app/hooks/useFirestoreDoc';
import { getUserProfile } from '../../../app/firestore/firestoreService';
import { listenToSelectedUserProfile } from '../../profiles/profileActions';

export default function ProgramUseContractContents({ profile }) {
	const dispatch = useDispatch();
	const { clientReqs4In } = useSelector((state) => state.clientReq4InStore);

	const { clientInfo, filter } = useSelector((state) => state.companyReducer);

	useFirestoreDoc({
		query: () => getUserProfile(profile.id),
		data: (profile) => dispatch(listenToSelectedUserProfile(profile)),
		deps: [dispatch, profile],
	});

	const companyInfo =
		clientInfo && Object.keys(clientInfo).length > 0 ? clientInfo : profile;
	const numOfWorkers = companyInfo?.numOfWorkers;
	const isWorker = companyInfo?.isWorker;

	const payDay = companyInfo?.payDay;

	const startDateString = companyInfo?.serviceStartDate;
	const formattedStartDate =
		startDateString instanceof Date
			? startDateString.toISOString().split('T')[0]
			: null; // 날짜가 유효하지 않을 경우 null

	const endDateString = companyInfo?.serviceEndDate;
	const formattedEndDate =
		endDateString instanceof Date
			? endDateString.toISOString().split('T')[0]
			: null; // 기본값을 "N/A" 등으로 설정할 수 있습니다.

	const startNumOfWorkers = `(월급직, 시급직, 일급직, 사업소득직 등 인원수 : ${numOfWorkers}명 기준)`;

	const companyGa = josaGaType(profile?.companyName);
	const companyUn = josaUnType(profile?.companyName);

	return (
		<>
			{isWorker ? null : (
				<PaydocuShortCut filter={filter} clientReqs4In={clientReqs4In} />
			)}
			<Button
				color='blue'
				style={{ display: 'inline', color: 'black', margin: '5px' }}
				onClick={() => printUseContract()}>
				SafeHR 서비스 계약서 출력
			</Button>
			<div style={{ display: 'flex', height: '100vh' }}>
				<div id='useContract'>
					<h1 style={{ textAlign: 'center', margin: '20px', fontSize: '30px' }}>
						SafeHR 서비스 계약서
					</h1>
					<h3>서비스 이용자 기본사항</h3>
					<Table celled style={{ width: '95%', lineHeight: '1' }}>
						<Table.Body>
							<Table.Row textAlign='center'>
								<Table.Cell>회사명</Table.Cell>
								<Table.Cell>{profile?.companyName}</Table.Cell>
								<Table.Cell>사업자등록번호</Table.Cell>
								<Table.Cell>{profile?.companyBizNumber}</Table.Cell>
								<Table.Cell>Email</Table.Cell>
								<Table.Cell>{profile?.email}</Table.Cell>
							</Table.Row>
							<Table.Row textAlign='center'>
								<Table.Cell>대표(이사)</Table.Cell>
								<Table.Cell>{profile?.ceoName}</Table.Cell>
								<Table.Cell>연락처</Table.Cell>
								<Table.Cell>{profile?.companyPhone}</Table.Cell>
								<Table.Cell>회사주소</Table.Cell>
								<Table.Cell>{profile?.companyAddress}</Table.Cell>
							</Table.Row>
						</Table.Body>
					</Table>

					<h3>제1조 계약의 성립</h3>
					<p>
						(주) 카이소프트는 {profile?.companyName}에게 SafeHR를 통한 SaaS HR
						서비스(이하 “서비스” 라 함.)를 제공하고 (주) 카이소프트는 이에 대한
						이용료 지급을 목적으로 본 계약을 체결한다.
					</p>
					<br />
					<h3>제2조 기본 서비스 내용 사용료</h3>
					<p>
						① (주) 카이소프트는 다음 각호의 서비스를 {profile?.companyName}에게
						제공하며, {companyUn} 이에 대한 보수를 지급한다.
						<span
							style={{
								color: 'blue',
								fontSize: '1.3em', // 1.3 times the current font size
							}}>
							{startNumOfWorkers}
						</span>
					</p>
					<br />
					<Table celled style={{ width: '95%', lineHeight: '1' }}>
						<Table.Header>
							<Table.Row textAlign='center'>
								<Table.HeaderCell style={{ width: '30%' }}>
									구분
								</Table.HeaderCell>
								<Table.HeaderCell style={{ width: '40%' }}>
									시스템 사용료 (월) (V.A.T. 별도)
								</Table.HeaderCell>
								<Table.HeaderCell style={{ width: '25%' }}>
									추가 인당 금액 (월) (V.A.T. 별도)
								</Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						<Table.Body>
							<Table.Row textAlign='center'>
								<Table.Cell style={{ width: '30%' }}>10인 미만</Table.Cell>
								<Table.Cell style={{ width: '40%' }}>
									<div style={{ margin: '1rem' }}>사업장당 10,000원</div>
								</Table.Cell>
								<Table.Cell style={{ width: '25%' }}>해당없음.</Table.Cell>
							</Table.Row>
							<Table.Row textAlign='center'>
								<Table.Cell style={{ width: '30%' }}>10인 이상</Table.Cell>
								<Table.Cell style={{ width: '40%' }}>
									사업장당 10,000원
								</Table.Cell>
								<Table.Cell style={{ width: '25%' }}>
									10인 초과 인당 1인당 1,500원
								</Table.Cell>
							</Table.Row>
						</Table.Body>
					</Table>
					<p>
						② {companyUn} (주) 카이소프트에게{' '}
						<span
							style={{
								color: 'blue',
								fontSize: '1.3em', // 1.3 times the current font size
							}}>
							매월 {payDay}
						</span>
						에 사업장당 시스템 사용료와 10인 이상 사업장의 경우 10인 이상
						사업장의 경우 10인 초과 1인당 1,500원을 지급한다.
					</p>

					{/* ①②③④⑤⑥⑦⑧⑨⑩ */}

					<h3>제 3 조 서비스 보수 지급 방법</h3>

					<p>
						① 전조의 SafeHR 사용료는
						<span
							style={{
								color: 'blue',
								fontSize: '1.3em', // 1.3 times the current font size
							}}>
							{' '}
							{companyBankAccountSeajong[0]}로 입금한다.
						</span>
					</p>
					<h3>제 4조 계약 기간</h3>
					<p>
						① 본 계약의 유효기간은
						<span
							style={{
								color: 'blue',
								fontSize: '1.3em', // 1.3 times the current font size
							}}>
							{' '}
							{formattedStartDate}부터 {formattedEndDate}
						</span>
						으로 하며, 계약종료일 30일전 까지 당사자 일방이 별다른 의사를
						표시하지 아니하는 경우 본 계약은 종료일로부터 동일기간으로
						자동갱신된 것으로 본다.
					</p>
					<br />
					<p>
						② 제1항의 자동 갱신될 때 기간은 동일한 기간으로 갱신하되, 제2조의
						서비스 비용은 인원수 및 이용하는 서비스 범위에 따라 변경된다.
					</p>
					<h3>제5조 정당한 계약 해지</h3>
					<p>
						① {companyUn} 다음 각 호의 사유가 발생하였을 경우에는 본 계약을
						해지할 수 있다. <br />
						1. (주) 카이소프트가 {profile?.companyName}의 경영 및 영업비밀을{' '}
						{profile?.companyName}의 동의 또는 협의 없이 무단으로 누설하였을 때{' '}
						<br />
						2. (주) 카이소프트가 SafeHR 시스템을 운영하지 않을 때 <br />
						3. (주) 카이소프트가 사업운영을 포기하여 폐업 등의 사유로 사업운영을
						하지않는 사유가 발생하였을 때
					</p>
					<p>
						② {companyGa} 다음 각 호의 사유가 발생하였을 경우에는 (주)
						카이소프트는 본 계약을 해지할 수 있다. <br />
						1. {companyGa}이 제2조의 SafeHR 이용료를 정당한 사유 없이 2개월 이상
						납부하지 않았을 때 <br />
						2. {companyGa}이 사업운영을 포기하여 폐업 등의 사유로 사업운영을
						하지않는 사유가 발생하였을 때
					</p>
					<br />
					<h3>제6조 할인 및 위약 예정 특약 사항</h3>
					<p>
						① {companyGa} SafeHR을 동료 전문가에게 2명을 소개해서 해당 전문가가
						본 SafeHR 서비스를 모두 이용할 때 (주) 카이소프트는 SafeHR 시스템
						사용료의 50%를 할인한다.(추가 인당 금액은 할인 대상이 아님)
					</p>
					<p>
						② {companyGa} 본 계약기간 내에 제5조의 정당한 해지사유가 아닌
						계약파기를 하는 경우 위 ①항의 할인은 무효가 되고, ‘서비스 이용자’은
						계약해지와 동시에 ①항을 통해 할인받았던 금액을 (주) 카이소프트에게
						지급한다.
					</p>

					<h3>날짜</h3>
					<Table celled style={{ width: '95%', lineHeight: '1' }}>
						{' '}
						{/* 테이블 줄 간격 조정 */}
						<Table.Body>
							<Table.Row textAlign='center'>
								<Table.Cell style={{ textAlign: 'center' }}>
									{format(new Date(), 'yyyy-MM-dd')}
								</Table.Cell>
							</Table.Row>
						</Table.Body>
					</Table>
					<h3>서명</h3>
					<Table celled style={{ width: '95%', lineHeight: '1' }}>
						<Table.Body>
							<Table.Row textAlign='center'>
								<Table.Cell>SafeHR 이용자</Table.Cell>
								<Table.Cell>{profile?.companyName}</Table.Cell>
								<Table.Cell>SafeHR 공급자</Table.Cell>
								<Table.Cell>(주) 카이소프트</Table.Cell>
							</Table.Row>
							<Table.Row textAlign='center'>
								<Table.Cell>대표자</Table.Cell>
								<Table.Cell>{profile?.ceoName} (인)</Table.Cell>
								<Table.Cell>대표자</Table.Cell>
								<Table.Cell>김 경 모 (인)</Table.Cell>
							</Table.Row>
						</Table.Body>
					</Table>
				</div>
			</div>
		</>
	);
}
