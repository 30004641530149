import React from 'react';
import {
	daysWT,
	josaGaType,
	josaUnType,
	josaWaType,
	roundUp2,
} from '../../../../../app/common/util/util';

export default function ClientReqDLCDTime({
	netAge15To18,
	isExceptTimeLawWorker,
	isEtcWorker,
	numOfWorkers,
	selectedClientReq4In,
	companyName,
	weekLBTime,
	worker,
	days1,
	days2,
	days3,
	days4,
	days5,
	days6,
	daysWT1,
	daysWT2,
	daysWT3,
	daysWT4,
	daysWT5,
	daysWT6,
	daysETL7,

	daysETL1,
	daysETL2,
	daysETL3,
	daysETL4,
	daysETL5,
	daysETL6,

	daysWTETL1,
	daysWTETL2,
	daysWTETL3,
	daysWTETL4,
	daysWTETL5,
	daysWTETL6,
	daysWTETL7,

	skipWeek1,
	skipWeek2,
	skipWeek3,
	skipWeek4,
	skipWeek5,
	skipWeek6,
}) {
	//첫번째 근무시간이 같은 날들의 시업, 종업일, 종업시간, 휴게(분)
	const startWT1 = selectedClientReq4In?.selectWorkDays1Info?.workingStartTime;
	const dayType1 =
		selectedClientReq4In?.selectWorkDays1Info?.workingEndTimeNextDayOrNot;
	const endWT1 = selectedClientReq4In?.selectWorkDays1Info?.workingEndTime;
	const dayRT1 = selectedClientReq4In?.selectWorkDays1Info?.dayBreakTimes
		? selectedClientReq4In?.selectWorkDays1Info?.dayBreakTimes
		: 0;
	const nigthRT1 = selectedClientReq4In?.selectWorkDays1Info?.nightBreakTimes
		? selectedClientReq4In?.selectWorkDays1Info?.nightBreakTimes
		: 0;

	//둘째 근무시간이 같은 날들의 시업, 종업일, 종업시간, 휴게(분)
	const startWT2 = selectedClientReq4In?.selectWorkDays2Info?.workingStartTime;
	const dayType2 =
		selectedClientReq4In?.selectWorkDays2Info?.workingEndTimeNextDayOrNot;
	const endWT2 = selectedClientReq4In?.selectWorkDays2Info?.workingEndTime;
	const dayRT2 = selectedClientReq4In?.selectWorkDays2Info?.dayBreakTimes
		? selectedClientReq4In?.selectWorkDays2Info?.dayBreakTimes
		: 0;
	const nigthRT2 = selectedClientReq4In?.selectWorkDays2Info?.nightBreakTimes
		? selectedClientReq4In?.selectWorkDays2Info?.nightBreakTimes
		: 0;

	//세째 근무시간이 같은 날들의 시업, 종업일, 종업시간, 휴게(분)
	const startWT3 = selectedClientReq4In?.selectWorkDays3Info?.workingStartTime;
	const dayType3 =
		selectedClientReq4In?.selectWorkDays3Info?.workingEndTimeNextDayOrNot;
	const endWT3 = selectedClientReq4In?.selectWorkDays3Info?.workingEndTime;
	const dayRT3 = selectedClientReq4In?.selectWorkDays3Info?.dayBreakTimes
		? selectedClientReq4In?.selectWorkDays3Info?.dayBreakTimes
		: 0;
	const nigthRT3 = selectedClientReq4In?.selectWorkDays3Info?.nightBreakTimes
		? selectedClientReq4In?.selectWorkDays3Info?.nightBreakTimes
		: 0;

	//네째 근무시간이 같은 날들의 시업, 종업일, 종업시간, 휴게(분)
	const startWT4 = selectedClientReq4In?.selectWorkDays4Info?.workingStartTime;
	const dayType4 =
		selectedClientReq4In?.selectWorkDays4Info?.workingEndTimeNextDayOrNot;
	const endWT4 = selectedClientReq4In?.selectWorkDays4Info?.workingEndTime;
	const dayRT4 = selectedClientReq4In?.selectWorkDays4Info?.dayBreakTimes
		? selectedClientReq4In?.selectWorkDays4Info?.dayBreakTimes
		: 0;
	const nigthRT4 = selectedClientReq4In?.selectWorkDays4Info?.nightBreakTimes
		? selectedClientReq4In?.selectWorkDays4Info?.nightBreakTimes
		: 0;

	//다섯째 근무시간이 같은 날들의 시업, 종업일, 종업시간, 휴게(분)
	const startWT5 = selectedClientReq4In?.selectWorkDays5Info?.workingStartTime;
	const dayType5 =
		selectedClientReq4In?.selectWorkDays5Info?.workingEndTimeNextDayOrNot;
	const endWT5 = selectedClientReq4In?.selectWorkDays5Info?.workingEndTime;
	const dayRT5 = selectedClientReq4In?.selectWorkDays5Info?.dayBreakTimes
		? selectedClientReq4In?.selectWorkDays5Info?.dayBreakTimes
		: 0;
	const nigthRT5 = selectedClientReq4In?.selectWorkDays5Info?.nightBreakTimes
		? selectedClientReq4In?.selectWorkDays5Info?.nightBreakTimes
		: 0;

	//여섯째 근무시간이 같은 날들의 시업, 종업일, 종업시간, 휴게(분)
	const startWT6 = selectedClientReq4In?.selectWorkDays6Info?.workingStartTime;
	const dayType6 =
		selectedClientReq4In?.selectWorkDays6Info?.workingEndTimeNextDayOrNot;
	const endWT6 = selectedClientReq4In?.selectWorkDays6Info?.workingEndTime;
	const dayRT6 = selectedClientReq4In?.selectWorkDays6Info?.dayBreakTimes
		? selectedClientReq4In?.selectWorkDays6Info?.dayBreakTimes
		: 0;
	const nigthRT6 = selectedClientReq4In?.selectWorkDays6Info?.nightBreakTimes
		? selectedClientReq4In?.selectWorkDays6Info?.nightBreakTimes
		: 0;

	//휴일 근무의 시업, 종업일, 종업시간, 휴게(분)
	const startWTHoli =
		selectedClientReq4In?.expectYearlyHolidayWorksInfo?.workingStartTime;
	const dayTypeHoli =
		selectedClientReq4In?.expectYearlyHolidayWorksInfo
			?.workingEndTimeNextDayOrNot;
	const endWTHoli =
		selectedClientReq4In?.expectYearlyHolidayWorksInfo?.workingEndTime;
	const dayRTHoli = selectedClientReq4In?.expectYearlyHolidayWorksInfo
		?.dayBreakTimes
		? selectedClientReq4In?.expectYearlyHolidayWorksInfo?.dayBreakTimes
		: 0;
	const nigthRTHoli = selectedClientReq4In?.expectYearlyHolidayWorksInfo
		?.nightBreakTimes
		? selectedClientReq4In?.expectYearlyHolidayWorksInfo?.nightBreakTimes
		: 0;
	const dayRContentsTHoli = selectedClientReq4In?.expectYearlyHolidayWorksInfo
		?.breakTimeContents
		? selectedClientReq4In?.expectYearlyHolidayWorksInfo?.breakTimeContents
		: 0;

	const rTContents1 =
		selectedClientReq4In?.selectWorkDays1Info?.breakTimeContents === undefined
			? ''
			: selectedClientReq4In?.selectWorkDays1Info?.breakTimeContents;
	const totalDayRT1 = parseInt(dayRT1) + parseInt(nigthRT1);

	//둘째 근무시간이 같은 날들의 시업, 종업일, 종업시간, 휴게(분)

	const rTContents2 =
		selectedClientReq4In?.selectWorkDays2Info?.breakTimeContents === undefined
			? ''
			: selectedClientReq4In?.selectWorkDays2Info?.breakTimeContents;
	const totalDayRT2 = parseInt(dayRT2) + parseInt(nigthRT2);

	//세째 근무시간이 같은 날들의 시업, 종업일, 종업시간, 휴게(분)

	const rTContents3 =
		selectedClientReq4In?.selectWorkDays3Info?.breakTimeContents === undefined
			? ''
			: selectedClientReq4In?.selectWorkDays3Info?.breakTimeContents;
	const totalDayRT3 = parseInt(dayRT3) + parseInt(nigthRT3);

	//네째 근무시간이 같은 날들의 시업, 종업일, 종업시간, 휴게(분)
	const rTContents4 =
		selectedClientReq4In?.selectWorkDays4Info?.breakTimeContents === undefined
			? ''
			: selectedClientReq4In?.selectWorkDays4Info?.breakTimeContents;
	const totalDayRT4 = parseInt(dayRT4) + parseInt(nigthRT4);

	//다섯째 근무시간이 같은 날들의 시업, 종업일, 종업시간, 휴게(분)
	const rTContents5 =
		selectedClientReq4In?.selectWorkDays5Info?.breakTimeContents === undefined
			? ''
			: selectedClientReq4In?.selectWorkDays5Info?.breakTimeContents;
	const totalDayRT5 = parseInt(dayRT5) + parseInt(nigthRT5);

	//여섯째 근무시간이 같은 날들의 시업, 종업일, 종업시간, 휴게(분)
	const rTContents6 =
		selectedClientReq4In?.selectWorkDays6Info?.breakTimeContents === undefined
			? ''
			: selectedClientReq4In?.selectWorkDays6Info?.breakTimeContents;
	const totalDayRT6 = parseInt(dayRT6) + parseInt(nigthRT6);

	////////////////////////////////////////////////////////////////////////////
	//첫번째 근무시간이 같은 날들의 시업, 종업일, 종업시간, 휴게(분)
	const startWTETL1 =
		selectedClientReq4In?.selectWorkDaysETL1Info?.workingStartTime;
	const dayTypeETL1 =
		selectedClientReq4In?.selectWorkDaysETL1Info?.workingEndTimeNextDayOrNot;
	const endWTETL1 =
		selectedClientReq4In?.selectWorkDaysETL1Info?.workingEndTime;
	const dayRTETL1 = selectedClientReq4In?.selectWorkDaysETL1Info?.dayBreakTimes
		? selectedClientReq4In?.selectWorkDaysETL1Info?.dayBreakTimes
		: 0;
	const nigthRTETL1 = selectedClientReq4In?.selectWorkDaysETL1Info
		?.nightBreakTimes
		? selectedClientReq4In?.selectWorkDaysETL1Info?.nightBreakTimes
		: 0;

	//둘째 근무시간이 같은 날들의 시업, 종업일, 종업시간, 휴게(분)
	const startWTETL2 =
		selectedClientReq4In?.selectWorkDaysETL2Info?.workingStartTime;
	const dayTypeETL2 =
		selectedClientReq4In?.selectWorkDaysETL2Info?.workingEndTimeNextDayOrNot;
	const endWTETL2 =
		selectedClientReq4In?.selectWorkDaysETL2Info?.workingEndTime;
	const dayRTETL2 = selectedClientReq4In?.selectWorkDaysETL2Info?.dayBreakTimes
		? selectedClientReq4In?.selectWorkDaysETL2Info?.dayBreakTimes
		: 0;
	const nigthRTETL2 = selectedClientReq4In?.selectWorkDaysETL2Info
		?.nightBreakTimes
		? selectedClientReq4In?.selectWorkDaysETL2Info?.nightBreakTimes
		: 0;

	//세째 근무시간이 같은 날들의 시업, 종업일, 종업시간, 휴게(분)
	const startWTETL3 =
		selectedClientReq4In?.selectWorkDaysETL3Info?.workingStartTime;
	const dayTypeETL3 =
		selectedClientReq4In?.selectWorkDaysETL3Info?.workingEndTimeNextDayOrNot;
	const endWTETL3 =
		selectedClientReq4In?.selectWorkDaysETL3Info?.workingEndTime;
	const dayRTETL3 = selectedClientReq4In?.selectWorkDaysETL3Info?.dayBreakTimes
		? selectedClientReq4In?.selectWorkDaysETL3Info?.dayBreakTimes
		: 0;
	const nigthRTETL3 = selectedClientReq4In?.selectWorkDaysETL3Info
		?.nightBreakTimes
		? selectedClientReq4In?.selectWorkDaysETL3Info?.nightBreakTimes
		: 0;

	//네째 근무시간이 같은 날들의 시업, 종업일, 종업시간, 휴게(분)
	const startWTETL4 =
		selectedClientReq4In?.selectWorkDaysETL4Info?.workingStartTime;
	const dayTypeETL4 =
		selectedClientReq4In?.selectWorkDaysETL4Info?.workingEndTimeNextDayOrNot;
	const endWTETL4 =
		selectedClientReq4In?.selectWorkDaysETL4Info?.workingEndTime;
	const dayRTETL4 = selectedClientReq4In?.selectWorkDaysETL4Info?.dayBreakTimes
		? selectedClientReq4In?.selectWorkDaysETL4Info?.dayBreakTimes
		: 0;
	const nigthRTETL4 = selectedClientReq4In?.selectWorkDaysETL4Info
		?.nightBreakTimes
		? selectedClientReq4In?.selectWorkDaysETL4Info?.nightBreakTimes
		: 0;

	//다섯째 근무시간이 같은 날들의 시업, 종업일, 종업시간, 휴게(분)
	const startWTETL5 =
		selectedClientReq4In?.selectWorkDaysETL5Info?.workingStartTime;
	const dayTypeETL5 =
		selectedClientReq4In?.selectWorkDaysETL5Info?.workingEndTimeNextDayOrNot;
	const endWTETL5 =
		selectedClientReq4In?.selectWorkDaysETL5Info?.workingEndTime;
	const dayRTETL5 = selectedClientReq4In?.selectWorkDaysETL5Info?.dayBreakTimes
		? selectedClientReq4In?.selectWorkDaysETL5Info?.dayBreakTimes
		: 0;
	const nigthRTETL5 = selectedClientReq4In?.selectWorkDaysETL5Info
		?.nightBreakTimes
		? selectedClientReq4In?.selectWorkDaysETL5Info?.nightBreakTimes
		: 0;

	//여섯째 근무시간이 같은 날들의 시업, 종업일, 종업시간, 휴게(분)
	const startWTETL6 =
		selectedClientReq4In?.selectWorkDaysETL6Info?.workingStartTime;
	const dayTypeETL6 =
		selectedClientReq4In?.selectWorkDaysETL6Info?.workingEndTimeNextDayOrNot;
	const endWTETL6 =
		selectedClientReq4In?.selectWorkDaysETL6Info?.workingEndTime;
	const dayRTETL6 = selectedClientReq4In?.selectWorkDaysETL6Info?.dayBreakTimes
		? selectedClientReq4In?.selectWorkDaysETL6Info?.dayBreakTimes
		: 0;
	const nigthRTETL6 = selectedClientReq4In?.selectWorkDaysETL6Info
		?.nightBreakTimes
		? selectedClientReq4In?.selectWorkDaysETL6Info?.nightBreakTimes
		: 0;

	//휴일 근무의 시업, 종업일, 종업시간, 휴게(분)
	const startWTETL7 =
		selectedClientReq4In?.expectYearly7thDaysETLInfo?.workingStartTime;
	const dayTypeETL7 =
		selectedClientReq4In?.expectYearly7thDaysETLInfo
			?.workingEndTimeNextDayOrNot;
	const endWTETL7 =
		selectedClientReq4In?.expectYearly7thDaysETLInfo?.workingEndTime;
	const dayRTETL7 = selectedClientReq4In?.expectYearly7thDaysETLInfo
		?.dayBreakTimes
		? selectedClientReq4In?.expectYearly7thDaysETLInfo?.dayBreakTimes
		: 0;
	const nigthRTETL7 = selectedClientReq4In?.expectYearly7thDaysETLInfo
		?.nightBreakTimes
		? selectedClientReq4In?.expectYearly7thDaysETLInfo?.nightBreakTimes
		: 0;

	const rTContentsETL1 =
		selectedClientReq4In?.selectWorkDaysETL1Info?.breakTimeContents ===
		undefined
			? ''
			: selectedClientReq4In?.selectWorkDaysETL1Info?.breakTimeContents;
	const totalDayRTETL1 = parseInt(dayRTETL1) + parseInt(nigthRTETL1);

	//둘째 근무시간이 같은 날들의 시업, 종업일, 종업시간, 휴게(분)

	const rTContentsETL2 =
		selectedClientReq4In?.selectWorkDaysETL2Info?.breakTimeContents ===
		undefined
			? ''
			: selectedClientReq4In?.selectWorkDaysETL2Info?.breakTimeContents;
	const totalDayRTETL2 = parseInt(dayRTETL2) + parseInt(nigthRTETL2);

	//세째 근무시간이 같은 날들의 시업, 종업일, 종업시간, 휴게(분)

	const rTContentsETL3 =
		selectedClientReq4In?.selectWorkDaysETL3Info?.breakTimeContents ===
		undefined
			? ''
			: selectedClientReq4In?.selectWorkDaysETL3Info?.breakTimeContents;
	const totalDayRTETL3 = parseInt(dayRTETL3) + parseInt(nigthRTETL3);

	//네째 근무시간이 같은 날들의 시업, 종업일, 종업시간, 휴게(분)
	const rTContentsETL4 =
		selectedClientReq4In?.selectWorkDaysETL4Info?.breakTimeContents ===
		undefined
			? ''
			: selectedClientReq4In?.selectWorkDaysETL4Info?.breakTimeContents;
	const totalDayRTETL4 = parseInt(dayRTETL4) + parseInt(nigthRTETL4);

	//다섯째 근무시간이 같은 날들의 시업, 종업일, 종업시간, 휴게(분)
	const rTContentsETL5 =
		selectedClientReq4In?.selectWorkDaysETL5Info?.breakTimeContents ===
		undefined
			? ''
			: selectedClientReq4In?.selectWorkDaysETL5Info?.breakTimeContents;
	const totalDayRTETL5 = parseInt(dayRTETL5) + parseInt(nigthRTETL5);

	//여섯째 근무시간이 같은 날들의 시업, 종업일, 종업시간, 휴게(분)
	const rTContentsETL6 =
		selectedClientReq4In?.selectWorkDaysETL6Info?.breakTimeContents ===
		undefined
			? ''
			: selectedClientReq4In?.selectWorkDaysETL6Info?.breakTimeContents;
	const totalDayRTETL6 = parseInt(dayRTETL6) + parseInt(nigthRTETL6);

	////////////////////////////////////////////////////////휴일근로
	//휴일 근무의 시업, 종업일, 종업시간, 휴게(분)
	const rTContentsETL7 =
		selectedClientReq4In?.expectYearly7thDaysETLInfo?.breakTimeContents ===
		undefined
			? ''
			: selectedClientReq4In?.expectYearly7thDaysETLInfo?.breakTimeContents;
	const totalDayRTETL7 = parseInt(dayRTETL7) + parseInt(nigthRTETL7);

	////////////////////////////////////////////////////////휴일근로
	// 년간 휴일근무 일수
	const numOfYearlyHoliWD = selectedClientReq4In?.expectYearlyHolidayWorks;
	const monthHoliWD = numOfYearlyHoliWD ? numOfYearlyHoliWD / 12 : 0;

	const expectYearly7thWorkDayCounts =
		+selectedClientReq4In?.expectYearly7thWorkDayCounts;

	//성별
	const gender = selectedClientReq4In?.gender;

	//불특정 1주 연장근로
	const expectWeeklyOverWorks =
		selectedClientReq4In?.expectWeeklyOverWorks === undefined
			? 0
			: selectedClientReq4In?.expectWeeklyOverWorks;
	const expectWeeklyNightWorks =
		selectedClientReq4In?.expectWeeklyNightWorks === undefined
			? 0
			: selectedClientReq4In?.expectWeeklyNightWorks;

	const expectWeeklyOverETLWorks =
		selectedClientReq4In?.expectWeeklyOverETRWorks === undefined
			? 0
			: selectedClientReq4In?.expectWeeklyOverETRWorks;
	const expectWeeklyNightETLWorks =
		selectedClientReq4In?.expectWeeklyNightETRWorks === undefined
			? 0
			: selectedClientReq4In?.expectWeeklyNightETRWorks;

	//휴일근로 근무시간이 정해진 1일 실근로시간(휴게제외)
	const daysWTHoli = daysWT(
		endWTHoli,
		dayTypeHoli,
		startWTHoli,
		dayRTHoli,
		nigthRTHoli
	);

	//회사 국어조사
	const companyUn = josaUnType(companyName);
	const companyWa = josaWaType(companyName);
	const companyGa = josaGaType(companyName);

	//직원 국어조사
	const workerUn = josaUnType(worker);
	const workerGa = josaGaType(worker);

	// 간주근로 영업직
	const workField = selectedClientReq4In?.workField;
	const workDetailField = selectedClientReq4In?.workDetailField;

	const holiWork = `${worker}의 월간휴일근무일수(년간휴일근로/12개월) : ${roundUp2(
		monthHoliWD
	)} 일, 휴일근무의 시업은 ${startWTHoli} 
  , 종업은 ${dayTypeHoli} ${endWTHoli}로 하며, 휴게시간은 ${dayRContentsTHoli}총 ${dayRTHoli}분, 
  근무시간이 정해진 1일 실근로시간 : ${daysWTHoli}시간으로 한다.`;

	const shiftWorker =
		selectedClientReq4In?.selectWorkDaysETL1Info?.shiftWork === '격일제 해당'
			? true
			: false;

	const safeDays1 = days1 || [];
	const safeDays2 = days2 || [];
	const safeDays3 = days3 || [];
	const safeDays4 = days4 || [];
	const safeDays5 = days5 || [];

	return (
		<>
			<div className='jau__contents'>
				<span style={{ fontSize: '1rem' }}>
					<strong>
						{isExceptTimeLawWorker
							? '제5조 근무일, 시업, 종업시간, 휴게, 야간근로'
							: '제5조 근무일, 시업, 종업시간, 휴게, 연장, 야간, 휴일근로'}
					</strong>
				</span>
				<ul>
					{isEtcWorker ? (
						<>
							<li>① {selectedClientReq4In?.etc?.workTimeComment}</li>
						</>
					) : (
						<>
							{!isExceptTimeLawWorker ? (
								<>
									<li>
										①{worker}의 {safeDays1.join(', ')}요일 시업시간은 {startWT1}
										, 종업시간은 {dayType1} {endWT1}로 하고, 휴게시간은{' '}
										{rTContents1}({totalDayRT1})분으로 근무시간이 정해진 1일
										실근로시간은 {roundUp2(daysWT1)}시간으로 한다.
										{skipWeek1 === true ? '(격주 근무일 기준)' : ''}
									</li>
									{daysWT2 !== 0 ? (
										<li>
											① {worker}의 {safeDays2.join(', ')}요일 시업시간은{' '}
											{startWT2}, 종업시간은 {dayType2} {endWT2}로 하고,
											휴게시간은 {rTContents2}({totalDayRT2})분으로 근무시간이
											정해진 1일 실근로시간은 {roundUp2(daysWT2)}시간으로 한다.
											{skipWeek2 === true ? '(격주 근무일 기준)' : ''}
										</li>
									) : null}
									{daysWT3 !== 0 ? (
										<li>
											① {worker}의 {safeDays3.join(', ')}요일 시업시간은{' '}
											{startWT3}, 종업시간은 {dayType3} {endWT3}로 하고,
											휴게시간은 {rTContents3}({totalDayRT3})분으로 근무시간이
											정해진 1일 실근로시간은 {roundUp2(daysWT3)}시간으로 한다.
											{skipWeek3 === true ? '(격주 근무일 기준)' : ''}
										</li>
									) : null}
									{daysWT4 !== 0 ? (
										<li>
											① {worker}의 {safeDays4.join(', ')}요일 시업시간은{' '}
											{startWT4}, 종업시간은 {dayType4} {endWT4}로 하고,
											휴게시간은 {rTContents4}({totalDayRT4})분으로 근무시간이
											정해진 1일 실근로시간은 {roundUp2(daysWT4)}시간으로 한다.
											{skipWeek4 === true ? '(격주 근무일 기준)' : ''}
										</li>
									) : null}
									{daysWT5 !== 0 ? (
										<li>
											① {worker}의 {safeDays5.join(', ')}요일 시업시간은{' '}
											{startWT5}, 종업시간은 {dayType5} {endWT5}로 하고,
											휴게시간은 {rTContents5}({totalDayRT5})분으로 근무시간이
											정해진 1일 실근로시간은 {roundUp2(daysWT5)}시간으로 한다.
											{skipWeek5 === true ? '(격주 근무일 기준)' : ''}
										</li>
									) : null}
									{daysWT6 !== 0 ? (
										<li>
											① {worker}의 {days6}요일 시업시간은 {startWT6}, 종업시간은{' '}
											{dayType6} {endWT6}로 하고, 휴게시간은 {rTContents6}(
											{totalDayRT6})분으로 근무시간이 정해진 1일 실근로시간은{' '}
											{roundUp2(daysWT6)}시간으로 한다.
											{skipWeek6 === true ? '(격주 근무일 기준)' : ''}
										</li>
									) : null}
								</>
							) : (
								<>
									<li>
										①{' '}
										{shiftWorker
											? `${worker}의 근무는 격일제 근무로`
											: `${worker}의`}{' '}
										{shiftWorker
											? `${daysETL1}요일 중 근무일의`
											: `${daysETL1}요일의`}{' '}
										시업시간은 {startWTETL1}, 종업시간은 {dayTypeETL1}{' '}
										{endWTETL1}로 하고, 휴게시간은 {rTContentsETL1}(
										{totalDayRTETL1})분으로 근무시간이 정해진 1일 실근로시간은{' '}
										{roundUp2(daysWTETL1)}시간으로 한다.
									</li>
									{daysWTETL2 !== 0 ? (
										<li>
											①{' '}
											{shiftWorker
												? `${worker}의 근무는 격일제 근무로`
												: `${worker}의`}{' '}
											{shiftWorker
												? `${daysETL2}요일 중 근무일의`
												: `${daysETL2}요일의`}{' '}
											시업시간은 {startWTETL2}, 종업시간은 {dayTypeETL2}{' '}
											{endWTETL2}로 하고, 휴게시간은 {rTContentsETL2}(
											{totalDayRTETL2})분으로 근무시간이 정해진 1일 실근로시간은{' '}
											{roundUp2(daysWTETL2)}시간으로 한다.
										</li>
									) : null}
									{daysWTETL3 !== 0 ? (
										<li>
											①{' '}
											{shiftWorker
												? `${worker}의 근무는 격일제 근무로`
												: `${worker}의`}{' '}
											{shiftWorker
												? `${daysETL3}요일 중 근무일의`
												: `${daysETL3}요일의`}{' '}
											시업시간은 {startWTETL3}, 종업시간은 {dayTypeETL3}{' '}
											{endWTETL3}로 하고, 휴게시간은 {rTContentsETL3}(
											{totalDayRTETL3})분으로 근무시간이 정해진 1일 실근로시간은{' '}
											{roundUp2(daysWTETL3)}시간으로 한다.
										</li>
									) : null}
									{daysWTETL4 !== 0 ? (
										<li>
											①{' '}
											{shiftWorker
												? `${worker}의 근무는 격일제 근무로`
												: `${worker}의`}{' '}
											{shiftWorker
												? `${daysETL4}요일 중 근무일의`
												: `${daysETL4}요일의`}{' '}
											시업시간은 {startWTETL4}, 종업시간은 {dayTypeETL4}{' '}
											{endWTETL4}로 하고, 휴게시간은 {rTContentsETL4}(
											{totalDayRTETL4})분으로 근무시간이 정해진 1일 실근로시간은{' '}
											{roundUp2(daysWTETL4)}시간으로 한다.
										</li>
									) : null}
									{daysWTETL5 !== 0 ? (
										<li>
											①{' '}
											{shiftWorker
												? `${worker}의 근무는 격일제 근무로`
												: `${worker}의`}{' '}
											{shiftWorker
												? `${daysETL5}요일 중 근무일의`
												: `${daysETL5}요일의`}{' '}
											시업시간은 {startWTETL5}, 종업시간은 {dayTypeETL5}{' '}
											{endWTETL5}로 하고, 휴게시간은 {rTContentsETL5}(
											{totalDayRTETL5})분으로 근무시간이 정해진 1일 실근로시간은{' '}
											{roundUp2(daysWTETL5)}시간으로 한다.
										</li>
									) : null}
									{daysWTETL6 !== 0 ? (
										<li>
											①{' '}
											{shiftWorker
												? `${worker}의 근무는 격일제 근무로`
												: `${worker}의`}{' '}
											{shiftWorker
												? `${daysETL6}요일 중 근무일의`
												: `${daysETL6}요일의`}{' '}
											시업시간은 {startWTETL6}, 종업시간은 {dayTypeETL6}{' '}
											{endWTETL6}로 하고, 휴게시간은 {rTContentsETL6}(
											{totalDayRTETL6})분으로 근무시간이 정해진 1일 실근로시간은{' '}
											{roundUp2(daysWTETL6)}시간으로 한다.
										</li>
									) : null}
									{daysWTETL7 !== 0 ? (
										<li>
											①{' '}
											{shiftWorker
												? `${worker}의 근무는 격일제 근무로`
												: `${worker}의`}{' '}
											{shiftWorker
												? `${daysETL7}요일 중 근무일의`
												: `${daysETL7}요일의`}{' '}
											시업시간은 {startWTETL7}, 종업시간은 {dayTypeETL7}{' '}
											{endWTETL7}로 하고, 휴게시간은 {rTContentsETL7}(
											{totalDayRTETL7})분으로 근무시간이 정해진 1일 실근로시간은{' '}
											{roundUp2(daysWTETL7)}시간으로 한다.(년간{' '}
											{expectYearly7thWorkDayCounts}회 - 월평균{' '}
											{roundUp2(expectYearly7thWorkDayCounts / 12)}회)
										</li>
									) : null}
								</>
							)}
						</>
					)}
					{expectWeeklyOverETLWorks ? (
						<li>
							①-1 {workerUn} 업무 특성상 ①항의 요일별 지정된 실근로시간 외에
							1주일 내에서 요일이 특정되지 않은 {expectWeeklyOverETLWorks}시간의
							추가 근로를 제공한다.(근기법 63조에 따라 연장근로 제한없음.)
						</li>
					) : (
						<>
							{expectWeeklyOverWorks > 0 ? (
								<>
									{numOfWorkers >= 5 ? (
										<>
											{netAge15To18 ? (
												<>
													<li>
														①-1 {workerUn} 18세 미만 연소근로자로 업무 특성상
														①항의 요일별 지정된 실근로시간 외에 1주
														5시간(소정근로를 초과하는 연장시간) 한도 안에서
														1주일 내에서 요일이 특정되지 않은{' '}
														{expectWeeklyOverWorks}시간의 연장근로를 제공하는데
														동의한다.
													</li>
													<b>
														<li style={{ color: 'blue' }}>
															위 ①-1항 동의자 : {worker} (인 또는 sign)
														</li>
													</b>
												</>
											) : (
												<>
													<li>
														①-1 {workerUn} 업무 특성상 ①항의 요일별 지정된
														실근로시간 외에 1주 12시간(소정근로를 초과하는
														연장시간) 한도 안에서 1주일 내에서 요일이 특정되지
														않은 {expectWeeklyOverWorks}시간의 연장근로를
														제공하는데 동의한다.
													</li>
													<b>
														<li style={{ color: 'blue' }}>
															위 ①-1항 동의자 : {worker} (인 또는 sign)
														</li>
													</b>
												</>
											)}
										</>
									) : (
										<li>
											①-1 {workerUn} 업무 특성상 ①항의 요일별 지정된 실근로시간
											외에 1주일 내에서 요일이 특정되지 않은{' '}
											{expectWeeklyOverWorks}시간의 연장근로를 제공한다.
										</li>
									)}
								</>
							) : null}
						</>
					)}

					{expectWeeklyNightWorks || expectWeeklyNightETLWorks ? (
						<>
							<li>
								①-2 {workerUn} 업무 특성상 ①항의 요일별 지정된 실근로시간 외에
								1주내에서 요일이 특정되지 않은 하오 10시 이후 상오 06시 이전의{' '}
								{expectWeeklyNightWorks || expectWeeklyNightETLWorks}시간의
								야간근로를 제공한다.
							</li>
						</>
					) : null}
					{weekLBTime < 15 || isExceptTimeLawWorker ? (
						<li>
							② {companyUn} 사업 특성 및 {worker}의 근무특성(유연근무제,
							교대근무제 등), 그리고 {companyName} 구성원의 원활한 업무수행 및{' '}
							{companyName} 업무상 필요한 경우에는 {worker}의 상기 근무요일 및
							근무시간, 휴게시간을 변경할 수 있다.
						</li>
					) : (
						<li>
							② {companyUn} 사업 특성 및 {worker}의 근무특성(유연근무제,
							교대근무제 등), 그리고 {companyName} 구성원의 원활한 업무수행 및{' '}
							{companyName} 업무상 필요한 경우에는 {worker}의 상기 근무요일 및
							근무시간, 휴게시간, 주휴일을 변경할 수 있다.
						</li>
					)}
					{isExceptTimeLawWorker ? (
						<li>
							③ {workerUn} 근로기준법 제63조에 따른 감단직 직원으로 동법
							제54조의 휴게규정을 적용하지 아니하므로 {companyUn} {worker}에게
							4시간에 30분, 8시간에 60분 이하의 휴게시간을 부여할 수 있고,{' '}
							{workerUn} {companyName}의 규율에 지장을 초래하지 않는 범위 내에서
							부여된 휴게시간(식사시간 포함)을 자유롭게 이용할 수 있다.
						</li>
					) : (
						<li>
							③ {workerUn} {companyName}의 질서와 규율에 지장을 초래하지 않는
							범위 내에서 휴게시간(식사시간 포함)을 자유롭게 이용할 수 있다.
						</li>
					)}
					{numOfWorkers >= 5 ? (
						<>
							{isExceptTimeLawWorker ? (
								<>
									{gender === '여성' ? (
										<>
											<li>
												④ {workerUn} 18세 이상 여성으로서 {companyName} 사업의
												특성 및 {worker} 근무의 특성 및 형태 등을 감안하여,{' '}
												{workerGa} 재직하는 동안 {worker} 및 {companyName}의
												필요로 야간(22:00~06:00) 및 휴일에 근로를 제공해야 하는
												경우 근로기준법 제70조 제1항에 의거 동 야간에 근로를
												제공하는 것에 대해 동의한다.(근기법 제63조에 따라 휴일은
												제외)
											</li>
											<b>
												<li style={{ color: 'blue' }}>
													위 ④항 동의자 : {worker} (인 또는 sign)
												</li>
											</b>
										</>
									) : null}
								</>
							) : (
								<>
									{netAge15To18 ? (
										<li>
											④ {workerUn} 18세 미만 연소근로자로 {companyWa} {workerUn}{' '}
											{companyName} 및 {worker}의 필요에 따라 근로기준법
											제69조의 1주 5시간 한도의 연장 근로와 동법 제70조 제2항의
											야간근로와 휴일근로는 {companyGa} 노동부의 인가를 받으면
											제공하는데 합의한다.
										</li>
									) : (
										<li>
											④ {companyWa} {workerUn} {companyName} 및 {worker}의
											필요에 따라{' '}
											{weekLBTime < 40
												? '근로기준법 제53조 제1항(단시간 근로자의 경우 기간제 및 단시간 보호 등에 관한 법률 제6조)'
												: '근로기준법 제53조'}
											의 1주 12시간 한도의 연장 근로를 제공하는데 합의하며, 본
											조항의 약정 근로 외에 {companyGa} 추가로 지시한 근무를
											제외한 연장, 야간, 휴일근무는 반드시 사전에 {companyGa}{' '}
											정하는 기준에 의거 {workerGa} {companyName}에 신청 후{' '}
											{companyName}의 승인 받은 경우에만 연장, 야간, 휴일근무로
											인정하고, 동 승인없는 {worker} 임의의 자발적 연장, 야간,
											휴일근로에 대해 {companyUn} 근로시간으로 인정하지 않으며,
											임금도 지급하지 않는다는 것에 대해 동의한다.
										</li>
									)}
									<b>
										<li style={{ color: 'blue' }}>
											위 ④항 합의(동의)자 : {worker} (인 또는 sign)
										</li>
									</b>
									{gender === '여성' && !netAge15To18 ? (
										<>
											<li>
												⑤ {workerUn} 18세 이상 여성으로서 {companyName} 사업의
												특성 및 {worker} 근무의 특성 및 형태 등을 감안하여,
												{workerGa} 재직하는 동안 {worker} 및 {companyName}의
												필요로 야간(22:00~06:00) 및 휴일에 근로를 제공해야 하는
												경우 근로기준법 제70조 제1항에 의거 동 야간 및 휴일에
												근로를 제공하는 것에 대해 동의한다.
											</li>
											<b>
												<li style={{ color: 'blue' }}>
													위 ⑤항 동의자 : {worker} (인 또는 sign)
												</li>
											</b>
											{numOfYearlyHoliWD && startWTHoli && endWTHoli ? (
												<li>⑥ {holiWork}</li>
											) : null}
										</>
									) : (
										<>
											{numOfYearlyHoliWD && startWTHoli && endWTHoli ? (
												<li>⑤ {holiWork}</li>
											) : null}
										</>
									)}
								</>
							)}
						</>
					) : (
						<li>
							{numOfYearlyHoliWD && startWTHoli && endWTHoli
								? `④ ${holiWork}`
								: ''}
						</li>
					)}
				</ul>
				{workField === '판매직을 제외한 영업/마케팅' && (
					<>
						<span style={{ fontSize: '1rem' }}>
							<strong>제5조의 1 간주근로시간제</strong>
						</span>
						<ul>
							<li>
								①{worker}의 담당 직종은 {workField}이고 구체적인 담당업무는{' '}
								{workDetailField}이므로 업무시간의 일부 또는 전부를 사업장
								밖에서 근무하여 실 근무시간 산정이 곤란하므로 근로기준법 제58조
								제1항에 의거 본 근로계약서 제4조 제1항의 소정근로시간을 근로한
								것으로 본다.
							</li>
							<li>
								② 제1항에도 불구하고 근로자대표와 {companyName}간에 근로기준법
								제58조 제2항에 따른 근로자대표서면합의로 별도의 간주근로시간을
								정한 경우에는 그 서면합의로 정한 시간을 근로한 것으로 본다.
							</li>
							<b>
								<li style={{ color: 'blue' }}>
									간주근로시간제 합의(동의)자 : {worker} (인 또는 sign)
								</li>
							</b>
						</ul>
					</>
				)}
			</div>
		</>
	);
}
