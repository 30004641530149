import React from 'react';

export default function ClientDash2025NewLaw({
	setOpenWhatsNew2025,
	openWhatsNew2025,
}) {
	return (
		<>
			<br />
			<div
				onClick={() => setOpenWhatsNew2025(!openWhatsNew2025)}
				style={{ cursor: 'pointer' }}>
				<h3>
					1. 최저임금 : 시행시기 2025년 1월 1일 : 최저임금 10,030원(주휴 포함시
					12,036원 - (주 40시간 기준))
				</h3>
				<p>
					* 업종에 상관없이 시급 10,030원, 일급(8시간 기준) 80,240원(주휴 포함시
					96,288원 - (주 40시간 기준)), 월급(주40시간 기준) 2,096,270원,
					년봉(주40시간 기준) 25,155,240원.
				</p>

				<h3>
					2. 육아휴직 : 시행시기 2025년 2월 23일 : 아래의 경우 육아휴직기간
					1년6개월까지 사용가능
				</h3>
				<p>
					* 부모 모두 각 3개월 이상 육아휴직을 사용하거나 한부모 또는 중중
					장애아동 부모에 해당시
				</p>
				<p>
					* 중증 장애아동은 "장애인복지법 시행규칙"에 따른 "장애의 정도가 심한
					장애(중증)"아동을 뜻합니다.
				</p>
				<p>
					* 분활회수는 2회에서 3회로 변경되어 총 4번 사용할 수 있습니다. 단,
					임신중 사용횟수는 제외됩니다.
				</p>
				<h3>
					3. 출산전후휴가 : 시행시기 2025년 2월 23일 : 출산전후휴가 100일 신설
				</h3>
				<p>
					* 임신 37주 미만의 출생아 또는 체중이 2.5kg 미만인 영유아(보자보건법
					시행령상 미숙아)로서 출생 후 24시간 이내에 신생아 중환자실에 입원한
					경우 100일의 출산전후휴가를 사용할 수 있습니다.
				</p>
				<h3>
					4. 임신기 근로시간 단축 : 시행시기 2025년 2월 23일 : 12주 이내 , 32주
					이후 사용 가능
				</h3>
				<p>
					* 임신기에 근로시간을 단축하여 사용할 수 있는 기간을 12주 이내, 32주
					이후로 변경되고, 다태임신, 당뇨병, 출혈 등 고위험 임산부 의료비
					지원사업(복지부)의 대상인 19가지 위험 질환을 진단받은 임신부는 임신
					전기간에 걸쳐 근로시간 단축을 시행할 수 있습니다.
				</p>
				<p>
					연차휴가 산정시 임신기 단축 근로시간을 미포함하여 연차휴가일수 및
					갯수에 변화가 없습니다.
				</p>

				<h3>
					5. 유사한 휴가 : 시행시기 2025년 2월 23일 : 임신 후 11주 이내 유사산시
					10일로 변경
				</h3>
				<p>
					* 임신 후 11주 이내에의 임신초기 유/사산 휴가 기간이 10일로
					변경됩니다.
				</p>
				<h3>6. 배우자 출산휴가 : 시행시기 2025년 2월 23일 : 20일로 변경</h3>
				<p>
					* 출산 후 120일 이내 기간동안 배우자 출산휴가 기간이 20일로 변경되며
					3회분할 사용이 가능합니다.
				</p>

				<h3>
					7. 육아기 근로시간 단축 : 시행시기 2025년 2월 23일 : 12세 이하 자녀로
					확대
				</h3>
				<p>
					* 자녀 연령이 12세까지 최소 사용기간은 1개월로 최대 3년(기존 1년 +
					미사용 육아휴직기간 * 2배기간)사용가능합니다.
				</p>
				<p>
					연차휴가 산정시 육아기 단축 근로시간을 미포함하여 연차휴가일수 및
					갯수에 변화가 없습니다.
				</p>

				<h3>8. 난임치료휴가 : 시행시기 2025년 2월 23일 : 연간 6일(2일 유급)</h3>
				<p>
					* 난임치료휴가가 연간 6일(2일 유급) 사용 가능하고 사업주의
					비밀누설금지의무가 신설됩니다.
				</p>

				<h3>9. 육아휴직 대체인력 지원금 : 시행시기 2025년 1월 1일 : 신설</h3>
				<p>
					* 기존 산전후휴가 대체인력, 육아기근로시간단축 대체인력 지원금만
					있었는데, 육아휴직 대체인력 지원금이 신설됩니다.
				</p>

				<h3>10. 육아휴직급여 : 시행시기 2025년 1월 1일 : 사후지급금 폐지</h3>
				<p>* 육아휴직자 대상 육아휴직급여 중 사후지급금이 폐지됩니다.</p>
				<h3>
					2025년 2월 23일 변경되는 모성보호관련 법령은 시행이 이전에 해당
					요건들이 충족되고 시행일 이후 자격이 되는 기존 직원들도
					사용가능합니다. (예시 : 2024년 12월 31일까지 부모 모두 각각 3개월
					이상의 육아휴직을 사용한 경우 2025년 2월 23일에 자녀가 8세 미만인 경우
					부모 모두 6개월씩 육아휴직을 사용할 수 있습니다.)
				</h3>

				<h3>기타 수시로 변경되는 법령을 추가로 올리겠습니다.</h3>

				<br />
				<button
					style={{
						fontSize: '15px',
						color: 'black',
						height: '30px',
						width: '200px',
						background: 'white',
					}}
					onClick={() => setOpenWhatsNew2025(!openWhatsNew2025)}>
					내용 닫기
				</button>
				<hr />
			</div>
		</>
	);
}
