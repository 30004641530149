import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Loader } from 'semantic-ui-react';
import { compareArray } from '../../../app/common/util/util';
import {
	clearPaydocu,
	fetchPaydocusFormFirestoreService4In,
} from '../companyActions';
import AnnualLeavesTableList from './AnnualLeavesTableList';
import PaydocuShortCut from '../paydocuDashboard/PaydocuShortCut';
import {
	clearClientReqs4In,
	fetchClientReqs4In,
} from '../../clientsRequest/clientReq4In/clientReqActions4In';
import PaydocuFilters from '../paydocuDashboard/PaydocuFilters';

export default function AnnualLeavesTableDashboard() {
	const dispatch = useDispatch();
	const { paydocus, filter, yearMonth, clientInfo } = useSelector(
		(state) => state.companyReducer
	);
	const { workerUsers, currentUserProfile } = useSelector(
		(state) => state.profile
	);
	const { loading } = useSelector((state) => state.async);
	const { authenticated, currentUser } = useSelector((state) => state.auth);
	const { clientReqs4In } = useSelector((state) => state.clientReq4InStore);

	const workerHost = workerUsers.filter((workerUser) => {
		return workerUser?.workerUserId?.includes(currentUser.uid);
	});
	const isWorker = currentUserProfile?.isWorker;

	const workerUid = workerHost.map(
		(workerUser) => workerUser?.paydocuWorkerUid
	);
	const paydocuWorkerUids = paydocus.map(
		(paydocu) => paydocu.workerInfo?.workerId
	);
	const workerView = compareArray(paydocuWorkerUids, workerUid);
	const workerViewData = paydocus.filter((paydocu) => {
		return paydocu?.workerInfo?.workerId?.includes(workerView);
	});

	useEffect(() => {
		const fetchClientReqs = async () => {
			try {
				await dispatch(fetchClientReqs4In(filter));
			} catch (error) {
				console.error('Error fetching client requests:', error);
			}
		};

		fetchClientReqs();

		return () => {
			dispatch(clearClientReqs4In());
		};
	}, [dispatch, filter]);

	useEffect(() => {
		const fetchAllPaydocus = async () => {
			try {
				await dispatch(
					fetchPaydocusFormFirestoreService4In(
						filter,
						yearMonth,
						'',
						clientInfo
					)
				);
			} catch (error) {
				console.error('Error fetching paydocus:', error);
			}
		};

		fetchAllPaydocus();

		return () => {
			dispatch(clearPaydocu());
		};
	}, [dispatch, filter, yearMonth, clientInfo]);

	const titleOfTable = 'Dynamic 연차/근태 대장';

	return (
		<>
			{isWorker ? null : (
				<PaydocuShortCut filter={filter} clientReqs4In={clientReqs4In} />
			)}

			<h1 style={{ textAlign: 'center', margin: '50px' }}>{titleOfTable}</h1>
			<>
				{isWorker ? null : (
					<>
						<PaydocuFilters loading={loading} isWorker={isWorker} />
					</>
				)}
			</>
			<Grid>
				<Grid.Column width={16}>
					<>
						<hr />
						{authenticated && (
							<AnnualLeavesTableList
								paydocus={paydocus}
								workerViewData={workerViewData}
								loading={loading}
								titleOfTable={titleOfTable}
							/>
						)}
					</>
				</Grid.Column>
				<Grid.Column width={16}>
					<Loader active={loading} />
				</Grid.Column>
			</Grid>
		</>
	);
}
